import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Activity } from '../../../models/activity.model';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { ProductInstance } from '../../../models/product.model';
import { WorkProcessInstance } from '../../../models/work-process.model';
import { Phase } from '../../../models/phase.model';
import { NavbarService } from '../../../services/navbar.service';
import { ProductService } from '../../../services/product.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { WorkProcessService } from '../../../services/workProcess.service';
import { PhaseService } from '../../../services/phase.service';
import { ActivityService } from '../../../services/activity.service';
import { InformativeDialogComponent } from '../../ui/informative-dialog/informative-dialog.component';
import { ThemeService } from '../../../services/theme.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProcessComponent implements OnInit {

    productChange: EventEmitter<any> = new EventEmitter();
    processChange: EventEmitter<any> = new EventEmitter();
    phaseChange: EventEmitter<any> = new EventEmitter();
    deviceChange: EventEmitter<any> = new EventEmitter();
    activityChange: EventEmitter<any> = new EventEmitter();

    // Blocks
    dropzoneHovered = false;

    blocProduct = false;
    blocProcess = false;
    blocPhase = false;
    blocDevice = false;

    columns = [];

    company: CompanyInstance;

    selectedCompany: CompanyInstance;

    selectedPlant: PlantInstance;

    plantId: number;

    selectedCompanyId: number;

    selectedPlantId: number;

    // Lists

    selectedProcess: WorkProcessInstance;
    processes: WorkProcessInstance[];
    filteredProcesses: WorkProcessInstance[] = [];
    scrollProcesses: WorkProcessInstance[] = [];

    newPhase: boolean;
    selectedPhase: Phase;
    phases: Phase[];
    filteredPhases: Phase[] = [];
    scrollPhases: Phase[] = [];

    newProcess: boolean;
    selectedProduct: ProductInstance;
    products: ProductInstance[] = [];
    filteredProducts: ProductInstance[] = [];
    scrollProducts: ProductInstance[] = [];

    selectedDevice: any;
    devices: DeviceInstance[] = [];
    filteredDevices: DeviceInstance[] = [];
    scrollDevices: DeviceInstance[] = [];

    newActivity: boolean;
    selectedActivities: Activity[];
    activities: Activity[] = [];
    filteredActivities: Activity[] = [];
    scrollActivity: Activity[] = [];

    // Filtered
    selectedProducts: ProductInstance[] = [];
    selectedDevices: DeviceInstance[] = [];

    // isDocText;
    // isDocLink;
    // srcResult: File = null;
    // isUpdated: boolean;
    // isUploading = false;

    processProcessForm = new FormGroup({
        processes: new FormControl('', []),
    });

    processPhaseForm = new FormGroup({
        phases: new FormControl('', [])
    });

    processProductForm = new FormGroup({
        products: new FormControl('', [])
    });

    processDeviceForm = new FormGroup({
        devices: new FormControl('', [])
    });

    processActivityForm = new FormGroup({
        activities: new FormControl('', [])
    });

    processRepeatByPiece: boolean;

    phaseHasTargetTime: boolean;
    phaseTargetTime: number;

    constructor(
        private _ui: UiService,
        private _products: ProductService,
        private _activityService: ActivityService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _translate: TranslateService,
        private _router: Router,
        private __workProcesses: WorkProcessService,
        private _phaseService: PhaseService,
        private _route: ActivatedRoute,
        private _dialog: MatDialog,
        private _loaderService: LoaderService
    ) {}
    
    async ngOnInit() {
        this._translate.stream([
            'process.title',
        ]).subscribe(async (translations) => {
            this._navbar.setTitle(translations['process.title']);
            setTimeout(() => this._sidebar.selected = 'process-management');
        });

        this._loaderService.startLoader();
        let lockRequest = true;
        this.selectedPlantId = Number(localStorage.getItem('plantId'));
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));

        await this._ngInitWorkProcess();
        await this._ngInitProducts();
        this._ngInitActivity();

        this._sortProcesses(this.processes);
        this._sortProducts(this.products);
        this._sortPhases(this.phases);

        this.initializeFilters();

        this._loaderService.stopLoader();
        lockRequest = false;
        // Blocks
        if (this._route.toString().includes('new-process')) {
            this.createNewProcess();
        } else {
            this._route.params.subscribe(async param => {
                if (param.id) {
                    const res = await this.__workProcesses.isEditable(param.id);
                    if (res.result) {
                        this.addProcess(param.id);
                    } else {
                        lockRequest = true
                        this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
                        this.goBack();
                    }
                }

                if (!lockRequest) {
                    if (this._route.toString().includes('new-phase')) {
                        this.createNewPhase();
                    }
    
                    if (param.phaseId) {
                        this.addPhase(param.phaseId);
    
                        if (this._route.toString().includes('new-activity')) {
                            this.createActivity();
                        }
                    }
                }
            });
        }
    }

    async _ngInitWorkProcess() {
        // get process
        this.processes = await this.__workProcesses.getProcesses(this.selectedPlantId, true, true, true, true);
        for (const process of this.processes) {
            process.WorkProcessPhases.sort((a, b) => {
                return a.WorkProcessesPhases.order > b.WorkProcessesPhases.order ? 1 : -1;
            });
        }
        this._ngInitPhases();
    }

    async _ngInitProducts() {
        // get products
        const deviceAttributes = ['id', 'label'];
        const plantProducts = await this._products.getProducts(null, this.selectedPlantId, true, null, deviceAttributes);
        this.products = plantProducts.filter(p => p.workProcessId == null);
        this._ngInitDevices(plantProducts);
    }

    _ngInitPhases() {
        // get phases
        this.phases = [];
        if (this.processes) {
            this.processes.forEach(process => {
                if (process.WorkProcessPhases.length > 0) {
                    process.WorkProcessPhases.forEach(phase => {
                        if (phase.processIds == null) {
                            phase.processIds = [];
                            phase.processIds.push(process.id);
                        }
                        if (!this.isDuplicate(this.phases, phase)) {
                            this.phases.push(phase);
                        }
                    });
                }
            });
        }
    }

    _ngInitDevices(products: ProductInstance[]) {
        // get devices filtered from selected products
        products.forEach(product => {
            product.Devices.forEach(device => {
                if (device.productIds == null) {
                    device.productIds = [];
                    device.productIds.push(product.id);
                }
                if (!this.isDuplicateDevice(this.devices, device)) {
                    this.devices.push(device);
                }
            });
        });
    }

    _ngInitActivity() {
        this.activities = [];
        this.phases.forEach(phase => {
            phase.ActivityUnits.forEach(activity => {
                activity.ProductionActivity.noteEnabled = activity.ProductionActivity.note ? true : false;
                activity.ProductionActivity.targetTimeInMinutesEnabled = activity.ProductionActivity.targetTimeInMinutes && activity.ProductionActivity.targetTimeInMinutes > 0 ? true : false;
                activity.name = activity.description;
                
                if (!this.isDuplicateActivity(this.activities, activity)) {
                    this.activities.push(activity);
                }
            });
        });
    }

    private initializeFilters() {

        this.filteredProcesses = [...this.processes];
        this.filteredPhases = [...this.phases];

        this.scrollProcesses = [...this.filteredProcesses];
        this.scrollPhases = [...this.filteredPhases];

        this.filteredProducts = [...this.products];
        this.filteredDevices = [...this.devices];

        this.scrollProducts = [...this.filteredProducts];
        this.scrollDevices = [...this.filteredDevices];
        this.scrollActivity = [...this.activities];

        this.selectedProcess = null;
        this.selectedPhase = null;
        this.selectedActivities = [];

        this._buildFilterProductOption();
        this._buildFilterProcessOption();
        this._buildFilterPhaseOption();
        this._buildFilterDeviceOption();
    }

    /**
    * PRODUCT CONTROLLER
    */

    async addProduct(productId: number) {

        this.blocProduct = true;

        this.selectedProduct = this.products.find(p => p.id === productId);

        if (!this.selectedProducts.find(d => d.id === this.selectedProduct.id)) {
            this.selectedProducts.push(this.selectedProduct);
        }

        const selectedIds = this.selectedProducts.map(d => d.id);
        this.filteredProducts = this.products.filter(d => !selectedIds.includes(d.id));
        this.scrollProducts = [...this.filteredProducts];
        this.processProductForm.reset();

        setTimeout(() => this.blocProduct = false, 1500);
    }

    async addAllProducts() {
        this.filteredProducts.forEach(product => {
            this.selectedProducts.push(product);
        });

        this.scrollProducts = [];

        const selectedIds = this.selectedProducts.map(d => d.id);
        this.filteredProducts = this.products.filter(d => !selectedIds.includes(d.id));
        // refresh products dropdown list
        this._sortProducts(this.scrollProducts);
    }

    removeProduct(product: ProductInstance) {
        if (!this.products.find(p => p.id === product.id)) {
            this.products.push(product);
        }

        if (!this.filteredProducts.find(p => p.id === product.id)) {
            this.filteredProducts.push(product);
        }
        this.selectedProducts = this.selectedProducts.filter(d => d.id !== product.id);
        this.scrollProducts.push(product);

        // refresh products dropdown list
        this._sortProducts(this.scrollProducts);
    }

    clearAllProducts() {
        this.selectedProducts.map(p => {
            if (!this.products.find(product => p.id === product.id)) {
                this.products.push(p);
            }
        });
        this.selectedProducts = [];
        this.filteredProducts = [...this.products];
        this.scrollProducts = [...this.filteredProducts];

        // refresh products dropdown list
        this._sortProducts(this.scrollProducts);

    }

    private _sortProducts(list: ProductInstance[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.code.toUpperCase(); // ignore upper and lowercase
            const codeB = b.code.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    private async _buildFilterProductOption() {
        this.productChange.subscribe(description => {
            if (description) {
                this.scrollProducts = this._filterProduct(description);
                this._sortProducts(this.scrollProducts);
            }
        });
    }

    private _filterProduct(value: string): ProductInstance[] {
        const filterValue = value.toLowerCase();
        this._sortProducts(this.filteredProducts);
        return this.filteredProducts.filter(product =>
            (product.name.toLowerCase().includes(filterValue) || product.code.toLowerCase().includes(filterValue)));
    }

    /**
    * PROCESS CONTROLLER
    */

    createNewProcess() {
        this.newProcess = true;

        this.selectedProcess = {
            id: null,
            plantId: this.selectedPlantId,
            description: '',
            Products: [],
            enabled: true,
            repeatByPiece: false,
            WorkProcessPhases: []
        };
        this.processRepeatByPiece = false;
        this.processProcessForm.get('processes').setValue('Nuovo processo');
        this.processPhaseForm.get('phases').setValue('');
        this.filteredProducts = [...this.products];
        this.scrollProducts = [...this.filteredProducts];
    }

    async addProcess(processId: number) {
        this.blocProcess = true;

        this.newProcess = false;
        this.newPhase = false;
        this.selectedProcess = this.processes.find(p => p.id == processId);
        this.processRepeatByPiece = this.selectedProcess.repeatByPiece;
        this.selectedPhase = null;
        // refresh products dropdown list
        this.filteredProducts = [...this.products];
        this._filterLists(true, true);
        this.processProcessForm.get('processes').setValue(this.selectedProcess.description);
        this.processPhaseForm.get('phases').setValue('');

        setTimeout(() => this.blocProcess = false, 1500);
    }

    private _sortProcesses(list: WorkProcessInstance[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.description.toUpperCase(); // ignore upper and lowercase
            const codeB = b.description.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0;
        });
    }

    private async _buildFilterProcessOption() {
        this.processChange.subscribe(description => {
            if (description) {
                this.scrollProcesses = this._filterProcess(description);
                this._sortProcesses(this.scrollProcesses);
            } else {
                this.selectedProcess = null;
                this.selectedPhase = null;
                this.newPhase = false;
                this.newProcess = false;
                this._filterLists(true, true);
            }
        });
    }

    private _filterProcess(value: string): WorkProcessInstance[] {
        const filterValue = value.toLowerCase();
        return this.filteredProcesses.filter(process => process.description.toLowerCase().includes(filterValue));
    }

    /**
    * PHASES CONTROLLER
    */

    createNewPhase() {
        this.newPhase = true;

        this.selectedPhase = {
            id: null,
            plantId: this.selectedPlantId,
            description: '',
            targetTimeInMinutes: null,
            processIds: [],
            WorkProcessesPhases: {
                enabled: null
            },
            ActivityUnits: []
        };

        this.selectedActivities = [];

        this.createActivity();

        this.phaseHasTargetTime = false;
        this.phaseTargetTime = null;

        this.processPhaseForm.get('phases').setValue('Nuova fase');

        this.scrollActivity = this.activities;

        this.filteredDevices = [...this.devices];
        this.scrollDevices = [...this.filteredDevices];
    }

    async addPhase(phaseId: number) {
        this.blocPhase = true;

        this.newPhase = false;
        this.selectedPhase = this.phases.find(p => p.id == phaseId);
        if (this.selectedPhase.targetTimeInMinutes) {
            this.phaseHasTargetTime = true;
            this.phaseTargetTime = this.selectedPhase.targetTimeInMinutes;
        }

        // refresh products dropdown list
        this.filteredDevices = [...this.devices];
        this._filterLists(false, true);
        this.processPhaseForm.get('phases').setValue(this.selectedPhase.description);

        setTimeout(() => this.blocPhase = false, 1500);
    }

    private _sortPhases(list: Phase[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.description.toUpperCase(); // ignore upper and lowercase
            const codeB = b.description.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    private async _buildFilterPhaseOption() {
        this.phaseChange.subscribe(description => {
            if (description) {
                this.scrollPhases = this._filterPhase(description);
                this._sortPhases(this.scrollPhases);
            } else if (description == '') {
                this.selectedPhase = null;
                this._filterLists(false, true);
            }
        });
    }

    private _filterPhase(value: string): Phase[] {
        const filterValue = value.toLowerCase();

        return this.filteredPhases.filter(phase =>
            phase.description.toLowerCase().includes(filterValue));
    }

    /**
    * DEVICE CONTROLLER
    */

    async addDevice(deviceId: number) {

        this.blocDevice = true;

        this.selectedDevice = this.devices.find(d => d.id === deviceId);

        if (!this.selectedDevices.find(d => d.id === this.selectedDevice.id)) {
            this.selectedDevices.push(this.selectedDevice);
        }

        const selectedIds = this.selectedDevices.map(d => d.id);
        this.filteredDevices = this.devices.filter(d => !selectedIds.includes(d.id));
        this.scrollDevices = [...this.filteredDevices];

        this.processDeviceForm.get('devices').reset();

        // refresh products dropdown list

        setTimeout(() => this.blocDevice = false, 1500);
    }

    async addAllDevices() {
        this.filteredDevices.forEach(device => {
            this.selectedDevices.push(device);
        });

        this.scrollDevices = [];

        const selectedIds = this.selectedDevices.map(d => d.id);
        this.filteredDevices = this.devices.filter(d => !selectedIds.includes(d.id));

        // refresh products dropdown list
        this._sortDevices(this.selectedDevices);
    }

    async removeDevice(device: DeviceInstance) {

        this.selectedDevices = this.selectedDevices.filter(d => d.id !== device.id);
        if (!this.filteredDevices.find(d => d.id === device.id)) {
            this.filteredDevices.push(device);
        }
        this.scrollDevices.push(device);

        // refresh products dropdown list
        this._sortDevices(this.scrollDevices);
    }

    async clearAllDevices() {
        this.selectedDevices = [];
        this.filteredDevices = [...this.devices];
        this.scrollDevices = [...this.filteredDevices];
        this.selectedDevices = [];
    }

    private _sortDevices(list: DeviceInstance[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const labelA = a.label.toUpperCase(); // ignore upper and lowercase
            const labelB = b.label.toUpperCase(); // ignore upper and lowercase

            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0; // names must be equal
        });
    }

    private async _buildFilterDeviceOption() {
        this.deviceChange.subscribe(description => {
            if (description) {
                this.scrollDevices = this._filterDevice(description);
                this._sortDevices(this.scrollDevices);
            }
        });
    }

    private _filterDevice(value: string): DeviceInstance[] {
        const filterValue = value.toLowerCase();
        return this.filteredDevices.filter(device =>
            device.label.toLowerCase().includes(filterValue));
    }

    /**
    * ACTIVITY CONTROLLER
    */

    createActivity() {
        const activity: Activity = {
            id: null,
            name: 'Nuova Attività',
            description: 'Nuova Attività',
            type: 1,
            plantId: this.selectedPlantId,
            PhasesActivityUnits: {
                phaseId: this.selectedPhase.id,
                activityUnitId: null,
                enabled: true,
                order: this.selectedActivities.length + 1,
                ActivityUnitId: null
            },
            ProductionActivity: {
                id: null,
                noteEnabled: true,
                note: 'Inserire qui le note',
                activityId: null,
                targetTimeInMinutesEnabled: false,
                targetTimeInMinutes: null
            }
        };

        this.selectedActivities.push(activity);

        this._refreshActivities();
    }

    resetActivity(activity: Activity, index: number) {

        activity = {
            id: null,
            name: 'Nuova Attività',
            description: 'Nuova Attività',
            type: 1,
            plantId: this.selectedPlantId,
            PhasesActivityUnits: {
                phaseId: this.selectedPhase.id,
                activityUnitId: null,
                enabled: true,
                order: index + 1,
                ActivityUnitId: null
            },
            ProductionActivity: {
                id: null,
                note: 'Inserire qui le note',
                noteEnabled: true,
                activityId: null,
                targetTimeInMinutesEnabled: true,
                targetTimeInMinutes: 0
            }
        };

        this.selectedActivities[index] = activity;

        this._refreshActivities();
    }

    async selectActivity(selectedActivity: Activity, activity: Activity, index: number) {
        this.blocPhase = true;

        const phaseId = activity.PhasesActivityUnits.phaseId;

        activity = Object.assign({}, selectedActivity);

        activity.type = 1;
        activity.PhasesActivityUnits.order = index + 1;
        activity.PhasesActivityUnits.phaseId = phaseId;

        this.selectedActivities[index] = activity;

        this._refreshActivities();

        setTimeout(() => this.blocPhase = false, 1500);
    }

    private _refreshActivities() {
        const activityIds = this.selectedActivities.map(a => a.PhasesActivityUnits.activityUnitId);
        this.scrollActivity = this.activities.filter(p => !activityIds.includes(p.PhasesActivityUnits.activityUnitId));

        this._sortActivities(this.scrollActivity);
    }
    
    private _orderActivities(list: Activity[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.PhasesActivityUnits.order; // ignore upper and lowercase
            const codeB = b.PhasesActivityUnits.order; // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    private _sortActivities(list: Activity[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.description; // ignore upper and lowercase
            const codeB = b.description; // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    // private async _buildFilterActivityOption() {
    //     this.activityChange.subscribe(description => {
    //         if (description) {
    //             console.log('here');
    //             this.scrollActivity = this._filterActivity(description);
    //             this._orderActivities(this.scrollActivity);
    //         } else if (description == '') {
    //             this.scrollActivity = this.filteredActivities;
    //         }
    //     });
    // }

    // private _filterActivity(value: string): Activity[] {
    //     const filterValue = value.toLowerCase();

    //     return this.activities.filter(activity =>
    //         activity.description.toLowerCase().includes(filterValue));
    // }

    // Functions to calculate lists

    private calculateProcessesList() {
        const processSelected = this.selectedProcess != null ? true : false;
        const phaseSelected = this.selectedPhase != null ? true : false;

        const filteredProcesses: WorkProcessInstance[] = this._selectProcessFiltered(processSelected, phaseSelected) || [];
        return filteredProcesses;
    }

    private rebaseSelectedProducts() {
        this.selectedProducts = [];
        if (this.selectedProcess) {
            this.selectedProducts = [...this.selectedProcess.Products];

            const prodIds = this.selectedProducts.map(p => p.id);
            this.filteredProducts = this.products.filter(p => !prodIds.includes(p.id));
            this.scrollProducts = [...this.filteredProducts];
        }

        this._sortProducts(this.scrollProducts);
    }

    private rebaseSelectedActivities() {
        this.selectedActivities = [];
        if (this.selectedPhase) {
            this.selectedActivities = [...this.selectedPhase.ActivityUnits];

            const activityIds = this.selectedActivities.map(a => a.PhasesActivityUnits.activityUnitId);
            this.scrollActivity = this.activities.filter(p => !activityIds.includes(p.PhasesActivityUnits.activityUnitId));
        }

        this._orderActivities(this.selectedActivities);
        this._sortActivities(this.scrollActivity);
    }

    private rebaseSelectedDevices() {
        this.selectedDevices = [];

        if (this.selectedPhase) {
            this.selectedPhase.Devices.forEach(device => {
                this.selectedDevices.push(device);
            });

            const deviceIds = this.selectedDevices.map(d => d.id);
            this.filteredDevices = this.devices.filter(d => !deviceIds.includes(d.id));
            this.scrollDevices = [...this.filteredDevices];
        }

        this._sortDevices(this.scrollDevices);
    }

    private _filterLists(processChange: boolean, phaseChange: boolean) {

        const filteredPhases: Phase[] = [];

        const filteredProcesses: WorkProcessInstance[] = this.calculateProcessesList();
        this.filteredProcesses = filteredProcesses;

        if (phaseChange) {
            this.rebaseSelectedDevices();
            if (processChange) {
                this.rebaseSelectedProducts();
            }
        }

        this.rebaseSelectedActivities();

        if (filteredProcesses.length > 0) {
            if (filteredProcesses.length > 1) {
                filteredProcesses.forEach(process => {
                    process.WorkProcessPhases.forEach(phase => {
                        if (!this.isDuplicateFilterList(filteredPhases, phase)) {
                            filteredPhases.push(phase);
                        }
                    });
                });

                this.filteredPhases = filteredPhases || [];

            } else {
                this.filteredPhases = filteredProcesses[0].WorkProcessPhases;
            }

            this.scrollProcesses = [...this.filteredProcesses];
            this.scrollPhases = [...this.filteredPhases];

            this._sortProcesses(this.scrollProcesses);
            this._sortPhases(this.scrollPhases);
        }
    }

    // private createSelectedDevices(selectedDevices: DeviceInstance[], devices: DeviceInstance[]){
    //     let FilteredDevices = []
    //     if(selectedDevices.length > 0){
    //         const set = new Set(selectedDevices.map(d => d.id));
    //         const deviceIds = Array.from(set);
    //         FilteredDevices = this.devices.filter(d => deviceIds.includes(d.id));
    //         console.log(FilteredDevices)
    //     } else {
    //         FilteredDevices = devices;
    //     }
    //     this._sortDevices(FilteredDevices);
    //     return FilteredDevices;
    // }

    private _selectProcessFiltered(process: boolean, phase: boolean): WorkProcessInstance[] {
        let filteredProcess: WorkProcessInstance[] = [];
        if (process && !this.newProcess) {
            this.processes.forEach(Process => {
                if (this.selectedProcess && Process.id == this.selectedProcess.id) {
                    filteredProcess.push(Process);
                }
            });
        } else {
            filteredProcess = this.processes;
        }
        return filteredProcess;
    }

    private rebuildPhaseList(phase: Phase) {
        this.processes.forEach(process => {

            process.WorkProcessPhases.forEach(Phase => {
                if (Phase.id = phase.id) {
                    Phase.processIds = Phase.processIds.filter(number => number != phase.WorkProcessesPhases.processId);
                }
            });
            if (process.id == phase.WorkProcessesPhases.processId) {
                process.WorkProcessPhases = process.WorkProcessPhases.filter(p => p.id != phase.id);
            }
        });
    }

    negativeActivitiesTime(activities: Activity[]) {
        let negativeActivities = false;
        activities.forEach(activity => {
            if (activity.ProductionActivity.targetTimeInMinutes < 0) {
                negativeActivities = true;
            }
        });
        return negativeActivities;
    }

    // TO DO Duplicate activity
    // async duplicatePhase(phase: Phase) {

    //     if (phase) {
    //         const res = 0;
    //         // await this.__workProcesses.duplicate(process);
    //         if (res) {
    //             this.phases.push(res);
    //             this._filterLists();
    //             this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-successfully'));
    //         } else {
    //             this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-error'));
    //         }
    //     }
    // }

    async setModifiedActivity(phase: Phase) {

        let phaseEnabled = false;

        try {

            if (phase.id) {
                for (const activity of this.selectedActivities) {
                    if (activity.id == null) {
                        const ac = await this._activityService.create(activity);
                        activity.id = ac.id;
                        activity.PhasesActivityUnits.phaseId = phase.id;
                    }

                    if (activity.PhasesActivityUnits.enabled) {
                        phaseEnabled = true;
                    }
                }
                

                if (phaseEnabled === false) {
                    phase.WorkProcessesPhases.enabled = phaseEnabled;
                    const process = this.processes.find(p => p.id === phase.WorkProcessesPhases.processId);
                    await this._phaseService.update(phase.WorkProcessesPhases.processId, process.WorkProcessPhases);
                    this._ui.openSnackBar(this._translate.instant('process.noActivityActive'));
                }
                await this._phaseService.updatePhaseActivities(phase, this.selectedActivities);
            }

        } catch (error) {
            console.log(error);
        }
    }

    async removeActivity(activity: Activity) {
        try {
            this._loaderService.startLoader();

            let order = 1;
            this.selectedActivities = this.selectedActivities.filter(a => a != activity);
            this.selectedActivities.forEach(activity => {
                activity.PhasesActivityUnits.order = order;
                order++;
            });

            await this._phaseService.updatePhaseActivities(this.selectedPhase, this.selectedActivities);
            this._loaderService.stopLoader();
        } catch (error) {
            console.log(error);
            this._loaderService.stopLoader();
        }
    }

    private isDuplicate(phases: Phase[], phaseDuplicate: Phase): boolean {
        let truth = false;
        if (phases.length > 0) {
            phases.forEach(phase => {
                if ((phase.id == phaseDuplicate.id)) {
                    if (!(phase.processIds.includes(phaseDuplicate.WorkProcessesPhases.processId))) {
                        phase.processIds.push(phaseDuplicate.WorkProcessesPhases.processId);
                        truth = true;
                    }
                }
            });
        }
        return truth;
    }

    private isDuplicateFilterList(phases: Phase[], phaseDuplicate: Phase): boolean {
        let truth = false;
        if (phases.length > 0) {
            phases.forEach(phase => {
                if ((phase.id == phaseDuplicate.id)) {
                    phase.processIds.push(phaseDuplicate.WorkProcessesPhases.processId);
                    truth = true;
                }

            });
        }
        return truth;
    }

    private isDuplicateDevice(devices: DeviceInstance[], deviceDuplicate: DeviceInstance): boolean {
        let truth = false;
        if (devices.length > 0) {
            devices.forEach(device => {
                if ((device.id == deviceDuplicate.id)) {
                    if (!(device.productIds.includes(deviceDuplicate.productIds[0]))) {
                        device.productIds.push(deviceDuplicate.productIds[0]);
                        truth = true;
                    }
                }
            });
        }
        return truth;
    }

    private isDuplicateActivity(activities: Activity[], activityDuplicate: Activity) {
        let truth = false;
        if (activities.length > 0) {
            truth = activities.some(a => a.id == activityDuplicate.id);
        }
        return truth;
    }

    // private isDuplicateDeviceFilterList(devices: DeviceInstance[], deviceDuplicate: DeviceInstance): boolean {
    //     let truth = false;
    //     if (devices.length > 0) {
    //         devices.forEach(device => {
    //             if ((device.id == deviceDuplicate.id)) {
    //                 device.productIds.push(deviceDuplicate.productIds[0]);
    //                 truth = true;
    //             }
    //         })
    //     }
    //     return truth;
    // }

    // onChangeRadioButton(is: boolean) {
    //     if (is) {
    //         this.isDocLink = false;
    //         this.isDocText = true;
    //     } else {
    //         this.isDocLink = true;
    //         this.isDocText = false;
    //     }
    // }

    // handleFileInput(files: FileList) {
    //     this.srcResult = files.item(0);
    //     this.isUpdated = true;
    // }

    // pdfSelect(){

    // }

    // Drag and drop

    async drop(event: CdkDragDrop<string[]>, activity: Activity) {
        moveItemInArray(this.selectedActivities, event.previousIndex, event.currentIndex);
        let order = 1;
        for (const activity of this.selectedActivities) {
            activity.PhasesActivityUnits.order = order;
            order++;
        }
        this.selectedPhase.ActivityUnits = [...this.selectedActivities];
    }

    goBack() {
        this._router.navigate(['process-management/']);
    }

    async save() {

        try {
            this._loaderService.startLoader();

            let process: WorkProcessInstance;
            let phase: Phase;
            // let ac: Activity;

            if (this.selectedProcess && this.selectedProcess.description.length == 0) {
                this._loaderService.stopLoader();
                return this._ui.openSnackBar(this._translate.instant('process.errors.noProcess'));
            }

            if (this.phaseTargetTime && this.phaseTargetTime < 0) {
                this._loaderService.stopLoader();
                this.phaseTargetTime = 0;
                return this._ui.openSnackBar(this._translate.instant('process.errors.noPhaseTime'));
            }

            if (this.selectedActivities.length > 0 && this.negativeActivitiesTime(this.selectedActivities)) {
                this._loaderService.stopLoader();
                return this._ui.openSnackBar(this._translate.instant('process.errors.noActivityTime'));
            }

            if (this.selectedPhase && this.selectedPhase.description.length == 0) {
                this._loaderService.stopLoader();
                return this._ui.openSnackBar(this._translate.instant('process.errors.noPhase'));
            }

            if (this.selectedProducts && this.selectedProducts.length == 0) {
                this._loaderService.stopLoader();
                return this._ui.openSnackBar(this._translate.instant('process.errors.noProduct'));
            }

            if (this.selectedPhase && this.selectedDevices && this.selectedDevices.length == 0) {
                this._loaderService.stopLoader();
                return this._ui.openSnackBar(this._translate.instant('process.errors.noDevice'));
            }

            if (this.selectedActivities.length > 0) {
                let error = false;
                for (const activity of this.selectedActivities) {
                    if (activity.description == null || activity.description === '') {
                        error = true;
                    }
                }
                if (error) {
                    this._loaderService.stopLoader();
                    return this._ui.openSnackBar(this._translate.instant('process.errors.noActivity'));
                }
            }

            const productIds = this.selectedProducts.map(p => p.id);
            const deviceIds = this.selectedDevices.map(d => d.id);

            if (this.newProcess) {
                this.selectedProcess.repeatByPiece = this.processRepeatByPiece;
                process = await this.__workProcesses.create(this.selectedProcess, productIds);

                this.selectedProcess.id = process.id;
                this.selectedProcess.Products = [...this.selectedProducts];
                this.processes.push(this.selectedProcess);
            } else {
                this.selectedProcess.repeatByPiece = this.processRepeatByPiece;
                const process = this.processes.find(p => p.id == this.selectedProcess.id);
                const processIndex = this.processes.indexOf(process);
                await this.__workProcesses.update(this.selectedProcess.id, this.selectedProcess, productIds);
                this.processes[processIndex].Products = [...this.selectedProducts];
                this.processes[processIndex].description = this.selectedProcess.description.valueOf();
            }

            this.products = this.products.filter(p => !productIds.includes(p.id));
            if (this.newPhase) {
                let phaseEnabled = false;
                const processId = this.newProcess ? process.id : this.selectedProcess.id;
                
                if (this.phaseHasTargetTime) {
                    this.selectedPhase.targetTimeInMinutes = this.phaseTargetTime ? this.phaseTargetTime.valueOf() : 0;
                } else {
                    this.selectedPhase.targetTimeInMinutes = null;
                }

                for (const activity of this.selectedActivities) {

                    if (activity.PhasesActivityUnits.enabled) {
                        phaseEnabled = true;
                    }
                }

                this.selectedPhase.WorkProcessesPhases.enabled = phaseEnabled;
                phase = await this._phaseService.create(this.selectedPhase, processId, deviceIds);

                this.selectedPhase.id = phase.id;
                this.selectedPhase.processIds = phase.processIds;
                this.selectedPhase.WorkProcessesPhases = phase.WorkProcessesPhases;
                this.selectedPhase.Devices = [...this.selectedDevices];

                const selectedProcess = this.processes.find(p => p.id == this.selectedProcess.id);

                selectedProcess.WorkProcessPhases.push(this.selectedPhase);

                // this.phases.push(phase);
                // const phaseIndex = this.phases.indexOf(this.selectedPhase);
            } else if (this.selectedPhase) {

                if (this.phaseHasTargetTime) {
                    this.selectedPhase.targetTimeInMinutes = this.phaseTargetTime ? this.phaseTargetTime.valueOf() : 0;
                } else {
                    this.selectedPhase.targetTimeInMinutes = null;
                }
                
                this.selectedPhase.Devices = [...this.selectedDevices];
                const selectedProcess = this.processes.find(p => p.id == this.selectedProcess.id);
                const phaseIndex = selectedProcess.WorkProcessPhases.indexOf(this.selectedPhase);
                await this._phaseService.updatePhase(this.selectedPhase, deviceIds);
                if (phaseIndex !== -1) {
                    selectedProcess.WorkProcessPhases[phaseIndex].Devices = [...this.selectedDevices];

                    if (this.phaseHasTargetTime) {
                        selectedProcess.WorkProcessPhases[phaseIndex].targetTimeInMinutes = this.phaseTargetTime ? this.phaseTargetTime.valueOf() : 0;
                    } else {
                        selectedProcess.WorkProcessPhases[phaseIndex].targetTimeInMinutes = null;
                    }

                    selectedProcess.WorkProcessPhases[phaseIndex].description = this.selectedPhase.description.valueOf();
                } else {
                    selectedProcess.WorkProcessPhases.push(this.selectedPhase);
                }
            }

            if (this.selectedActivities.length > 0) {
                const phaseId = this.selectedPhase.id;
                for (const activity of this.selectedActivities) {
                    if (activity.ProductionActivity.noteEnabled) {
                        activity.ProductionActivity.note = activity.ProductionActivity.note ? activity.ProductionActivity.note : 'Inserire delle note';
                    } else {
                        activity.ProductionActivity.note = null;
                    }

                    if (activity.ProductionActivity.targetTimeInMinutesEnabled) {
                        activity.ProductionActivity.targetTimeInMinutes ? activity.ProductionActivity.targetTimeInMinutes : 0;
                    } else {
                        activity.ProductionActivity.targetTimeInMinutes = null;
                    }

                    if (activity.id == null) {
                        const ac = await this._activityService.create(activity);
                        activity.id = ac.id;
                        activity.PhasesActivityUnits.phaseId = phaseId;
                    } else {
                        await this._activityService.update(activity);
                    }
                }

                await this._phaseService.updatePhaseActivities(this.selectedPhase, this.selectedActivities);
                this.selectedPhase.ActivityUnits = [...this.selectedActivities];

            }

            let phaseId = null;

            this._ngInitPhases();
            this._ngInitActivity();
            if (this.selectedProcess) {
                if (this.selectedPhase) {
                    phaseId = this.selectedPhase.id;
                }
                this.addProcess(this.selectedProcess.id);
            }

            if (phaseId) {
                this.addPhase(phaseId);
            }

            this._loaderService.stopLoader();
            this._ui.openSnackBar(this._translate.instant('process.errors.finished'));

        } catch (error) {
            console.log(error);
            this._loaderService.stopLoader();
            this._ui.openSnackBar(this._translate.instant('process.errors.errorData'));
        }

    }

    activityDependencies(activity) {
        if (!activity || !activity.id) {
            return 0;
        }
        
        const reduced = this.phases.map(p => p.ActivityUnits).reduce((acc, val) => acc.concat(val), []);
        const list = reduced.filter(a => a.id === activity.id);
        return list ? list.length : 0;
    }

    showDependenciesCounter(event: Event, activity) {
        event.preventDefault();
        event.stopPropagation();
        let count = this.activityDependencies(activity);

        const ref = this._dialog.open(InformativeDialogComponent, {
            width: '700px',
            data: {
                title: this._translate.instant('dialogs.workprocess.activity.deps_info_header',  {
                    description: activity.description,
                    count: count
                }),
                message: this._translate.instant('dialogs.workprocess.activity.deps_info_text'),
                disabled: 'no'
            },
            disableClose: true
        });
    }

}
