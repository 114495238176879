import { Injectable } from '@angular/core';

@Injectable()
export class SidebarService {
    selected: string;

    constructor() {}

    setSelected(sel) {
        this.selected = sel;
    }
}
