import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceInstance } from '../../../models/device.model';
import { DeviceService } from '../../../services/device.service';
import { OrderInstance } from '../../../models/order.model';
import { ProductInstance } from '../../../models/product.model';
import { ProductService } from '../../../services/product.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { UiService } from '../../../services/ui.service';
import { FormGroup, FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrdersService } from '../../../services/orders.service';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrdersComponent implements OnInit {

    isAdmin = false;

    devices: DeviceInstance[];

    companies: CompanyInstance[];

    ordersList: OrderInstance[] = [];

    tmpOrdersList: OrderInstance[] = [];

    products: ProductInstance[];

    selectedDevice: DeviceInstance;

    selectedCompany: CompanyInstance;

    satelliteUrl: string;

    columns = [];

    editRow: number;

    modified = false;

    filteredProducts: ProductInstance[];

    controlForm = new FormControl();

    filteredOptions: Observable<ProductInstance[]>;

    @ViewChild('code') codeTemplate: TemplateRef<any>;
    @ViewChild('product') productTemplate: TemplateRef<any>;
    @ViewChild('target') targetTemplate: TemplateRef<any>;
    @ViewChild('targetSpeed') targetSpeedTemplate: TemplateRef<any>;
    @ViewChild('deliveryDate') deliveryDateTemplate: TemplateRef<any>;
    @ViewChild('addItem') addItemTemplate: TemplateRef<any>;
    @ViewChild('actions') actionsTemplate: TemplateRef<any>;
    @ViewChild('done') doneTemplate: TemplateRef<any>;

    constructor(
        private _device: DeviceService,
        private _translate: TranslateService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _products: ProductService,
        private _company: CompanyService,
        private _dialog: MatDialog,
        private _ui: UiService,
        private _orders: OrdersService,
        private _auth: AuthService
    ) { }


    async ngOnInit() {

        this._translate.stream([
            'orders.title',
            'orders.columns.code',
            'orders.columns.product',
            'orders.columns.target',
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['orders.title']);
            setTimeout(() => this._sidebar.setSelected('orders'));
            this.columns = [{
                resizable: true,
                canAutoResize: true,
                name: translations['orders.columns.code'],
                prop: 'code',
                cellTemplate: this.codeTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['orders.columns.product'],
                prop: 'products',
                cellTemplate: this.productTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['orders.columns.target'],
                prop: 'target',
                cellTemplate: this.targetTemplate
            },
            // {
            //     resizable: true,
            //     canAutoResize: true,
            //     name: translations['orders.columns.target-speed'],
            //     prop: 'targetSpeed',
            //     cellTemplate: this.targetSpeedTemplate
            // }, {
            //     resizable: true,
            //     canAutoResize: true,
            //     name: translations['orders.columns.delivery-date'],
            //     prop: 'deliveryDate',
            //     cellTemplate: this.deliveryDateTemplate
            // },
            // {
            //     resizable: true,
            //     canAutoResize: true,
            //     name: translations['orders.columns.done'],
            //     prop: 'done',
            //     cellTemplate: this.doneTemplate
            // },
            {
                resizeable: false,
                canAutoResize: true,
                width: 160,
                name: '',
                headerTemplate: this.addItemTemplate,
                cellTemplate: this.actionsTemplate
            }];
        });

        this.isAdmin = this._auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            await this.fetchCompanies();
            if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                this.companyChanged();
            }
        } else {
            this.devices = await this._device.getDevices();
            if (this.devices.length > 0) {
                this.products = await this._orders.getProducts();
                this.selectedCompany = this.devices[0].Company;
                this.ordersList = await this._orders.get();
                this.tmpOrdersList = this.clone(this.ordersList);
                this.editRow = null;
            }
        }

        this.filteredOptions = this.controlForm.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.products.slice())
        );
    }

    async save(order: OrderInstance, index: number) {
        let res = null;

        order.productCode = this.controlForm.value.code;
        if (this.satelliteUrl) res = await this._orders.create(order, this.satelliteUrl);
        else res = await this._orders.create(order);


        if (res === 422) {
            this._ui.openSnackBar(this._translate.instant('orders.code_exist'));
            return;
        }

        order.id = res.id;

        this.ordersList[index] = order;
        this.tmpOrdersList = this.clone(this.ordersList);
        this.modified = false;
        this.editRow = null;
        this._ui.openSnackBar(this._translate.instant('orders.saved'));
    }

    setModified() {
        this.modified = true;
    }

    async companyChanged() {
        this.devices = await this._device.getDevicesByCompany(this.selectedCompany.id);
        this.satelliteUrl = this.selectedCompany.satelliteUrl;
        this.ordersList = await this._orders.get(this.satelliteUrl);
        this.products = await this._orders.getProducts(this.satelliteUrl);
        this.tmpOrdersList = this.clone(this.ordersList);
        this.editRow = null;
        this.modified = false;
    }

    add() {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        const newOrder = {
            code: '',
            productCode: null,
            device: this.selectedDevice,
            target: 0,
            // targetSpeed: null,
            // deliveryDate: new Date()
        };

        this.ordersList.unshift(newOrder);
        this.tmpOrdersList = this.clone(this.ordersList);
        this.editRow = 0;
    }

    async delete(order: OrderInstance, index: number) {

        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                title: this._translate.instant('orders.dialog.title'),
                message: this._translate.instant('orders.dialog.text', { prod: order.code }),
                disabled: 'no'
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {
                let res = null;
                if (this.satelliteUrl) res = await this._orders.delete(order.code, this.satelliteUrl);
                else res = await this._orders.delete(order.code);

                if (res) {
                    this.ordersList.splice(index, 1);
                    this.tmpOrdersList.splice(index, 1);

                    this.ordersList = [...this.ordersList];
                    this.tmpOrdersList = [...this.tmpOrdersList];

                    this._ui.openSnackBar(this._translate.instant('orders.deleted'));
                    this.editRow = null;
                    this.modified = false;
                } else {
                    this.ordersList = [...this.ordersList];
                    this.tmpOrdersList = [...this.tmpOrdersList];

                    this._ui.openSnackBar(this._translate.instant('orders.error-delete'));
                    this.editRow = null;
                    this.modified = false;
                }
            }
        });
    }

    back() {
        this.tmpOrdersList.shift();
        this.ordersList.shift();
        this.tmpOrdersList = this.clone(this.tmpOrdersList);
        this.ordersList = this.clone(this.ordersList);
        this.editRow = null;
        this.modified = false;
    }

    edit(rowIndex: number) {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        this.editRow = rowIndex;
    }

    private _filter(name: string): ProductInstance[] {
        const filterValue = name.toLowerCase();
        return this.products.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
    }


    clone(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }

    displayFn(product?: ProductInstance): string | undefined {
        return product ? product.name : undefined;
    }

    private async fetchCompanies() {
        this.companies = await this._company.getCompanies();
        this.companies = this.companies.filter((company) => {
            if (company.satelliteUrl) return company;
        });
        const companies: CompanyInstance[] = [];
        for (const company of this.companies) {
            const check = await this._auth.verifyInsertOrder(company.satelliteUrl);
            if (check) companies.push(company);
        }
        this.companies = this.clone(companies);
    }

    private async fetchProducts(devices: DeviceInstance[]): Promise<ProductInstance[]> {
        let products: ProductInstance[] = [];
        for (const device of devices) {
            let data = await this._products.getProducts(device.id);
            data = data.filter((product) => product.enabled);
            products = products.concat(data);
        }
        return this.getUniqueProducts(products, 'id');
    }

    private getUniqueProducts(products: ProductInstance[], value: string): ProductInstance[] {
        const unique = products
            .map(e => e[value])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => products[e]).map(e => products[e]);
        return unique;
    }
}
