import { Injectable } from '@angular/core';

@Injectable()
export class NavbarService {
    title = 'Dashboard';

    constructor() {}

    /**
     * Set new navbar title
     *
     * @param {any} newTitle New title
     * @memberof NavbarService
     */
    setTitle(newTitle) {
        this.title = newTitle;
    }
}
