import { CmsComponent } from './cms/cms.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorsComponent } from './components/pages/errors/errors.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { NewDeviceComponent } from './components/pages/new-device/new-device.component';
import { LoginComponent } from './components/pages/login/login.component';
import { TagsComponent } from './components/pages/tags/tags.component';
import { ShiftsComponent } from './components/pages/work-shift/work-shift.component';
import { UsersComponent } from './components/pages/users/users.component';
import { OrdersComponent } from './components/pages/orders/orders.component';
import { QualityControlsComponent } from './components/pages/quality-controls/quality-controls.component';
import { ControlComponent } from './components/pages/control/control.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CompaniesComponent } from './components/pages/companies/companies.component';
import { OrdersCoreComponent } from './components/pages/orders-core/orders-core.component';
import { ProcessDataComponent } from './components/pages/process-data/process-data.component';
import { PluginComponent } from './components/pages/plugin/plugin.components';
import { ProcessManagementComponent } from './components/pages/process-management/process-management.component';
import { ProcessComponent } from './components/pages/process/process.component';


const routes: Routes = [{
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
}, {
    path: '',
    component: CmsComponent,
    children: [{
        path: 'stops',
        component: ErrorsComponent
    }, {
        path: 'tags',
        component: TagsComponent
    }, {
        path: 'categories',
        component: CategoriesComponent
    }, {
        path: 'products',
        component: ProductsComponent
    }, {
        path: 'devices',
        component: DevicesComponent
    }, {
        path: 'devices/new',
        component: NewDeviceComponent
    }, {
        path: 'process-data',
        component: ProcessDataComponent
    }, {
        path: 'process-management',
        component: ProcessManagementComponent
    }, {
        path: 'process-management/newprocess',
        component: ProcessComponent
    }, {
        path: 'process-management/:id',
        component: ProcessComponent
    }, {
        path: 'process-management/:id/phase/:phaseId',
        component: ProcessComponent
    }, {
        path: 'process-management/:id/new-phase',
        component: ProcessComponent
    }, {
        path: 'process-management/:id/phase/:phaseId/new-activity',
        component: ProcessComponent
    }, {
        path: 'companies',
        component: CompaniesComponent
    }, {
        path: 'shifts',
        component: ShiftsComponent
    }, {
        path: 'users',
        component: UsersComponent
    }, {
        path: 'orders',
        component: OrdersComponent
    }, {
        path: 'orders-core',
        component: OrdersCoreComponent
    }, {
        path: 'quality-controls',
        component: QualityControlsComponent
    }, {
        path: 'quality-controls/:id/details',
        component: ControlComponent
    }, {
        path: 'plugin',
        component: PluginComponent
    }]
}, {
    path: 'login',
    component: LoginComponent
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
