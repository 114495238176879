import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { OrdersCoreService } from '../../../services/ordersCore.service';
import { UiService } from '../../../services/ui.service';
import { OrderCoreInstance } from '../../../models/order-core.model';
import { OrderStatus } from '../../../models/order-device.model';


@Component({
    selector: 'app-order-device-core-dialog',
    templateUrl: './order-device-core-dialog.component.html',
    styleUrls: ['./order-device-core-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrderDeviceCoreDialogComponent implements OnInit {

    order: OrderCoreInstance;
    changeStatus: number[] = [];

    constructor(
        private _ref: MatDialogRef<OrderDeviceCoreDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { orderData?: OrderCoreInstance},
        private _ordersCoreService: OrdersCoreService,
        private _ui: UiService,
        private _translate: TranslateService,
    ) { }

    async ngOnInit() {
        this.order = JSON.parse(JSON.stringify(this.data.orderData));
    }

    onNoClick() {
        this._ref.close();
    }

    confirm() {
        for (let i = 0; i < this.order.Devices.length; i++) {
            if (this.changeStatus.includes(this.order.Devices[i].id)) {
                this.order.Devices[i] = JSON.parse(JSON.stringify(this.data.orderData.Devices[i]));
            }
        }
        this._ref.close(this.order);
    }

    onChangeStatus(deviceId: number, value) {
        if (!this.changeStatus.includes(deviceId)) {
            this.changeStatus.push(deviceId);
        }
        for (const device of this.order.Devices) {
            if (device.id === deviceId) {
                device.OrdersDevices.status = value === '0' ? OrderStatus.suspended : OrderStatus.completed;
            }
        }
    }

    async onSaveStatus(deviceId: number, status: OrderStatus) {
        const orderUpdated = await this._ordersCoreService.updateOrderStatus(this.order.id, deviceId, status);
        if (typeof orderUpdated === 'number') {
            this._ui.openSnackBar(this._translate.instant('orders-core.error-save'));
            return;
        }
        this.order = orderUpdated;
        this.changeStatus = this.changeStatus.filter(id => id !== deviceId);
        this._ui.openSnackBar(this._translate.instant('orders-core.saved'));
   }

   isModified(deviceId: number): boolean {
       return this.changeStatus.includes(deviceId);
   }

   getStatus(status) {
       return status !== OrderStatus.completed ? '0' : '1';
   }
}
