import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';

import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { MatDialog } from '@angular/material';
import { QrDialogComponent } from '../../ui/qr-dialog/qr-dialog.component';
import { PlantInstance } from '../../../models/plant.model';
import { PlantService } from '../../../services/plant.service';
import { DeviceService } from '../../../services/device.service';
import { PlantModuleDialogComponent } from '../../ui/plant-module-dialog/plant-module-dialog.component';
import { DeviceModuleDialogComponent } from '../../ui/device-module-dialog/device-module-dialog.component';
import { DeviceModuleInstance } from '../../../models/device-module.model';
import { PlantModuleInstance } from '../../../models/plant-module.model';


@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CompaniesComponent implements OnInit {

    companies: CompanyInstance[];
    companiesList: CompanyInstance[];

    editRow: number;

    plantRow: number;

    codeSearch: string;

    constructor(
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _translate: TranslateService,
        private _company: CompanyService,
        private _dialog: MatDialog,
        private _plant: PlantService,
        private _device: DeviceService
    ) { }

    async ngOnInit() {

        this._translate.stream([
            'companies.title',
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['companies.title']);
            setTimeout(() => this._sidebar.setSelected('companies'));
        });

        await this._fetchCompanies();
    }

    private async _fetchCompanies() {
        this.companies = [];
        this.companiesList = [];
        this.companies = await this._company.getCompanies(true, true);
        this.companies.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            else if (nameA > nameB) return 1;
            else return 0;
        });
        this.companiesList = this._cloneArray(this.companies);
    }

    public filter() {
        const filter = this.companies.filter((item) => {
            return item.name.toLowerCase().indexOf(this.codeSearch.toLowerCase()) > -1;
        });

        this.companiesList = this._cloneArray(filter);
    }

    public filterReset() {
        this.codeSearch = '';
        this.companiesList = [];
        this.companiesList = this._cloneArray(this.companies);
    }

    private _cloneArray(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }

    public filterDevice(devices: DeviceInstance[], plant?: number): DeviceInstance[] {

        const filteredDevice: DeviceInstance[] = [];

        if (!plant) {
            for (const device of devices) {
                if (!device.plantId) filteredDevice.push(device);
            }
        } else {
            for (const device of devices) {
                if (device.plantId === plant) filteredDevice.push(device);
            }
        }

        return filteredDevice;
    }

    // Show dialog for plant/device modules
    public openDialogPlant(plant: PlantInstance, companyId: number) {
        const ref = this._dialog.open(PlantModuleDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                description: plant.description,
                plantModule: plant.PlantModule,
                companyId,
                plantId: plant.id
            }
        });
        ref.afterClosed().subscribe(async (result: { msg: string, data: PlantInstance, description?: string }) => {
            try {
                if (result.msg === "plantModuleUpdated") {
                    // update plant
                    plant.description = result.description;
                    await this.savePlant(plant, plant.companyId);
                    // update plantModule
                    this.companiesList.forEach((company) => {
                        company.Plants.forEach((plant) => {
                            if (plant.PlantModule) {
                                if (plant.PlantModule.id === result.data.id) {
                                    plant.PlantModule = result.data
                                }
                            }
                        })
                    })
                } else if (result.msg === "plantToCreate") {
                    await this.savePlant(result.data, result.data.companyId);
                } else if (result.msg === "error") {
                    this._ui.openErrorSnackBar(this._translate.instant('companies.error') + result.data);
                }
            } catch(err) {
                console.log('err: ', err);
            }
        })
    }

    public openDialogDevice(device: DeviceInstance, plant: PlantInstance) {
        const ref =this._dialog.open(DeviceModuleDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                label: device.label,
                deviceModule: device.DeviceModule,
                plantModule: plant.PlantModule,
                plantId: plant.id,
                deviceId: device.id
            }
        });
        ref.afterClosed().subscribe((result: { msg: string, data: DeviceModuleInstance, deviceId?: number }) => {
            if (result.msg === "delete") {
                this.companiesList.forEach((company) => {
                    company.Devices.forEach((device) => {
                        if (device.DeviceModule) {
                            if (device.DeviceModule.id === result.data) {
                                device.DeviceModule = null;
                            }
                        }
                    })
                })
                this._ui.openSnackBar(this._translate.instant('companies.deviceModule.alert.deleted-successfully'));
            } else if (result.msg === "deviceModuleUpdated") {
                this.companiesList.forEach((company) => {
                    company.Devices.forEach((device) => {
                        if (device.DeviceModule) {
                            if (device.DeviceModule.id === result.data.id) {
                                device.DeviceModule = result.data
                            }
                        }
                    })
                })
                this._ui.openSnackBar(this._translate.instant('companies.deviceModule.alert.updated-successfully'));
            } else if (result.msg === "deviceModuleCreated") {
                if (result.deviceId) {
                    this.companiesList.forEach((company) => {
                        company.Devices.forEach((device) => {
                            if (device.id === result.deviceId) {
                                device.DeviceModule = result.data
                            }
                        })
                    })
                    this._ui.openSnackBar(this._translate.instant('companies.deviceModule.alert.created-successfully'));
                }
            } else if (result.msg === "error") {
                this._ui.openErrorSnackBar(this._translate.instant('companies.error') + result.data);
            }
        })
    }

    // Device table actions
    public async showQr(device: DeviceInstance) {
        device.serial = device.serial || device.Unipi.serial;

        const ref = this._dialog.open(QrDialogComponent, {
            width: '400px',
            data: device
        });

        ref.afterClosed().subscribe(async (result) => {
            // Print QR code
            if (typeof result === 'object' && result.name === 'print') {
                const popup = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                popup.document.open();
                popup.document.write(`
        <html>
        <style>
        * {
          font-family: "Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
          font-size: 10pt;
        }
        </style>
        <body onload="window.print(); window.close();" style="text-align: center;">
        <p>${device.label}</p>
        <img src="${result.src}" style="width: 25mm; height: 25mm;"/>
        <p>S/N: ${device.serial}</p>
        </body>
        </html>
        `);
                popup.document.close();
            }
        });
    }

    public editSatelliteUrl(companyId: number) {
        this.editRow = companyId;
    }

    public back() {
        this.plantRow = null;
        this.editRow = null;
        this.companiesList = this._cloneArray(this.companies);
    }

    public async saveSatelliteUrl(company: CompanyInstance) {

        if (company.satelliteUrl === this.companies.find(item => item.id === company.id).satelliteUrl) {
            this.editRow = null;
            this.companiesList = this._cloneArray(this.companies);
            return;
        } else {
            const response = await this._company.updateSatelliteUrl(company.id, company.satelliteUrl);

            if (response === 200) {

                this._ui.openSnackBar(this._translate.instant('companies.save-succesfully'));
                this.companies.map((item) => {
                    if (item.id === company.id) item.satelliteUrl = company.satelliteUrl;
                });
            } else {

                this._ui.openSnackBar(this._translate.instant('companies.save-error'));
                this.companiesList = this._cloneArray(this.companies);
            }

            this.editRow = null;
        }
    }

    public addPlant(company: CompanyInstance) {

        const data: PlantInstance = {
            id: -1,
            companyId: company.id,
            description: ''
        };

        const index = this.companiesList.map((item) => item.id).indexOf(company.id);

        if (index !== -1) {

            this.companiesList[index].Plants.push(data);
            this.plantRow = -1;
        }
    }

    public editPlant(plant: PlantInstance) {
        this.plantRow = plant.id;
    }

    public async deletePlant(plant: PlantInstance, companyId: number) {

        const index = this.companiesList.map((item) => item.id).indexOf(companyId);

        if (plant.id === -1) {
            this.companiesList[index].Plants = this.companiesList[index].Plants.filter((item) => item.id !== -1);

        } else {
            const res = await this._plant.delete(plant.id);

            if (res === 200) {
                this._ui.openSnackBar(this._translate.instant('companies.successfully'));
                this.companies[index].Plants = this.companies[index].Plants.filter((item) => item.id !== plant.id);
                this.companiesList = [];
                this.companiesList = this._cloneArray(this.companies);
            } else {
                this._ui.openErrorSnackBar(this._translate.instant('companies.error'));
            }
        }

        this.plantRow = null;
    }

    public async savePlant(plant: PlantInstance, companyId: number) {
        const index = this.companiesList.map((item) => item.id).indexOf(companyId);

        if (plant.id === -1) {
            this.companiesList[index].Plants.splice(this.companiesList[index].Plants.findIndex(item => item.id === -1), 1);
            delete plant.id;
            const respose = await this._plant.create(plant);
            if (respose) {
                this._ui.openSnackBar(this._translate.instant('companies.plantModule.alert.updated-successfully'));
                this.companiesList[index].Plants.push(respose);
            }
        } else {
            const response = await this._plant.update(plant.id, plant.description);
            if (response === 200) {
                this._ui.openSnackBar(this._translate.instant('companies.plantModule.alert.updated-successfully'));
            }
        }
        this.plantRow = null;

    }

    public async drop(event: CdkDragDrop<DeviceInstance[]>, companyId: number, plantId: number) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);

            const index = this.companiesList.map((item) => item.id).indexOf(companyId);
            if (plantId === -1) plantId = null;
            const removedDeviceId = event.container.data[event.currentIndex].id;

            try {
                this.companiesList[index].Devices.find((device) => device.id === removedDeviceId).plantId = plantId;
                await this._device.updateDevice(removedDeviceId, {
                    plantId: plantId
                });
                this._ui.openSnackBar(this._translate.instant('devices.save'));
            } catch (err) {
                console.error(err);
                this._ui.openSnackBar(this._translate.instant('devices.error'));
            }
            this.companies = this._cloneArray(this.companiesList);
        }
    }
}
