export enum PluginType {
    order='order',
    changeItems='changeItems',
    showDelta='showDelta',
    doneOrder='doneOrder',
    operator='operator',
    print='print',
    orderList='orderList',
    multiOrders='multiOrders',
    batch='batch',
    pdfDocs='pdfDocs',
    checks='checks'
}

export interface PluginInstance {
    id?: number;
    code: string;
    description: string;
    PluginsDevices: PluginsDevicesInstance;
}

export interface PluginsDevicesInstance {
    deviceId: number;
    pluginId?: number;
    enabled: boolean;
    isOnSatellite: boolean;
}
