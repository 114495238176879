import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { OrderCoreInstance } from '../models/order-core.model';
import { OrderStatus } from '../models/order-device.model';


@Injectable()
export class OrdersCoreService {

    private _baseUrl = 'order';

    constructor(
        private _http: HttpService,
        private _config: ConfigService) { }

        async getAll(plantId: number,
                deviceId?: number,
                productId?: number,
                done?: boolean,
                populateProduct?: boolean): Promise<OrderCoreInstance[]> {
        let url = `${this._config.API.url}/${this._baseUrl}?`;

        if (populateProduct) {
            url += `populateProduct=true`;
        }
        if (plantId) {
            url += `&plantId=${plantId}`;
        }
        if (deviceId) {
            url += `&deviceId=${deviceId}`;
        }
        if (productId) {
            url += `&productId=${productId}`;
        }
        if (done) {
            url += `&done=${done}`;
        }

        return this._http.get(url).then( response => {
            let orders = response.json() as OrderCoreInstance[];
            orders = orders.map( e => this._transformOrderCoreInstance(e));

            return orders;
        }).catch( error => {
            console.log(error);
            return [];
        });
    }

    async create(code: string,
            phase?: string,
            plantId?: number,
            productId?: number,
            workProcessId?: number,
            deviceIds?: number[],
            target?: number,
            priorityErrorTarget?: number,
            deliveryDate?: Date,
            populateProduct?: boolean,
            jsonInfo?: object): Promise<OrderCoreInstance> {

        try {
            let url = `${this._config.API.url}/${this._baseUrl}`;
            if (populateProduct) {
                url += `?populateProduct=true`;
            }
            let data = {};
            if (workProcessId) {
                data = {
                    code,
                    phase,
                    plantId,
                    productId,
                    workProcessId,
                    target,
                    deliveryDate,
                    priorityErrorTarget,
                    jsonInfo
                };
            } else {
                data = {
                    code,
                    phase,
                    plantId,
                    productId,
                    deviceIds,
                    target,
                    deliveryDate,
                    priorityErrorTarget,
                    jsonInfo
                };
            }

            const response = await this._http.post(url, data);
            const order = response.json() as OrderCoreInstance;

            return this._transformOrderCoreInstance(order);
        } catch (err) {
            return err.status;
        }
    }

    async update(orderId: number,
            code?: string,
            phase?: string,
            plantId?: number,
            productId?: number,
            workProcessId?: number,
            deviceIds?: number[],
            target?: number,
            priorityErrorTarget?: number,
            deliveryDate?: Date,
            done?: boolean,
            jsonInfo?: object): Promise<OrderCoreInstance> {

        try {
            const url = `${this._config.API.url}/${this._baseUrl}/${orderId}`;
            let data = {};

            if (workProcessId) {
                data = {
                    code,
                    phase,
                    plantId,
                    productId,
                    workProcessId,
                    target,
                    deliveryDate,
                    done,
                    jsonInfo,
                    priorityErrorTarget
                };
            } else {
                data = {
                    code,
                    phase,
                    plantId,
                    productId,
                    deviceIds,
                    target,
                    deliveryDate,
                    done,
                    jsonInfo,
                    priorityErrorTarget
                };
            }

            const response = await this._http.put(url, data);
            const order = response.json() as OrderCoreInstance;

            return this._transformOrderCoreInstance(order);
        } catch (err) {
            return err.status;
        }
    }

    async delete(orderId: number) {
        try {
            const url = `${this._config.API.url}/${this._baseUrl}/${orderId}`;

            const response = await this._http.delete(url);
            return response.status;
        } catch (err) {
            return err.status;
        }
    }

    async updateOrderStatus(orderId: number, deviceId: number, status: OrderStatus) {
        try {
            const url = `${this._config.API.url}/${this._baseUrl}s/updateStatus/${orderId}`;
            const response = await this._http.put(url, {
                deviceId: deviceId,
                status: status
            });
            const orderUpdated = response.json() as OrderCoreInstance;
            return this._transformOrderCoreInstance(orderUpdated);
        } catch (err) {
            return err.status;
        }
    }

    async sortByDate(plantId: number) {
        try {
            const url = `${this._config.API.url}/orders/date-sort`;
            const response = await this._http.put(url, {
                plantId: plantId
            });
            return response.status;
        } catch (err) {
            return err.status;
        }
    }

    async sort(orderIds: number[], plantId: number) {
        try {
            const url = `${this._config.API.url}/orders/sort`;
            const response = await this._http.put(url, {
                orderIds: orderIds,
                plantId: plantId
            });
            return response.json();
        } catch (err) {
            return err.status;
        }
    }

    private _transformOrderCoreInstance(order: OrderCoreInstance) {
        if (order) {
            if (order.deliveryDate) {
                order.deliveryDate = new Date(order.deliveryDate);
            }
            if (order.createdAt) {
                order.createdAt = new Date(order.createdAt);
            }
            if (order.updatedAt) {
                order.updatedAt = new Date(order.updatedAt);
            }
        }

        return order;
    }
}
