import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { WorkProcessInstance } from '../models/work-process.model';


@Injectable()
export class WorkProcessService {
    constructor(
        private _http: HttpService,
        private _config: ConfigService
    ) { }

    async getProcesses(plantId: number,
        populateProduct?: boolean,
        populatePhase?: boolean, populateActivities?: boolean,
        populateDevices?: boolean, enabled?: boolean) {
        let url = `${this._config.API.url}/workprocess/process?`;

        if (plantId) url += `&plantId=${plantId}`;
        if (populateProduct) url += `&populateProduct=${populateProduct}`;
        if (populatePhase) url += `&populatePhases=${populatePhase}`;
        if (populateActivities) url += `&populateActivities=${populateActivities}`;
        if (populateDevices) url += `&populateDevices=${populateDevices}`;
        if (enabled) url += `&enabled=${enabled}`;

        try {
            const response = await this._http.get(url);
            const workProcesses = response.json() as WorkProcessInstance[];
            return workProcesses;
        } catch (err) {
            return err.status;
        }
    }

    async getProcess(processId: number) {
        const url = `${this._config.API.url}/workprocess/process/${processId}`;

        const response = await this._http.get(url);
        const process = response.json() as WorkProcessInstance;

        return process;
    }

    async create(process: WorkProcessInstance, productIds?: number[]) {

        const data = {
            plantId: process.plantId,
            description: process.description,
            repeatByPiece: process.repeatByPiece,
            productIds: productIds
        };

        const url = `${this._config.API.url}/workprocess/process`;

        const response = await this._http.post(url, data);

        return response.json() as WorkProcessInstance;
    }

    async update(processId: number, data: WorkProcessInstance, productIds?: number[]) {
        const url = `${this._config.API.url}/workprocess/process/${processId}`;

        let process = null;

        if (productIds) {
            process = {
                enabled: data.enabled ? true : false,
                description: data.description,
                repeatByPiece: data.repeatByPiece,
                productIds: productIds
            };
        } else {
            process = {
                enabled: data.enabled ? true : false,
                repeatByPiece: data.repeatByPiece,
                description: data.description,
            };
        }

        const response = await this._http.put(url, process);

        return response;
    }

    async duplicate(processId: number) {
        const url = `${this._config.API.url}/workprocess/process/${processId}/duplicate`;

        const response = await this._http.post(url);

        return response.json() as WorkProcessInstance;
    }

    async delete(processId: number) {
        const url = `${this._config.API.url}/workprocess/process/${processId}`;

        const response = await this._http.delete(url);

        return response;
    }

    async isEditable(processId: number) {
        const url = `${this._config.API.url}/workprocess/historyLog/workprocess/isEditable/${processId}`;

        const response = await this._http.get(url);

        return response.json();
    }
}
