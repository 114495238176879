import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

import { ErrorInstance } from '../models/error.model';
import { ErrorCategoryInstance } from '../models/error-category.model';
import { ErrorsTagModel } from '../models/errorsTag.model';
import { SaveChoice } from '../components/ui/error-dialog/error-dialog.component';
import { DeviceInstance } from '../models/device.model';

@Injectable()
export class ErrorService {
    errors: ErrorInstance[];
    errorCategories: ErrorCategoryInstance[];

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getErrors(deviceId?: number, options?: any) {

        let url;

        if (deviceId) {
            // tslint:disable-next-line: max-line-length
            url = `${this._config.API.url}/error?deviceId=${deviceId}&populateDevices=true&populateErrorTag=true&populateCategory=true&enabledOnly=false`;
        } else {
            url = `${this._config.API.url}/error?enabled=true`;
        }

        if (options) {
            let toAppend = '';
            if (options.populateErrorTag != undefined) {
                toAppend += `populateErrorTag=${options.populateErrorTag}&`
            } 
            if (options.populateDevices != undefined) {
                toAppend += `populateDevices=${options.populateDevices}&`
            } 

            if (toAppend != '') {
                url += `&${toAppend}`;
            }
        }
        
        const response = await this._http.get(url);

        this.errors = response.json() as ErrorInstance[];
        
        return this.errors;
    }

    async getErrorsByCompany(companyId: number, options = {populateErrorTag: false, populateDevices: false }) {
        let url = `${this._config.API.url}/error?companyId=${companyId}&enabledOnly=false`;
        
        if (options) {
            let toAppend = '';
            if (options.populateErrorTag != undefined) {
                toAppend += `populateErrorTag=${options.populateErrorTag}&`
            } 
            if (options.populateDevices != undefined) {
                toAppend += `populateDevices=${options.populateDevices}&`
            } 

            if (toAppend != '') {
                url += `&${toAppend}`;
            }
        }
        
        const response = await this._http.get(url);

        this.errors = response.json() as ErrorInstance[];
        
        return this.errors;
    }

    async getErrorCategories(companyId: number): Promise<ErrorCategoryInstance[]> {
        const url = `${this._config.API.url}/errorCategory?companyId=${companyId}`;

        const response = await this._http.get(url);
        return this.errorCategories = response.json() as ErrorCategoryInstance[];
    }

    async createErrorCategories(data: ErrorCategoryInstance): Promise<ErrorCategoryInstance> {
        const url = `${this._config.API.url}/errorCategory`;

        const response = await this._http.post(url, data);
        return response.json() as ErrorCategoryInstance;
    }

    async updateErrorCategories(id: number, name: string): Promise<number> {
        const url = `${this._config.API.url}/errorCategory/${id}`;
        const data = { name: name };
        const response = await this._http.put(url, data);
        return response.status;
    }

    async deleteErrorCategories(id: number): Promise<number> {
        try {
            const url = `${this._config.API.url}/errorCategory/${id}`;
            const response = await this._http.delete(url);
            return response.status;
        } catch (err) {
            return err.status;
        }
    }

    async deleteError(errorId: number) {
        const url = `${this._config.API.url}/error/${errorId}`;
        const response = await this._http.delete(url);

        return response.status;
    }

    async updateError(err: ErrorInstance, dialogResult?: SaveChoice, device?: DeviceInstance) {
        let url = null;
        switch (dialogResult) {
            case SaveChoice.applyOne:
                url = `${this._config.API.url}/error/${err.id}/${device.id}`;
                break;
            case SaveChoice.applyAll:
            default:
                url = `${this._config.API.url}/error/${err.id}`;
                break;
        }
        const response = await this._http.put(url, {
            id: err.id,
            deviceIds: err.Devices.map(e => e.id),
            text: err.text,
            categoryId: err.categoryId,
            type: err.type,
            enabled: err.enabled,
            target: err.target ? err.target : null
        });

        return response.json() as ErrorInstance;
    }

    async saveNewError(err: ErrorInstance, dialogResult?: SaveChoice, deviceId?: number) {
        let url = null;
        switch (dialogResult) {
            case SaveChoice.applyOne:
                url = `${this._config.API.url}/error?deviceId=${deviceId}`;
                break;
            case SaveChoice.applyAll:
            default:
                url = `${this._config.API.url}/error`;
                break;
        }

        const response = await this._http.post(url, {
            id: err.id,
            deviceIds: err.Devices.map(e => e.id),
            text: err.text,
            categoryId: err.categoryId,
            type: err.type,
            enabled: err.enabled,
            target: err.target
        });

        return response.json() as ErrorInstance;
    }
    async addErrorTag(errorId: number, tagId: number) {
        const url = `${this._config.API.url}/error/${errorId}/tag/${tagId}`;
        const response = await this._http.post(url);

        return response.status;
    }
    async removeErrorTag(errorId: number, tagId: number) {
        const url = `${this._config.API.url}/error/${errorId}/tag/${tagId}`;
        const response = await this._http.delete(url);

        return response.status;
    }

    async updateErrorTag(errorId: number, oldTagId: number, newTagId: number) {
        await this.removeErrorTag(errorId, oldTagId);
        await this.addErrorTag(errorId, newTagId);
    }

    /**
     * Get all errors tags
     *
     * @param {number} [deviceId]
     * @returns
     *
     * @memberOf ErrorsService
     */
    async getTagsWithErrors(companyId?: number) {
        let url;
        if (companyId) {
            url = `${this._config.API.url}/errorsTag?populateErrors=true&companyId=${companyId}`;
        } else {
            url = `${this._config.API.url}/errorsTag`;
        }
        const response = await this._http.get(url);
        
        return response.json() as ErrorsTagModel[];
    }
}