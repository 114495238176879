import { Injectable } from '@angular/core';

import { DeviceInstance } from '../models/device.model';
import { UserInstance, UserRole } from '../models/user.model';
import { DeviceService } from './device.service';
import { PluginService } from './plugin.service';
import { PluginInstance, PluginType } from '../models/plugin.model';

export enum UserView {
    ordersCore = 'ORDERS_CORE',
    workProcess = 'WORK_PROCESS',
    delays = "DELAYS"
};

@Injectable()
export class ViewService {
    
    constructor(
        private _deviceService: DeviceService,
        private _pluginService: PluginService) { }

    async userCanView(user: UserInstance, view: UserView): Promise<boolean> {
        if (!user || !view) {
            return false;
        }
        
        let canView: boolean = false;
        let userIsAdmin = user.role === UserRole.admin;
        let userIsManager = user.role === UserRole.manager;

        switch (view) {
           case UserView.ordersCore: 
                /*
                Affinchè la view "core orders" sia abilitata, deve 
                esserci almeno un device con plugin "order" abilitato su core (no su satellite)
                */
                if (userIsAdmin) {
                    canView = true;
                } else if (userIsManager) {
                    let devices: DeviceInstance[] = await this._deviceService.getDevicesByCompany(user.companyId);

                    let orderCoreChecks = await Promise.all(devices.map(async (device) => {
                        let orderCorePluginEnabled = false;
                        let plugins: PluginInstance[] = await this._pluginService.getPlugins(device.id);
                        let orderPlugin = plugins.find(p => p.code == PluginType.order);

                        if (orderPlugin && orderPlugin.PluginsDevices) {
                            orderCorePluginEnabled = orderPlugin.PluginsDevices.enabled == true && !orderPlugin.PluginsDevices.isOnSatellite;
                        }

                        return orderCorePluginEnabled;
                    }));

                    canView = orderCoreChecks.findIndex(check => check == true) >= 0;
                }
                break;
            case UserView.workProcess:
                /*
                Affinchè la view "work process" sia abilitata, l'utente deve essere un admin oppure Takeda.
                Patch temporanea!! A tendere bisognerà gestire in maniera centralizzata le view
                */
                if (userIsAdmin) {
                    canView = true;
                } else {
                    if (user.companyId === 62) { // Takeda produzione
                        canView = true;
                    }
                    if (user.companyId === 34) { // TeamSystem staging
                        canView = true;
                    }
                    if (user.companyId === 36) { // SuperTechmass staging
                        canView = true;
                    }
                    if (user.companyId === 37) { // Takeda staging
                        canView = true;
                    }
                    if (user.companyId === 29) { // Zoppas produzione
                        canView = true;
                    }
                }
            break;
            case UserView.delays:
            /*
            Affinchè la view "delays" sia abilitata, l'utente deve essere un admin oppure Takeda.
            Patch temporanea!! A tendere bisognerà gestire in maniera centralizzata le view
            */
            if (userIsAdmin) {
                canView = true;
            } else {
                if (user.companyId === 62) { // Takeda produzione
                    canView = true;
                }
                if (user.companyId === 37) { // Takeda staging
                    canView = true;
                }
                if (user.companyId === 29) { // Zoppas produzione
                    canView = true;
                }
            }
        break;
        }

        return canView;
    }
}
