
export interface ProcessDataInstance {
    id: number;
    code: string;
    description?: string;
    valueType?: ValueType;
    deviceId?: number;
}


export enum ValueType {
    type1 = 1,
    type2 = 2,
    type3 = 3,
    type4 = 4
}