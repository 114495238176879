import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-quality-control-dialog',
    templateUrl: './quality-control-dialog.component.html',
    styleUrls: ['./quality-control-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QualityControlDialogComponent implements OnInit {

    key: string;
    values: any[];
    codeSearch: string;

    constructor(
        private _ref: MatDialogRef<QualityControlDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            key?: string,
            values: any[],
            needFilterBar?: boolean
        }
    ) { }

    async ngOnInit() {
        this.key = this.data.key;
        this.values = this.data.values;
    }

    closeDialog() {
        this._ref.close();
    }

    filter() {
        const filteredItem = this.values.filter((d) => {
            return d.toLowerCase().indexOf(this.codeSearch.toLowerCase()) > -1;
        });

        this.values = filteredItem;
    }

    filterReset() {
        this.codeSearch = '';
        this.values = this.data.values;
    }
}

