import { Component, Inject, OnInit, ViewChild, TemplateRef, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TaskIstance, DocIstance, UnitInstance } from '../../../models/task.model';
import { UiService } from '../../../services/ui.service';
import { TaskService } from '../../../services/task.service';
import { ControlIstance } from '../../../models/control.model';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileSizePipe } from '../../../pipes/file-size.pipe';
import { UnitService } from '../../../services/unit.service';

@Component({
    selector: 'app-task-dialog',
    templateUrl: './task-dialog.component.html',
    styleUrls: ['./task-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [FileSizePipe]
})
export class TaskDialogComponent implements OnInit {
    tasks: TaskIstance[];
    units: UnitInstance[];
    filteredTask: TaskIstance[] = [];
    useExisting: boolean;
    taskWithValues: boolean;
    selectAction: boolean;
    columns = [];
    docsColumns = [];
    selectedTask: TaskIstance[] = [];
    control: ControlIstance;
    docs: DocIstance[];
    isDocLink = false;
    isDocText = false;
    srcResult: File = null;
    isUpdated: boolean;
    isUploading = false;
    currentTask: TaskIstance = {};

    @ViewChild('description') descriptionTemplate: TemplateRef<any>;
    @ViewChild('closedQuestion') closedQuestionTemplate: TemplateRef<any>;
    @ViewChild('openQuestion') openQuestionTemplate: TemplateRef<any>;
    @ViewChild('docType') docTypeTemplate: TemplateRef<any>;
    @ViewChild('createAt') createAtTemplate: TemplateRef<any>;
    @ViewChild('updateAt') updateAtTemplate: TemplateRef<any>;
    @ViewChild('size') docSizeTemplate: TemplateRef<any>;
    @ViewChild('originalname') docNameTemplate: TemplateRef<any>;
    @ViewChild('question') questionField: ElementRef;
    constructor(
        private _ui: UiService,
        private _translate: TranslateService,
        private _task: TaskService,
        private _unit: UnitService,
        private _ref: MatDialogRef<TaskDialogComponent>,
        private _fileSize: FileSizePipe,
        @Inject(MAT_DIALOG_DATA) public data: {
            controlId: number,
            task?: TaskIstance,
            companyId: number,
            plantId: number,
            isDuplicate?: boolean,
            isEdit?: boolean
        }
    ) { }

    controlForm = new FormGroup({
        description: new FormControl('', [
            Validators.required,
            Validators.maxLength(5000)
        ]),
        docText: new FormControl('', [
            Validators.maxLength(5000)
        ])
    });

    async ngOnInit() {

        this.useExisting = false;

        if (this.data.task) {
            this.addNewTask(this.data.task, true);
        } else {
            this.selectAction = true;
        }

        this._translate.stream([
            'control.dialog.columns.description',
            'control.dialog.columns.closedQuestion',
            'control.dialog.columns.openQuestion',
            'control.dialog.columns.docName',
            'control.dialog.columns.uploaded-instruction',
        ]).subscribe((translations) => {
            this.columns = [{
                resizable: false,
                canAutoResize: true,
                width: 200,
                maxWidth: 400,
                name: translations['control.dialog.columns.description'],
                prop: 'description',
                cellTemplate: this.descriptionTemplate
            }, {
                resizable: false,
                canAutoResize: true,
                width: 150,
                maxWidth: 150,
                name: translations['control.dialog.columns.closedQuestion'],
                prop: 'closedQuestion',
                cellTemplate: this.closedQuestionTemplate
            }, {
                resizable: false,
                canAutoResize: true,
                width: 150,
                maxWidth: 150,
                name: translations['control.dialog.columns.openQuestion'],
                prop: 'Unit',
                cellTemplate: this.openQuestionTemplate
            }, {
                resizable: false,
                canAutoResize: true,
                width: 250,
                maxWidth: 150,
                name: translations['control.dialog.columns.docType'],
                prop: 'docType',
                cellTemplate: this.docTypeTemplate
            }];

            this.docsColumns = [{
                resizable: false,
                canAutoResize: true,
                name: translations['control.dialog.columns.uploaded-instruction'],
                prop: 'originalname',
                cellTemplate: this.docNameTemplate

            }, {
                resizable: false,
                canAutoResize: true,
                name: translations['control.dialog.columns.size'],
                prop: 'size',
                cellTemplate: this.docSizeTemplate

            }];
        });
    }

    async chooseExisting() {
        this.selectAction = false;
        this.useExisting = true;

        await this._fetchTask();
    }
    async goBack() {
        this.selectAction = true;
        this.useExisting = false;
        this._cleanTask();
    }

    async addNewTask(selectedTask?: TaskIstance, isExistingTask?: boolean) {

        this.selectAction = false;
        this.docs = await this._task.getDocs(this.data.companyId, this.data.plantId);
        this.units = await this._unit.get();
        this.docs.forEach((doc) => this._fileSize.transform(doc.size));

        if (!isExistingTask) {
            this.taskWithValues = false;
            this.isDocText = true;
        } else if (this.data.isDuplicate) {
            this.currentTask = { description: selectedTask.description, closedQuestion: selectedTask.closedQuestion };

            if (this.data.task.docLink) {
                this.currentTask.docLink = this.data.task.docLink;
                this.isDocLink = true;
            } else if (this.data.task.docText) {
                this.currentTask.docText = this.data.task.docText;
                this.isDocText = true;
            } else {
                this.isDocText = true;
            }

            this.currentTask.description += ` (${this._translate.instant('control.dialog.duplicate')})`;

            if (this.data.task.unitId || this.data.task.TasksParameters.length) {
                this.taskWithValues = true;
                if (this.data.task.unitId && !this.data.task.TasksParameters.length) {
                    this.currentTask.TasksParameters.push({
                        unitId: this.data.task.unitId,
                        insertThresholds: false
                    });
                } else if (this.data.task.TasksParameters.length) {
                    this.currentTask.TasksParameters = this.cloneArray(this.data.task.TasksParameters);
                    for (let parameter of this.currentTask.TasksParameters) {
                        if (parameter.minThreshold || parameter.minThreshold === 0 ||
                            parameter.maxThreshold || parameter.maxThreshold === 0 ||
                            parameter.targetValue || parameter.targetValue === 0 ||
                            parameter.outOfRangeDescription) {
                                parameter.insertThresholds = true;
                            }
                    }
                }
            } else {
                this.taskWithValues = false;
            }

        } else if (this.data.isEdit) {
            this.currentTask = { description: selectedTask.description, closedQuestion: selectedTask.closedQuestion };

            if (this.data.task.docLink) {
                this.currentTask.docLink = this.data.task.docLink;
                this.isDocLink = true;
            } else if (this.data.task.docText) {
                this.currentTask.docText = this.data.task.docText;
                this.isDocText = true;
            }

            if (this.data.task.unitId || this.data.task.TasksParameters.length) {
                this.taskWithValues = true;
                if (this.data.task.unitId && !this.data.task.TasksParameters.length) {
                    this.currentTask.TasksParameters = [];
                    this.currentTask.TasksParameters.push({
                        unitId: this.data.task.unitId,
                        insertThresholds: false
                    });
                } else if (this.data.task.TasksParameters.length) {
                    this.currentTask.TasksParameters = this.cloneArray(this.data.task.TasksParameters);
                    for (let parameter of this.currentTask.TasksParameters) {
                        if (parameter.minThreshold || parameter.minThreshold === 0 ||
                            parameter.maxThreshold || parameter.maxThreshold === 0 ||
                            parameter.targetValue || parameter.targetValue === 0 ||
                            parameter.outOfRangeDescription) {
                                parameter.insertThresholds = true;
                            }
                    }
                }
            } else {
                this.taskWithValues = false;
            }
        }

        // focus on question
        this.questionField.nativeElement.focus();
    }

    async saveTask() {

        this.isUploading = true;

        try {
            const data: FormData = new FormData();

            data.append('controlId', this.data.controlId.toString());
            data.append('description', this.currentTask.description ? this.currentTask.description : '');
            data.append('unitId', null);
            data.append('closedQuestion', this.currentTask.closedQuestion ? this.currentTask.closedQuestion.toString() : 'false');
            data.append('parameters', this.currentTask.TasksParameters && this.currentTask.TasksParameters.length ? JSON.stringify(this.currentTask.TasksParameters) : null);
            if (this.isDocText) data.append('docText', this.currentTask.docText ? this.currentTask.docText : '');
            if (this.isDocLink) {
                await this._checkIfDocExist();
                if (this.srcResult && this.isUpdated) {
                    data.append('pdf', this.srcResult, this.srcResult.name);
                } else if (this.currentTask.docLink && this.currentTask.docLink !== '' && !this.isUpdated) {
                    data.append('docLink', this.currentTask.docLink);
                } else {
                    this._ui.openSnackBar(this._translate.instant('control.dialog.saved-document-error'));
                    return;
                }
            }

            if (!this.data.isEdit) data.append('companyId', this.data.companyId.toString());

            if (this.data.isEdit) {
                const response = await this._task.update(data, this.data.task.id);
                this.closeDialog(response);
            } else {
                const response = await this._task.create(data);
                this.closeDialog(response);
            }

            this._ui.openSnackBar(this._translate.instant('control.dialog.saved-successfully'));
        } catch (err) {
            this._ui.openSnackBar(this._translate.instant('control.dialog.saved-error'));
            this.isUploading = false;
        }
    }


    rowIdentityComparator(row: TaskIstance) {
        return row.id;
    }

    async onSelectedTask(taskId: number) {
        if (this.isUploading) return;
        this.isUploading = true;
        const task = this.tasks.find(t => t.id === taskId);
        const res = await this._task.associate(taskId, this.data.controlId, true);
        if (res.status === 200) {
            this.closeDialog(task);
            this._ui.openSnackBar(this._translate.instant('control.dialog.copied-successfully'));
        } else {
            this._ui.openSnackBar(this._translate.instant('control.dialog.copied-error'));
        }
        this.isUploading = false;
    }

    async onSelectedDoc(docName: string) {
        if (docName) {
            this.currentTask.docLink = docName;
            this.isUpdated = false;
        }
    }

    onChangeRadioButton(is: boolean) {
        if (is) {
            this.isDocLink = false;
            this.isDocText = true;
        } else {
            this.isDocLink = true;
            this.isDocText = false;
        }

    }

    upload(event) {
        this.srcResult = event.target.files[0];
    }

    handleFileInput(files: FileList) {
        this.srcResult = files.item(0);
        this.isUpdated = true;
    }

    async selectExisting() {
        this.useExisting = true;
        this.currentTask = {};
    }

    // remove description with icon
    public _removeDescription() {
        this.currentTask.description = '';
    }

    public taskWithValuesChanged() {
        if (this.taskWithValues) {
            this.currentTask.TasksParameters = [];
            this.currentTask.TasksParameters.push({
                insertThresholds: false
            });
        } else {
            this.currentTask.TasksParameters = [];
        }
    }

    public removeValue(index: number) {
        this.currentTask.TasksParameters.splice(index, 1);
    }

    public insertThresholdsChanged(index: number) {
        if (!this.currentTask.TasksParameters[index].insertThresholds) {
            this.currentTask.TasksParameters[index].minThreshold = null;
            this.currentTask.TasksParameters[index].maxThreshold = null;
            this.currentTask.TasksParameters[index].targetValue = null;
            this.currentTask.TasksParameters[index].outOfRangeDescription = null;
        }
    }

    public addValue() {
        this.currentTask.TasksParameters.push({
            insertThresholds: false
        });
    }

    // fetch and filter existing task to associate
    private async _fetchTask() {
        this.tasks = await this._task.get(this.data.companyId, this.data.plantId);
        await this.tasks.forEach((task) => {
            let check = false;
            task.ControlsTasks.forEach((c) => {
                if (c.controlId === this.data.controlId) check = true;
            });
            if (!check) {
                this.filteredTask.push(task);
            }
        });

        this.filteredTask = [...this.filteredTask];
    }

    private async _checkIfDocExist() {
        if (!this.srcResult) return;
        const check = this.docs.find((d) => d.originalname === this.srcResult.name);
        if (check) {
            this.isUpdated = false;
            this.currentTask.docLink = this.srcResult.name;
        }
    }

    public checkIfCanSaveTask() {
        if (!this.currentTask.description || this.currentTask.description === '' || (!this.currentTask.closedQuestion && !this.taskWithValues) || this.isUploading) {
            return false;
        }
        if (this.currentTask.TasksParameters) {
            if (this.currentTask.TasksParameters.find(parameter => { return !parameter.description || !parameter.unitId})) {
                return false;
            }
        }
        return true;
    }

    // clean selected task
    private async _cleanTask() {
        this.selectedTask = [];
    }

    closeDialog(data?: any) {
        this.isUploading = false;
        if (data) this._ref.close(data);
        else this._ref.close();
    }

    cloneArray(array: any[]) {
        return array.map((x) => {
            const y = Object.assign({}, x);
            return y;
        });
    }

}
