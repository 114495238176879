import { Injectable } from '@angular/core';
import { DelayInstance } from '../models/delay.model';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DelaysService {

  constructor(private _http: HttpService, private _config: ConfigService) { }

    /**
     * GET Get all delays
     *
     * @param {number} deviceId
     * @param {any} options
     * @returns {DelayInstance[]} Delays[]
     * @memberof DelaysService
     */

  async getDelays(deviceId?: number, options?: any) {

    let url;

    if (deviceId) {
      url = `${this._config.API.url}/delay?deviceId=${deviceId}&populateDevices=true&enabledOnly=false`;
    } else {
      url = `${this._config.API.url}/delay?enabled=true`;
    }

    if (options) {
      let toAppend = '';
      if (options.populateDevices !== undefined) {
        toAppend += `populateDevices=${options.populateDevices}&`;
      }

      if (toAppend !== '') {
        url += `&${toAppend}`;
      }
    }
    const response = await this._http.get(url);
    return response.json() as DelayInstance[];
  }

    /**
     * POST Create a delay
     *
     * @param {DelayInstance} delay
     * @returns {DelayInstance} Delay
     * @memberof DelaysService
     */

  async saveNewDelay(delay: DelayInstance) {
    const url = `${this._config.API.url}/delay`;
    const deviceIds = delay.Devices.map(device => device.id);

    const response = await this._http.post(url, {
      description: delay.description,
      plantId: delay.plantId,
      enabled: delay.enabled,
      deviceIds: deviceIds
    });

    return response.json() as DelayInstance;
  }

    /**
     * PUT Modify a delay
     *
     * @param {DelayInstance} delay
     * @returns {DelayInstance} Delay
     * @memberof DelaysService
     */

  async updateDelay(delay: DelayInstance) {
    const url = `${this._config.API.url}/delay/${delay.id}`;
    const deviceIds = delay.Devices.map(device => device.id);

    const response = await this._http.put(url, {
      description: delay.description,
      plantId: delay.plantId,
      enabled: delay.enabled,
      deviceIds: deviceIds
    });

    return response.json() as DelayInstance;
  }

    /**
     * DELETE Remove a delay
     *
     * @param {number} delayId
     * @returns {string} response
     * @memberof DelaysService
     */

  async deleteDelay(delayId: number) {
    const url = `${this._config.API.url}/delay/${delayId}`;
    const response = await this._http.delete(url);

    return response;
  }
}
