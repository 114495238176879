import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { TaskIstance, DocIstance } from '../models/task.model';


@Injectable()
export class TaskService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async get(companyId: number, plantId: number, controlId?: number): Promise<TaskIstance[]> {
        let url = `${this._config.API.url}/tasks?companyId=${companyId}&plantId=${plantId}`;

        if (controlId) url += `&controlId=${controlId}`;

        const response = await this._http.get(url);

        return response.json() as TaskIstance[];
    }

    async create(data: FormData): Promise<TaskIstance> {
        const url = `${this._config.API.url}/task`;

        const response = await this._http.post(url, data);

        return response.json() as TaskIstance;
    }

    async associate(taskId?: number, controlId?: number, enabled?: boolean) {
        const url = `${this._config.API.url}/tasks/${taskId}/controls/${controlId}/associate`;

        const data = { enabled };
        const response = this._http.put(url, data);
        return response;
    }

    async delete(taskId: number, controlId: number) {
        const url = `${this._config.API.url}/tasks/${taskId}/controls/${controlId}`;

        const response = this._http.delete(url);
        return response;
    }

    async update(data: FormData, taskId: number) {
        const url = `${this._config.API.url}/tasks/${taskId}`;

        const response = await this._http.put(url, data);

        return response.json() as TaskIstance;
    }

    async getDocs(companyId: number, plantId: number) {
        const url = `${this._config.API.url}/tasks/docs?companyId=${companyId}&plantId=${plantId}`;

        const response = await this._http.get(url);

        return response.json() as DocIstance[];

    }
}
