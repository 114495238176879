import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { PlantInstance } from '../models/plant.model';

@Injectable()
export class PlantService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async create(data: any): Promise<PlantInstance> {
        const url = `${this._config.API.url}/plant`;
        const response = await this._http.post(url, data);

        return response.json() as PlantInstance;
    }

    async update(plantId: number, description: string) {
        const url = `${this._config.API.url}/plant/${plantId}?description=${description}`;
        const response = await this._http.put(url);

        return response.status;
    }

    async delete(plantId: number) {
        const url = `${this._config.API.url}/plant/${plantId}`;
        const response = await this._http.delete(url);

        return response.status;
    }
}