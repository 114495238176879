import { ViewChild } from '@angular/core/src/metadata/di';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    constructor(
      private _translate: TranslateService
    ) {
        const lang = localStorage.getItem('lang');

        _translate.setDefaultLang(lang || 'en');
        _translate.use(lang || 'en');
    }
    ngOnInit() {

    }
}
