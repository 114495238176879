import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OrderModuleConfigInstance } from '../../../models/order-module-config.model';
import { PlantModuleInstance } from '../../../models/plant-module.model';
import { ModulesService } from '../../../services/modules.service';

@Component({
    selector: 'app-plant-module-dialog',
    templateUrl: './plant-module-dialog.component.html',
    styleUrls: ['./plant-module-dialog.component.scss']
})
export class PlantModuleDialogComponent implements OnInit {

    description: string;
    plantModule: PlantModuleInstance;
    companyId: number;
    plantId: number;
    plantModuleDefault: PlantModuleInstance;
    orderModuleConfigDefault: OrderModuleConfigInstance;

    constructor(
        private _ref: MatDialogRef<PlantModuleDialogComponent>,
        private _modulesService: ModulesService,
        @Inject(MAT_DIALOG_DATA) public data: {
            description?: string,
            plantModule?: PlantModuleInstance,
            companyId?: number;
            plantId?: number
        }
    ) { 
        this.orderModuleConfigDefault = {
            isOnSatellite: false,
            autoProgress: false,
            validateProgress: false,
            workProcess: false,
            deltaTarget: false,
            targetScrapsDiff: false,
            showOrderList: false,
            multiOrder: false,
            batch: false,
            batchRawMaterial: false,
            scanMaterial: false,
            pdfDocs: false,
            startOrder: false,
            endOrder: false
        }
        this.plantModuleDefault = {
            performances: true,
            orders: false,
            qualityControls: false,
            rawMaterial: false,
            parameters: false,
            operatorsLogin: false,
            OrderModuleConfig: this.orderModuleConfigDefault
        }
        if (this.data.plantId && this.data.plantId !== -1) {
            this.plantModuleDefault['plantId'] = this.data.plantId;
        }
    }

    async ngOnInit() {
        this.companyId = this.data.companyId;
        this.plantId = this.data.plantId;
        this.description = this.data.description;
        if (this.data.plantModule) {
            this.plantModule = this.formatObjectValuesFromNumberToBoolean(this.data.plantModule);
            if (!this.data.plantModule.OrderModuleConfig) {
                this.plantModule['OrderModuleConfig'] = this.orderModuleConfigDefault;
            }
        } else {
            this.plantModule = this.plantModuleDefault;
        }
    }

    closeDialog() {
        this._ref.close({
            msg: "cancel",
            data: null
        });
    }

    async save() {
        let plantModuleComputed: PlantModuleInstance;
        let orderData: OrderModuleConfigInstance = null;
        try {
            if (this.plantModule.OrderModuleConfig) {
                orderData = {
                  isOnSatellite: this.plantModule.OrderModuleConfig.isOnSatellite,
                  autoProgress: this.plantModule.OrderModuleConfig.autoProgress,
                  validateProgress: this.plantModule.OrderModuleConfig.validateProgress,
                  workProcess: this.plantModule.OrderModuleConfig.workProcess,
                  deltaTarget: this.plantModule.OrderModuleConfig.deltaTarget,
                  targetScrapsDiff: this.plantModule.OrderModuleConfig.targetScrapsDiff,
                  showOrderList: this.plantModule.OrderModuleConfig.showOrderList,
                  multiOrder: this.plantModule.OrderModuleConfig.multiOrder,
                  batch: this.plantModule.OrderModuleConfig.batch,
                  batchRawMaterial: this.plantModule.OrderModuleConfig.batchRawMaterial,
                  scanMaterial: this.plantModule.OrderModuleConfig.scanMaterial,
                  pdfDocs: this.plantModule.OrderModuleConfig.pdfDocs,
                  startOrder: this.plantModule.OrderModuleConfig.startOrder,
                  endOrder: this.plantModule.OrderModuleConfig.endOrder
                }
            }

            let data = {
                plantData: {
                    performances: this.plantModule.performances,
                    orders: this.plantModule.orders,
                    qualityControls: this.plantModule.qualityControls,
                    rawMaterial: this.plantModule.rawMaterial,
                    parameters: this.plantModule.parameters,
                    operatorsLogin: this.plantModule.operatorsLogin
                },
                orderData
            }

            // update an existing plantModule.
            // diamo per scontato che se esiste un plant, allora esiste anche il plantModule associato.
            if (this.plantId && this.plantId > 0) {
                if (!this.plantModule.id) {
                    throw new Error("Plant Module cannot be null");
                }
                data.plantData['plantId'] = this.plantId;
                plantModuleComputed = await this._modulesService.udpatePlantModule(this.plantModule.id, data);
                this._ref.close({
                    msg: "plantModuleUpdated",
                    data: plantModuleComputed,
                    description: this.description
                });
            } else {
                // create a new plant and associated plantModule
                data['companyId'] = this.companyId;
                data['description'] = this.description;
                data['id'] = this.plantId;
                this._ref.close({
                    msg: "plantToCreate",
                    data: data
                });
            }
        } catch(err) {
            console.log('err:', err);
            this._ref.close({
                msg: "error",
                data: err
            });
        }
    }

    filterReset() {
        this.description = this.data.description;
        this.plantModule = this.data.plantModule;
    }

    formatObjectValuesFromNumberToBoolean(object) {
        for ( const property in object ) {
            if (property !== "id" && property !== "plantId" && property !== "plantModuleId") {
                if (typeof object[property] === "object") {
                    object[property] = this.formatObjectValuesFromNumberToBoolean(object[property]);
                } else {
                    object[property] = !!object[property]
                }
            }
            
        }
        return object;
    }

    // remove description with icon
    public _removeDescription() {
        this.description = '';
    }

}
