import { UnipiInstance } from './unipi.model';
import { OrderDeviceIstance } from './order-device.model';
import { DeviceModuleInstance } from './device-module.model';

export interface DeviceInstance {
    id?: number;
    label?: string;
    serial?: string;
    Company?: any;
    location?: [number, number];
    address?: string;
    place?: string;
    stopProductsThreshold?: number;
    resumeProductionProductsThreshold?: number;
    stopProductsThresholdSec?: number;
    resumeProductionProductsThresholdPieces?: number;
    multiplier?: number;
    scrapsMultiplier?: number;
    sessionType?: SessionType;
    priorityErrorId?: number;
    forceStopFromShift?: number;
    changeStopOnShift?: number;
    startSessionOnShift?: number;
    Unipi?: UnipiInstance;
    plantId?: number;
    OrdersDevices?: OrderDeviceIstance;
    productIds?: number[],
    enabled?: boolean,
    DeviceModule?: DeviceModuleInstance
}

export enum SessionType {
    startWithPiece = 0,
    startWithProduct = 1,
    timerMode = 2
}
