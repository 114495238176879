import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { DeviceInstance } from '../../../models/device.model';
import { UserRole } from '../../../models/user.model';
import { ProcessDataInstance, ValueType } from '../../../models/process-data.model';

import { DeviceService } from '../../../services/device.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { AuthService } from '../../../services/auth.service';
import { ProcessDataService } from '../../../services/processData.service';

import { DialogComponent } from '../../ui/dialog/dialog.component';


@Component({
    selector: 'app-process-data',
    templateUrl: './process-data.component.html',
    styleUrls: ['./process-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProcessDataComponent implements OnInit {
    processData: ProcessDataInstance[] = [];
    tmpProcessData: ProcessDataInstance[] = [];

    devices: DeviceInstance[];
    selectedDevice: DeviceInstance;
    
    isAdmin = false;
    modifiedProcessData: boolean = false;
    editRow: number;
    columns = [];
    valueTypes = [];

    selectedCompanyId: number;
    processDataSearch: string;

    @ViewChild('code') codeTemplate: TemplateRef<any>;
    @ViewChild('description') descriptionTemplate: TemplateRef<any>;
    @ViewChild('valueType') valueTypeTemplate: TemplateRef<any>;
    @ViewChild('actions') actionsTemplate: TemplateRef<any>;
    @ViewChild('addItem') addItemTemplate: TemplateRef<any>;

    constructor(
        private _device: DeviceService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _auth: AuthService,
        private _processDataService: ProcessDataService
    ) { }

    async ngOnInit() {
        // check if the current user is an admin
        this.isAdmin = this._auth.user.role === UserRole.admin;
        
        this._translate
            .stream([
                'processData.title',
                'processData.columns.code',
                'processData.columns.description',
                'processData.columns.valueType'
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['processData.title']);
                setTimeout(() => this._sidebar.selected = 'process_data');

                this.columns = [{
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['processData.columns.code'],
                    comparator: this.textComparator.bind(this),
                    cellTemplate: this.codeTemplate,
                    prop: 'code'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 250,
                    name: translations['processData.columns.description'],
                    cellTemplate: this.descriptionTemplate,
                    comparator: this.textComparator.bind(this),
                    prop: 'description'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    width: 200,
                    name: translations['processData.columns.valueType'],
                    comparator: this.valueTypeComparator.bind(this),
                    cellTemplate: this.valueTypeTemplate,
                    prop: 'valueType'
                }, {
                    resizeable: false,
                    canAutoResize: true,
                    name: '',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];
            });

        this.devices = await this._device.getDevices();
        if (this.devices.length > 0) {
            if (localStorage.getItem('deviceId')) {
                const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                if (device) this.selectedDevice = device;
            } else this.selectedDevice = this.devices[0];
            this.deviceChanged();
        }

        if (this.isAdmin) {
            this.selectedCompanyId = this.selectedDevice.Company.id;
            
        } else {
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        }

        this._buildValueTypesList();
    }

    async deviceChanged() {
        localStorage.setItem('deviceId', this.selectedDevice.id.toString());
        this.processData = await this._processDataService.getAll(this.selectedDevice.id);
        this.tmpProcessData = this.cloneArray(this.processData);
        this.editRow = null;
        this.modifiedProcessData = false;
    }

    private _buildValueTypesList() {
        const keys = Object.keys(ValueType).filter(k => typeof ValueType[k as any] === "number");
        
        keys.forEach( el => {
            this.valueTypes.push({ 
                id: ValueType[el],
                name: ValueType[el],
                value: ValueType[el]
            });
        });

        this.valueTypes.unshift({
            id: 0,
            name: '-- none --',
            value: null
        });
    }

    addProcessData() {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        
        const newProcessData = {
            id: null,
            code: '',
            description: ''
        };

        this.processData.unshift(newProcessData);
        this.processData = [...this.processData];
        this.tmpProcessData = this.cloneArray(this.processData);

        this.editRow = 0;
    }

    edit(rowIndex: number) {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        this.editRow = rowIndex;
    }

    async saveProcessData(processData: ProcessDataInstance, index: number) {
        let res = null;
        let errorOccurred = false;

        if (processData.id) {
            res = await this._processDataService.update(processData);
            if (!res) {
                errorOccurred = true;
            }
        } else {
            res = await this._processDataService.create(this.selectedDevice.id, processData);
            if (res) {
                processData.id = res.id;
            } else {
                errorOccurred = true;
            }
        }
        
        if (!errorOccurred) {
            this.processData[index] = processData;
            this.editRow = null;
            this.modifiedProcessData = false;
            this._ui.openSnackBar(this._translate.instant('processData.saved'));
        } else {
            this._ui.openSnackBar(this._translate.instant('processData.error'));
        }
    }

    setModified(index) {
        this.modifiedProcessData = true;
    }

    textComparator(a, b) {
        return (a.toUpperCase() > b.toUpperCase()) ? -1 : 1;
    }

    valueTypeComparator(a, b) {
        if (a == b) {
            return 0;
        }

        if (!a || a == null || a == '') {
            return 1;
        }
        if (!b || b == null || b == '') {
            return -1;
        }

        return a < b ? -1: 1;
    }

    isEditRow(rowIndex: number) {
        return this.editRow === rowIndex;        
    }

    isEditMode(rowIndex: number) {
        return (this.editRow || this.editRow === 0) && !this.isEditRow(rowIndex);        
    }

    isModified() {
        return this.modifiedProcessData;
    }

    back(rowIndex: number) {
        if (this.processData[rowIndex].id) {
            this.tmpProcessData[rowIndex] = this.cloneObject(this.processData[rowIndex]);
        } else {
            this.processData.shift();
            this.tmpProcessData.shift();
        }
        this.processData = [...this.processData];
        this.tmpProcessData = [...this.tmpProcessData];

        this.editRow = null;
        this.modifiedProcessData = false;
    }

    cloneArray(array: any[]) {
        return array.map(x => Object.assign({}, x));
    }
    cloneObject(object: any) {
        return Object.assign({}, object);
    }

    async deleteProcessData(processData: ProcessDataInstance, index: number) {
        if (!processData.id) {
            this.processData.splice(index, 1);
            this.processData = [...this.processData];
            this.tmpProcessData = this.cloneArray(this.processData);
            this.editRow = null;
            this.modifiedProcessData = false;
            
            return;
        }

        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                disabled:'no',
                title: this._translate.instant('processData.dialog.title'),
                message: this._translate.instant('processData.dialog.text', {code: processData.code})
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result === 'delete') {
                let res = await this._processDataService.delete(processData.id);

                if (res != null) {
                    this.processData.splice(index, 1);
                    this.processData = [...this.processData];
                    this.tmpProcessData = this.cloneArray(this.processData);
                    this.editRow = null;
                    this.modifiedProcessData = false;

                    this._ui.openSnackBar(this._translate.instant('processData.deleted'));
                } else {
                    //error
                    this._ui.openSnackBar(this._translate.instant('processData.error'));
                }
            }
        });
    }
}
