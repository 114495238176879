import { Injectable } from '@angular/core';

@Injectable()
export class Utility {

    cloneArray(array: any) {
        if (array) {
            return JSON.parse(JSON.stringify(array));
        }

        return [];
    }

    cloneObject(object: any) {
        return JSON.parse(JSON.stringify(object));
    }
}