import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export enum SaveChoice {
    applyOne = 'applyOne',
    applyAll = 'applyAll'
}

@Component({
    selector: 'app-product-dialog',
    templateUrl: './product-dialog.component.html',
    styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent {

    applyAllAction: SaveChoice;
    applyOneAction: SaveChoice;
    
    constructor(
        private _ref: MatDialogRef<ProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
        this.applyAllAction = SaveChoice.applyAll;
        this.applyOneAction = SaveChoice.applyOne;
    }

    onBack() {
        this._ref.close();
    }

    onConfirm(choice) {
        this._ref.close(choice);
    }
}

