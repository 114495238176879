import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../../services/auth.service';
import { UiService } from '../../../services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import {ThemeService} from "../../../services/theme.service";
import { EnvConfigurationService } from '../../../services/env-config.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public mail: String;

    public password: String;

    public recover_mail: String;

    public loading = false;

    passwordLost = false;

    loginForm = new FormGroup({
        mail: new FormControl('', [
            Validators.required
        ]),

        password: new FormControl('', [
            Validators.required
        ])
    });

    resetForm = new FormGroup({
        recover_mail: new FormControl('', [
            Validators.required
        ])
    });

    constructor(
        private _auth: AuthService,
        private _ui: UiService,
        private _router: Router,
        private _translate: TranslateService,
        private _user: UserService,
        private _themeService: ThemeService,
        private _envSettings: EnvConfigurationService
    ) { }

    ngOnInit() {
        if (this._auth.authenticated) {
            this._router.navigate(['/stops']);
        } else {
          this._themeService.setTeamSystemTheme(window.location.href.includes(this._envSettings.settings.mesCloudUrl));
        }
    }

    async loginSubmit(): Promise<any> {
        if (!this.loginForm.valid) return;

        try {
            this.loading = true;
            await this._auth.login(this.mail, this.password);
            this._translate.get('login.success').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
            this._router.navigate(['/stops']);
        } catch (err) {
            if (err.status === 401) {
                this._translate.get('login.wrong').subscribe((res) => {
                    this._ui.openSnackBar(res);
                });
            } else if (err.message === 'no_role') {
                this._translate.get('login.wrong').subscribe((res) => {
                    this._ui.openSnackBar(res);
                });
            } else {
                this._translate.get('login.unexpected').subscribe((res) => {
                    this._ui.openSnackBar(res);
                });
            }
        }

        this.loading = false;
    }

    async resetSubmit(): Promise<any> {
        if (!this.resetForm.valid) return;

        try {
            this.loading = true;
            const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
            await this._user.resetPassword(this.recover_mail, lang);
            this._translate.get('login.recover.success').subscribe((res) => {
                this._ui.openSnackBar(res);
            });

            this.passwordLost = false;

        } catch (err) {
            console.log(err);
            this._translate.get('login.recover.wrong').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        this.loading = false;

    }

    isPasswordLost(is: boolean) {
        this.passwordLost = is ? true : false;
    }
}
