import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { ConfigService } from './config.service';

import { ProductInstance } from '../models/product.model';
import { DeviceInstance } from '../models/device.model';

/*

- Integrare name
- Ordinamento per codice
- Ricerca per code
- Modal alla cancellazione

*/

@Injectable()
export class ProductService {
    products: ProductInstance[];

    constructor(
        private _http: HttpService,
        private _config: ConfigService
    ) { }

    async getProducts(deviceId?: number, plantId?: number, populateDevices?: boolean, enabled?: boolean, deviceAttributes?: string[], deviceTmpAttributes?: string[]) {
        let url = `${this._config.API.url}/product?`;

        if (deviceId) url += `&deviceId=${deviceId}`;
        if (plantId) url += `&plantId=${plantId}`;
        if (populateDevices) url += `&populateDevices=${populateDevices}`;
        if (enabled) url += `&enabled=${enabled}`;
        if (deviceAttributes && deviceAttributes.length) deviceAttributes.map((attr) => url += `&deviceAttributes[]=${attr}`);
        if (deviceTmpAttributes && deviceTmpAttributes.length) deviceTmpAttributes.map((attr) => url += `&deviceTmpAttributes[]=${attr}`);

        const response = await this._http.get(url);

        const products = response.json() as ProductInstance[];
        this.products = products.map(product => this.formatProduct(product));
        return this.products;
    }

    async get(): Promise<ProductInstance[]> {
        const url = `${this._config.API.url}/product?enabled=true`;
        const response = await this._http.get(url);

        const products = response.json() as ProductInstance[];
        this.products = products.map(product => this.formatProduct(product));
        return this.products;
    }

    async getByDevice(device: DeviceInstance): Promise<ProductInstance[]> {
        const url = `${this._config.API.url}/product/${device.id}?enabled=true`;
        const response = await this._http.get(url);

        const products = response.json() as ProductInstance[];
        this.products = products.map(product => this.formatProduct(product));
        return this.products;
    }

    async update(prod: ProductInstance) {
        const url = `${this._config.API.url}/product/${prod.id}`;

        if (prod.stopProductsThresholdSec) {
            prod.stopProductsThreshold = prod.stopProductsThresholdSec * 1000;
        }

        let body = this._buildBaseMsgFromProduct(prod);
        body['enabled'] = prod.enabled;

        const response = await this._http.put(url, body);

        return response.json() as ProductInstance;
    }

    async updateForDevice(prod: ProductInstance, device: DeviceInstance) {
        const url = `${this._config.API.url}/product/${prod.id}/${device.id}`;

        if (prod.stopProductsThresholdSec) {
            prod.stopProductsThreshold = prod.stopProductsThresholdSec * 1000;
        }
        
        let body = this._buildBaseMsgFromProduct(prod);
        body['enabled'] = prod.enabled;

        const response = await this._http.put(url, body);

        return response.json() as ProductInstance;
    }

    async create(prod: ProductInstance) {
        
        if (prod.stopProductsThresholdSec) {
            prod.stopProductsThreshold = prod.stopProductsThresholdSec * 1000;
        }
        
        const url = `${this._config.API.url}/product`;
        const body = this._buildBaseMsgFromProduct(prod);
        const response = await this._http.post(url, body);

        return response.json() as ProductInstance;
    }

    async createForDevice(prod: ProductInstance, device: DeviceInstance) {
        
        if (prod.stopProductsThresholdSec) {
            prod.stopProductsThreshold = prod.stopProductsThresholdSec * 1000;
        }

        const url = `${this._config.API.url}/product/${device.id}`;
        const body = this._buildBaseMsgFromProduct(prod);
        const response = await this._http.post(url, body);

        return response.json() as ProductInstance;
    }

    async delete(prod: ProductInstance) {
        const url = `${this._config.API.url}/product/${prod.id}`;
        let response;
        try {
            response = await this._http.delete(url);
        } catch (error) {
            response = error;
        }
        return response ? response.status : null;
    }

    /**
     * Format device fields, i.e. threshold in seconds rather than ms
     *
     * @param {DeviceInstance} product
     * @returns {DeviceInstance}
     * @memberof DeviceService
     */
    formatProduct(product: ProductInstance): ProductInstance {
        if (product.stopProductsThreshold) {
            product.stopProductsThresholdSec = Math.trunc(product.stopProductsThreshold / 1000);
        }
        return product;
    }

    _buildBaseMsgFromProduct(prod: ProductInstance) {
        if (!prod) return {};
        
        return {
            code: prod.code,
            name: prod.name,
            stopProductsThreshold: prod.stopProductsThreshold,
            resumeProductionProductsThreshold: prod.resumeProductionProductsThreshold,
            idealPiecesPerMinutesSpeed: prod.idealPiecesPerMinutesSpeed,
            inputMultiplier: prod.inputMultiplier,
            scrapsMultiplier: prod.scrapsMultiplier,
            multiplier: prod.multiplier,
            divider: prod.divider,
            deviceIds: prod.Devices.map(e => e.id)
            
        };
    }
}
