/**
 * Create by LL 22 April 2019
 * This compoment is another version of schedules component.
 * This is an utility to costumer for manager work shift thanks a calendar view.
 */
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { addDays, addMinutes, endOfWeek, startOfWeek } from 'date-fns';
import { CalendarEvent, CalendarEventTimesChangedEvent, DAYS_OF_WEEK, CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { Subject } from 'rxjs';
import { DayViewHourSegment } from 'calendar-utils';
import { finalize, takeUntil } from 'rxjs/operators';
import { DeviceInstance } from '../../../models/device.model';
import { DeviceService } from '../../../services/device.service';
import { UiService } from '../../../services/ui.service';
import { WorkShiftsInterface } from '../../../models/work-shift.model';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { WorkShiftsService } from '../../../services/shift.service';
import * as moment from 'moment';
import { MatDialog, MatSlideToggleChange } from '@angular/material';
import { CalendarDialogComponent } from '../../ui/calendar-dialog/calendar-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { EnvConfigurationService } from '../../../services/env-config.service';

moment.locale(localStorage.getItem('lang'));

export interface CustomCalendarEvent<MetaType = any> extends CalendarEvent {
  shift?: number;
}


// Override method for formatting hour and header view
export class CustomDateFormatter extends CalendarDateFormatter {

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'EEE', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Component({
  selector: 'app-shifts',
  templateUrl: './work-shift.component.html',
  styleUrls: ['./work-shift.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})

export class ShiftsComponent implements OnInit {

  @Input() view = 'week';
  @Input() viewDate = new Date();
  @Input() locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

  events: CustomCalendarEvent<CustomCalendarEvent>[] = [];

  shifts: WorkShiftsInterface[];

  devices: DeviceInstance[];

  selectedDevice: DeviceInstance;

  __shiftId = 0;

  dragToCreateActive = false;

  onDeleting = false;

  forceStop: boolean;

  changeStop: boolean;

  startSession: boolean;


  refresh_changed: Subject<any> = new Subject();

  hourSegmentHeight: number;
  hourSegments: number;

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  constructor(
    private _device: DeviceService,
    private _dialog: MatDialog,
    private _shift: WorkShiftsService,
    private _ui: UiService,
    private _navbar: NavbarService,
    private _sidebar: SidebarService,
    private _translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private _envSettings: EnvConfigurationService
  ) { }

  async ngOnInit() {

    if (this._envSettings.settings['workshift']) {
      if (this._envSettings.settings['workshift']['shiftStep']) {
        switch (this._envSettings.settings['workshift']['shiftStep']) {
          case 5: //5 minutes
            this.hourSegmentHeight = 2;
            this.hourSegments = 12;
            break;
          case 15: //15 minutes
          default:
            this.hourSegmentHeight = 6;
            this.hourSegments = 4;
            break;
        }
      }
    }

    this.hourSegmentHeight = this.hourSegmentHeight || 6;
    this.hourSegments = this.hourSegments || 4;

    this._translate.stream([
      'work-shifts.title'
    ]).subscribe((translations) => {
      this._navbar.setTitle(translations['work-shifts.title']);
      setTimeout(() => this._sidebar.setSelected('shifts'));
    });

    this.devices = await this._device.getDevices();

    if (this.devices.length > 0) {
      if (localStorage.getItem('deviceId')) {
          const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
          if (device) this.selectedDevice = device;
      } else this.selectedDevice = this.devices[0];
      this.changeStop = !!this.selectedDevice.changeStopOnShift;
      this.forceStop = !!this.selectedDevice.forceStopFromShift;
      this.startSession = !!this.selectedDevice.startSessionOnShift;
      this.deviceChanged();
  }
  }

  async deviceChanged() {
    localStorage.setItem('deviceId', this.selectedDevice.id.toString());
    this.changeStop = !!this.selectedDevice.changeStopOnShift;
    this.forceStop = !!this.selectedDevice.forceStopFromShift;
    this.startSession = !!this.selectedDevice.startSessionOnShift;
    await this.fetchEvent();
    this.refresh();
    this.refresh_changed.next();
  }

  async cloneWeek() {

    const ref = this._dialog.open(CalendarDialogComponent, {
      width: '500px'
    });

    // tslint:disable-next-line: no-shadowed-variable
    ref.afterClosed().subscribe(async data => {
      // If data is definet user check "Continue"
      if (data != null) {
        // Get first day of week
        const startOView = addDays(startOfWeek(this.viewDate), 1);

        const copyOneYear = (data === 0) ? false : true;

        // copyOneYear: false copy one week; true copy util the end of the year
        const req = {
          beginAt: startOView,
          year: copyOneYear
        };

        // Send HTTP Request and get response
        const res = await this._shift.cloneWorkShift(this.selectedDevice.id, req);

        if (res) {
          await this.fetchEvent();
          this.refresh();
          // If shift was copied successfully show message and update local event
          this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.copy-success'));

        } else {
          this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.copy-error'));
        }
      }
    });
  }

  async startDragToCreate(
    segment: DayViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement,
  ) {

    const dragToSelectEvent: CustomCalendarEvent = {
      id: Number(this.__shiftId + 1),
      start: segment.date,
      title: '',
      draggable: true,
      meta: {
        tmpEvent: true
      },
      resizable: {
        beforeStart: true, // this allows you to configure the sides the event is resizable from
        afterEnd: true
      },
      actions: [
        {
          label: '<i class="fa fa-fw fa-times"></i>',
          onClick: async ({ event }: { event: CustomCalendarEvent }) => {
            this.onDeleting = true;
            this.events = this.events.filter(iEvent => iEvent !== event);
            const res = await this.deleteWorkShift(Number(event.id), this.selectedDevice.id);
            if (!res) {
              this.events.push(event);
            } else {
              await this.fetchDayEvent(event.start);
            }
            this.onDeleting = false;
          }
        }
      ]
    };

    this.events = [...this.events, dragToSelectEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;
    let endOfView = endOfWeek(this.viewDate);
    endOfView = addDays(endOfView, 2);

    fromEvent(document, 'mousemove')
      .pipe(
        finalize(async () => {

          try {
            // Count how many shift there are in the given day
            const events = this.events;
            const dayEvent = events.filter(
              (e) => e.start.toDateString() === dragToSelectEvent.start.toDateString()
            ).length;

            // If thene are four shift in the same day you cannot create another one
            if (dayEvent === 5) {

              // Open error message and remove created shift from event list
              this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.max-shift'));
              this.events = this.events.filter((e) => e.id !== this.__shiftId + 1);
            } else {
              // Set the number of shift in the given day
              dragToSelectEvent.shift = await this.setShift(dragToSelectEvent.start);

              // tslint:disable-next-line: max-line-length
              dragToSelectEvent.title = this.getTitle(dragToSelectEvent.start, dragToSelectEvent.end, dragToSelectEvent.shift);

              // Create object for API Call
              const newEvent = {
                deviceId: this.selectedDevice.id,
                beginAt: dragToSelectEvent.start.toISOString(),
                endAt: dragToSelectEvent.end.toISOString(),
                shift: dragToSelectEvent.shift
              };

              // Send HTTP Request and get response
              const res = await this._shift.createWorkShift(this.selectedDevice.id, newEvent);

              if (res) {
                // If shift was created successfully show message and increase the global Id
                this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.create-success'));
                // Assign last id to local variables tracker
                dragToSelectEvent.id = res.id;
                this.__shiftId = res.id;
                // Then fetch all shift of the given day to update shift number
                await this.fetchDayEvent(res.beginAt);
              } else {
                // If shift wans't create successfully show error message and remove shift from global event (shift) array
                this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.create-error'));
                this.events = this.events.filter((e) => e.id !== this.__shiftId + 1);
              }
            }
          } catch (err) {
            // If an error war catch remove shift from global event (shift) array
            this.events = this.events.filter((e) => e.id !== this.__shiftId + 1);
          }

          // Delete temporary shift object
          delete dragToSelectEvent.meta.tmpEvent;
          this.dragToCreateActive = false;

          // Refresh component
          this.refresh();
        }),
        takeUntil(fromEvent(document, 'mouseup'))
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = mouseMoveEvent.clientY - segmentPosition.top;
        const daysDiff = floorToNearest(mouseMoveEvent.clientX - segmentPosition.left, segmentPosition.width) / segmentPosition.width;
        const newEnd = addDays(addMinutes(segment.date, ceilToNearest(minutesDiff * 2.5, 15)), daysDiff);
        if (newEnd > segment.date && newEnd < endOfView) {
          dragToSelectEvent.end = newEnd;
        }
        const startDay = moment(segment.date).format('D MMMM YYYY');
        const startHour = moment(segment.date).format('H:mm');
        const endHour = moment(dragToSelectEvent.end).format('H:mm');
        dragToSelectEvent.title = `<br> ${startDay}<br><b>${startHour} - ${endHour}</b>`;
        this.refresh();
      });
  }

  async fetchDayEvent(beginAt: any): Promise<void> {

    const updateShift = {
      beginAt: beginAt
    };

    const updatedShift = await this._shift.updateDayShift(this.selectedDevice.id, updateShift);

    for (const shift of updatedShift) {
      this.events.map(async (e) => {

        if (e.id === shift.id) {

          e.shift = shift.shift;
          e.title = this.getTitle(shift.beginAt, shift.endAt, shift.shift);
        }
      });
    }
    this.refresh();
  }

  // Fetch all event from given device
  async fetchEvent(): Promise<void> {
    this.events = [];
    this.shifts = [];

    // Get all shift assigned to given device
    this.shifts = await this._shift.getWorkShift(this.selectedDevice.id);
    // Get max Id. Need it to create other shift
    this.__shiftId = Math.max(...this.shifts.map(s => s.id));
    // For each shift need to create an event to display it in calendar
    for (const shift of this.shifts) {

      this.events.push({
        id: shift.id,
        title: this.getTitle(shift.beginAt, shift.endAt, shift.shift),
        shift: shift.shift,
        start: new Date(shift.beginAt),
        end: new Date(shift.endAt),
        draggable: true,
        resizable: {
          beforeStart: true, // this allows you to configure the sides the event is resizable from
          afterEnd: true
        },
        actions: [
          {
            label: '<i class="fa fa-fw fa-times"></i>',
            onClick: async ({ event }: { event: CustomCalendarEvent }) => {
              this.onDeleting = true;
              this.events = this.events.filter(iEvent => iEvent !== event);
              const res = await this.deleteWorkShift(Number(event.id), this.selectedDevice.id);
              if (!res) {
                this.events.push(event);
              } else {
                await this.fetchDayEvent(event.start);
              }
              this.onDeleting = false;
            }
          }
        ]
      });
    }
  }

  async eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent) {
    if (!this.onDeleting) {
      try {
        const customEvent: CustomCalendarEvent = event;
        // check if nothing change
        if (customEvent.start.getTime() === newStart.getTime() && customEvent.end.getTime() === newEnd.getTime()) {
          this.refresh();
          return;
        }

        // check if there is another shift in new period
        const overideShift = await this.events.find((e) => {
          if (e.start.getTime() < newEnd.getTime() &&
            e.end.getTime() > newStart.getTime() &&
            e.id !== event.id) return true;
        });

        // return if find a shift
        if (overideShift) {
          this.refresh();
          return;
        }

        // set new start & end
        customEvent.start = newStart;
        customEvent.end = newEnd;
        // set new title
        customEvent.title = this.getTitle(customEvent.start, customEvent.end, customEvent.shift);
        // refresh object
        this.refresh_changed.next();

        const updateEvent = {
          beginAt: newStart || customEvent.start,
          endAt: newEnd || customEvent.end,
          shift: null
        };
        // if updated event has same start of old event not update shift
        if (customEvent.start === newStart) {
          updateEvent.shift = customEvent.shift;
        } else {
          updateEvent.shift = await this.setShift(newStart || customEvent.start) + 1;
        }
        // Send HTTP request
        const res = await this._shift.updateWorkShift(this.selectedDevice.id, Number(customEvent.id), updateEvent);
        if (res) {
          this.refresh_changed.next();
          await this.fetchDayEvent(res.beginAt);
          this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.update-success'));
        } else {
          this.refresh_changed.next();
          await this.fetchDayEvent(customEvent.start);
          this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.update-error'));
        }
      } catch (err) {
        this.refresh_changed.next();
        this.refresh();
      }
      return event;
    }
  }

  // Utility funcion to get and set shift number
  async setShift(start: Date): Promise<any> {

    const events = this.events;

    const dayEvent = events.filter(
      (e) => e.start.toDateString() === start.toDateString()
    );

    return Object.keys(dayEvent.filter(
      (e) => e.start.toTimeString() <= start.toTimeString()
    )).length;
  }

  // Utility function to get easy title for single event
  getTitle(start: Date, end: Date, shift: number): any {

    const startDay = moment(start).format('D MMMM YYYY');
    const startHour = moment(start).format('H:mm');
    const endHour = moment(end).format('H:mm');

    return `${this._translate.instant('work-shifts.shift')} ${shift}
    <br> ${startDay}<br>
    <b>${startHour} - ${endHour}</b>`;
  }

  private refresh() {
    this.events = [...this.events];
    this.cdr.detectChanges();
  }

  async deleteWorkShift(id: number, device: number) {
    const res = await this._shift.deleteWorkShift(id, device);
    if (res === 200) {
      this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.delete-success'));
      return res;
    } else {
      this._ui.openSnackBar(this._translate.instant('work-shifts.snack-bar.delete-error'));
      return null;
    }
  }

  public async onForceStopChange(event: MatSlideToggleChange) {

    if (event.checked !== this.forceStop) {
      this.forceStop = event.checked;
      await this._shift.updateForceStopDevice(this.selectedDevice.id, this.forceStop, 'forceStop');
      this.selectedDevice.forceStopFromShift = this.forceStop ? 1 : 0;
      this.devices.map((device) => {
        if (device.id === this.selectedDevice.id) {
          device = { ...device, forceStopFromShift: this.forceStop ? 1 : 0 };
        }
      });
    }
  }

  public async onChangeStopChange(event: MatSlideToggleChange) {

    if (event.checked !== this.changeStop) {
      this.changeStop = event.checked;
      await this._shift.updateForceStopDevice(this.selectedDevice.id, this.changeStop, 'changeStop');
      this.selectedDevice.changeStopOnShift = this.changeStop ? 1 : 0;
      this.devices.map((device) => {
        if (device.id === this.selectedDevice.id) {
          device = { ...device, forceStopFromShift: this.changeStop ? 1 : 0 };
        }
      });
    }
  }

  public async onStartSessionChange(event: MatSlideToggleChange) {

    if (event.checked !== this.startSession) {
      this.startSession = event.checked;
      await this._shift.updateForceStopDevice(this.selectedDevice.id, this.startSession, 'startSession');
      this.selectedDevice.startSessionOnShift = this.startSession ? 1 : 0;
      this.devices.map((device) => {
        if (device.id === this.selectedDevice.id) {
          device = { ...device, startSessionOnShift: this.startSession ? 1 : 0 };
        }
      });
    }
  }

  onRightClick() {
    return false;
  }

}
