import { Injectable, EventEmitter } from '@angular/core';

import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { CompanyService } from './company.service';
import { UserInstance, UserRole } from '../models/user.model';
import { CompanyInstance } from '../models/company.model';
import { Router } from '@angular/router';
import {ThemeService} from "./theme.service";

@Injectable()
export class AuthService {
    public user: UserInstance;
    public authenticated: boolean;
    public token: string;
    private company: CompanyInstance;

    public onNavbarChange = new EventEmitter<void>();

    constructor(
        private _http: HttpService,
        private _config: ConfigService,
        private _company: CompanyService,
        private router: Router,
        private _themeService: ThemeService
    ) {
        this.loadStateFromLocalStorage();
    }

    clearToken() {
        this.clearLocalStorage();
        this.loadStateFromLocalStorage();
    }

    async login(mail: String, password: String): Promise<any> {
        this.clearToken();
        const url = this._config.API.url + '/login';
        const data = {
            email: mail,
            password: password
        };

        const response = await this._http.post(url, data);
        this.user = response.json() as UserInstance;

        if (this.user.role !== UserRole.admin && this.user.role !== UserRole.manager) {
            throw new Error('no_role');
        }

        this.token = this.user.token;

        this.saveStateToLocalStorage();
        this.loadStateFromLocalStorage();

        this.onNavbarChange.emit();
    }

    async logout() {
        const url = `${this._config.API.url}/logout`;
        try {
            const response = await this._http.put(url, {});
            
            if (response.status === 200) {
                this.clearToken();
                this.router.navigateByUrl('/login');
            } else {
                return;
            }
        } catch (err) {
            if (err.status === 401 || err.status === 404) {
                // auth error
                return false;
            } else {
                // other error
                return false;
            }
        }
    }

    private clearLocalStorage() {
        localStorage.clear();
    }

    private async saveStateToLocalStorage() {
        localStorage.setItem('token', this.token);
        localStorage.setItem('id', this.user.id.toString());
        localStorage.setItem('email', this.user.email);
        localStorage.setItem('role', this.user.role.toString());
        if (this.user && this.user.companyId) {
            localStorage.setItem('companyId', this.user.companyId.toString());
        }
        if (this.user.lastName || this.user.firstName) {
            localStorage.setItem('lastName', this.user.lastName ? this.user.lastName : '');
            localStorage.setItem('firstName', this.user.firstName ? this.user.firstName : '');
        }
    }

    private loadStateFromLocalStorage() {
        const token = localStorage.getItem('token');

        if (token) {
            this.user = {
                id: parseInt(localStorage.getItem('id'), 10),
                email: localStorage.getItem('email'),
                role: parseInt(localStorage.getItem('role'), 10),
                firstName: localStorage.getItem('firstName'),
                lastName: localStorage.getItem('lastName'),
                companyId: parseInt(localStorage.getItem('companyId'), 10)
            };

            // Force logout and page reload when role is missing
            if (localStorage.getItem('role') === null || this.user.role === NaN) {
                this.clearLocalStorage();
                location.reload();
            }

            this.token = localStorage.getItem('token');
            this.authenticated = true;

            this._http.setDefaultHeaders({
                'Authorization': `bearer ${this.token}`
            });
        } else {
            this.user = null;
            this.authenticated = false;
            this.token = null;
        }

        this.checkPlantAndOrderManager();
    }

    private async checkPlantAndOrderManager() {

        if (this.user && this.user.role !== UserRole.admin) {
            localStorage.setItem('companyId', this.user.companyId.toString());
            const company: CompanyInstance = await this._company.getCompany(this.user.companyId);
            if (company.provider) {
                this._themeService.setTeamSystemTheme(company.provider === 2);
            }
            if (company.Plants && company.Plants.length) {
                this.user.Plants = company.Plants;
                localStorage.setItem('plantId', this.user.Plants[0].id.toString());
            }
            if (company.satelliteUrl) {
                if (await this.verifyInsertOrder(company.satelliteUrl)) {
                    localStorage.setItem('satelliteUrl', company.satelliteUrl);
                }
            }
        } else if(this.user && this.user.role == UserRole.admin) {
          this._themeService.setTeamSystemTheme(false);
        }
    }

    async verifyInsertOrder(uri: string): Promise<boolean> {
        const url = uri + '/plugins';

        try {
            const res = await this._http.get(url);
            return res.json()[1] === 'insertOrder' ? true : false;

        } catch (err) {
            return false;
        }
    }
}
