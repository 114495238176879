import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { OrderInstance } from '../models/order.model';
import { ProductInstance } from '../models/product.model';


@Injectable()
export class OrdersService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async get(satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/ordersList';
        else url = localStorage.getItem('satelliteUrl') + '/ordersList';

        const response = await this._http.get(url);
        return response.json() as OrderInstance[];

    }

    async getProducts(satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/products';
        else url = localStorage.getItem('satelliteUrl') + '/products';

        const response = await this._http.get(url);
        return response.json() as ProductInstance[];

    }

    async delete(code: string, satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/order/' + code;
        else url = localStorage.getItem('satelliteUrl') + '/order/' + code;

        const response = await this._http.put(url);
        return response.status;
    }

    async create(data: any, satelliteUrl?: string) {
        let url: string;
        if (satelliteUrl) url = satelliteUrl + '/order';
        else url = localStorage.getItem('satelliteUrl') + '/order';

        try {
            const response = await this._http.post(url, data);
            return response.status;
        } catch (err) {
            return err.status;
        }
    }
}
