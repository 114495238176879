import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { DeviceService } from '../../../services/device.service';
import { NavbarService } from '../../../services/navbar.service';
import { ProductService } from '../../../services/product.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';

import { DeviceInstance, SessionType } from '../../../models/device.model';
import { ProductInstance } from '../../../models/product.model';

import { DialogComponent } from '../../ui/dialog/dialog.component';
import { DevicesDialogComponent } from '../../ui/devices-dialog/devices-dialog.component';
import { ProductDialogComponent, SaveChoice } from '../../ui/product-dialog/product-dialog.component';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductsComponent implements OnInit {
    devices: DeviceInstance[];
    selectedDevice: DeviceInstance;
    timerMode: boolean;
    deltaMode: boolean;

    products: ProductInstance[];
    tmpProducts: ProductInstance[];
    columns = [];
    allColumns = [];
    codeSearch: string;
    editRow: number;
    modified: boolean;

    @ViewChild('code') codeTemplate: TemplateRef<any>;
    @ViewChild('name') nameTemplate: TemplateRef<any>;
    @ViewChild('devicesTemplate') devicesTemplate: TemplateRef<any>;
    @ViewChild('speedHeader') speedHeaderTemplate: TemplateRef<any>;
    @ViewChild('outputMultiplierHeader') multiplierHeaderTemplate: TemplateRef<any>;
    @ViewChild('speed') speedTemplate: TemplateRef<any>;
    @ViewChild('stopThreshold') stopThresholdTemplate: TemplateRef<any>;
    @ViewChild('startThreshold') startThresholdTemplate: TemplateRef<any>;
    @ViewChild('inputMultiplierHeader') inputMultiplierHeaderTemplate: TemplateRef<any>;
    @ViewChild('inputMultiplier') inputMultiplierTemplate: TemplateRef<any>;
    @ViewChild('outputMultiplier') outputMultiplierTemplate: TemplateRef<any>;
    @ViewChild('scrapsMultiplierHeader') scrapsMultiplierHeaderTemplate: TemplateRef<any>;
    @ViewChild('scrapsMultiplier') scrapstMultiplierTemplate: TemplateRef<any>;
    @ViewChild('dividerHeader') dividerHeaderTemplate: TemplateRef<any>;
    @ViewChild('divider') dividerTemplate: TemplateRef<any>;
    @ViewChild('actions') actionsTemplate: TemplateRef<any>;
    @ViewChild('enabled') enabledTemplate: TemplateRef<any>;
    @ViewChild('addItem') addItemTemplate: TemplateRef<any>;

    constructor(
        private _device: DeviceService,
        private _product: ProductService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _dialog: MatDialog,
        private _translate: TranslateService
    ) { }

    async ngOnInit() {
        this._translate.stream([
            'products.title',
            'products.columns.code',
            'products.columns.name',
            'products.columns.devices',
            'products.columns.stopThreshold',
            'products.columns.startThreshold',
            'products.columns.multiplier',
            'products.columns.output-multiplier',
            'products.columns.input-multiplier',
            'products.columns.scraps-multiplier',
            'products.columns.enabled'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['products.title']);
            setTimeout(() => this._sidebar.setSelected('products'));

            this.allColumns = [{
                resizeable: false,
                canAutoResize: true,
                name: translations['products.columns.code'],
                prop: 'code',
                cellTemplate: this.codeTemplate,
                comparator: this.codeComparator.bind(this)
            }, {
                resizeable: false,
                canAutoResize: true,
                name: translations['products.columns.name'],
                prop: 'name',
                cellTemplate: this.nameTemplate,
                comparator: this.codeComparator.bind(this)
            }, {
                resizable: true,
                canAutoResize: true,
                minWidth: 120,
                name: translations['products.columns.stopThreshold'],
                prop: 'stopProductsThresholdSec',
                cellTemplate: this.stopThresholdTemplate,
            }, {
                resizable: true,
                canAutoResize: true,
                minWidth: 120,
                name: translations['products.columns.startThreshold'],
                prop: 'resumeProductionProductsThreshold',
                cellTemplate: this.startThresholdTemplate,
            }, {
                resizable: true,
                canAutoResize: true,
                minWidth: 80,
                prop: 'idealPiecesPerMinutesSpeed',
                cellTemplate: this.speedTemplate,
                headerTemplate: this.speedHeaderTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['products.columns.input-multiplier'],
                minWidth: 120,
                prop: 'inputMultiplier',
                cellTemplate: this.inputMultiplierTemplate,
                headerTemplate: this.inputMultiplierHeaderTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                minWidth: 100,
                name: translations['products.columns.multiplier'],
                prop: 'multiplier',
                cellTemplate: this.outputMultiplierTemplate,
                headerTemplate: this.multiplierHeaderTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['products.columns.scraps-multiplier'],
                minWidth: 100,
                prop: 'scarpsMultiplier',
                cellTemplate: this.scrapstMultiplierTemplate,
                headerTemplate: this.scrapsMultiplierHeaderTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['products.columns.divider'],
                minWidth: 100,
                prop: 'divider',
                cellTemplate: this.dividerTemplate,
                headerTemplate: this.dividerHeaderTemplate
            }, {
                resizeable: true,
                canAutoResize: true,
                name: translations['products.columns.devices'],
                prop: 'Devices.length',
                cellTemplate: this.devicesTemplate,
                comparator: this.devicesComparator.bind(this)
            }, {
                resizeable: false,
                canAutoResize: true,
                width: 80,
                name: translations['products.columns.enabled'],
                cellTemplate: this.enabledTemplate,
                prop: 'enabled'
            }, {
                resizeable: false,
                canAutoResize: true,
                name: '',
                headerTemplate: this.addItemTemplate,
                cellTemplate: this.actionsTemplate
            }];
        });

        this.devices = await this._device.getDevices();

        if (this.devices.length > 0) {
            if (localStorage.getItem('deviceId')) {
                const device = this.devices.find((item) => item.id === Number(localStorage.getItem('deviceId')));
                if (device) this.selectedDevice = device;
            } else this.selectedDevice = this.devices[0];
            this.deviceChanged();
        }
    }

    async deviceChanged() {
        const deviceAttributes = ['id', 'label'];
        const deviceTmpAttributes = ['id', 'label'];
        localStorage.setItem('deviceId', this.selectedDevice.id.toString());
        this.products = await this._product.getProducts(this.selectedDevice.id, null, true, null, deviceAttributes, deviceTmpAttributes);
        this.tmpProducts = this.cloneArray(this.products);
        this.codeSearch = '';
        this.editRow = null;
        this.modified = false;
        this.timerMode = this.selectedDevice.sessionType === 2 ? true : false;
        this.deltaMode = this.selectedDevice.Unipi.sensorType === 1 ? true : false;
        this.columns = [];
        if (this.timerMode) {
            this.columns = [...this.allColumns];
            this.columns = this.allColumns.filter((v, index) => {
                return index === 0 ||
                    index === 1 ||
                    index === 4 ||
                    index === 8 ||
                    index === 9 ||
                    index === 10 ||
                    index === 11;
            });
        } else if (this.deltaMode) {
            this.columns = [...this.allColumns];
            // this.columns = this.allColumns.filter((v, index) => {
            //     return index !== 7;
            // });
        } else {
            this.columns = [...this.allColumns];
            this.columns = this.allColumns.filter((v, index) => {
                return index !== 5;
            });
        }
    }

    add() {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        const newProd = {
            id: null,
            name: '',
            code: '',
            companyId: this.selectedDevice.Company.id,
            Devices: [this.selectedDevice],
            enabled: true
        };

        this.products.unshift(newProd);
        this.products = [...this.products];
        this.tmpProducts = this.cloneArray(this.products);

        this.editRow = 0;
    }

    edit(rowIndex: number) {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        this.editRow = rowIndex;
    }

    async save(prod: ProductInstance) {
        let dialogResult = null;

        if (prod.Devices.length > 1 && (
            prod.stopProductsThreshold ||
            prod.resumeProductionProductsThreshold ||
            prod.idealPiecesPerMinutesSpeed ||
            prod.inputMultiplier ||
            prod.multiplier ||
            prod.scrapsMultiplier ||
            prod.divider)) {
            const ref = this._dialog.open(ProductDialogComponent, {
                width: '700px',
                data: {
                    title: this._translate.instant('dialogs.products.applychangestoalldevices_info_header'),
                    message: this._translate.instant('dialogs.products.applychangestoalldevices_info_text', {
                        deviceName: this.selectedDevice.label
                    }),
                    disabled: 'no'
                },
                disableClose: true
            });

            dialogResult = await ref.afterClosed().toPromise();

            if (!dialogResult || dialogResult === '') {
                return;
            }
        }

        let prodToSave = this.cloneObject(prod);
        if (!prod.scrapsMultiplier) prod.scrapsMultiplier = 1;
        if (!prod.multiplier) prod.multiplier = 1;
        if (!prod.inputMultiplier) prod.inputMultiplier = 1;
        if (!prod.divider) prod.divider = 1;

        if (prodToSave.id) {
            switch (dialogResult) {
                case SaveChoice.applyOne:
                    prodToSave = await this._product.updateForDevice(prodToSave, this.selectedDevice);
                    break;
                case SaveChoice.applyAll:
                default:
                    prodToSave = await this._product.update(prodToSave);
                    break;
            }

            if (!prodToSave) {
                this._ui.openSnackBar(this._translate.instant('devices.error'));
                return;
            }
            this.products[this.findWithAttr(this.products, 'id', prodToSave.id)] = this._product.formatProduct(prodToSave);
        } else {
            // const prodFound = this.products.find((product) => product.code === prod.code);
            // if (prodFound) {
            //     this._ui.openSnackBar(this._translate.instant('devices.error'));
            //     return;
            // }

            let createdProduct = null;

            switch (dialogResult) {
                case SaveChoice.applyOne:
                createdProduct = await this._product.createForDevice(prodToSave, this.selectedDevice);
                    break;
                case SaveChoice.applyAll:
                default:
                    createdProduct = await this._product.create(prodToSave);
                    break;
            }

            if (!createdProduct) {
                this._ui.openSnackBar(this._translate.instant('devices.error'));
                return;
            }

            this.products.shift();
            this.products.unshift(this._product.formatProduct(createdProduct));
        }

        this.products = [...this.products];
        this.tmpProducts = this.cloneArray(this.products);
        this.modified = false;
        this.editRow = null;
        this._ui.openSnackBar(this._translate.instant('products.saved'));
    }

    setModified() {
        this.modified = true;
    }

    async delete(prod: ProductInstance, index: number) {
        if (!prod.id) {
            this.products.shift();
            this.products = [...this.products];
            this.tmpProducts = this.cloneArray(this.products);
        } else {

            const ref = this._dialog.open(DialogComponent, {
                width: '600px',
                data: {
                    title: this._translate.instant('products.dialog.title'),
                    message: this._translate.instant('products.dialog.text', { prod: prod.code })
                }
            });

            ref.afterClosed().subscribe(async (result) => {
                if (result) {
                    const status = await this._product.delete(prod);
                    if (status === 200) {
                        this.tmpProducts.splice(index, 1);
                        this.products.splice(this.findWithAttr(this.products, 'id', prod.id), 1);
                        this.products = [...this.products];
                        this.tmpProducts = this.cloneArray(this.products);
                        this._ui.openSnackBar(this._translate.instant('products.deleted'));
                    } else {
                        this._ui.openSnackBar(this._translate.instant('devices.error') + ` (${status})`);
                    }
                }
            });
        }
        this.editRow = null;
        this.modified = false;
    }

    back(rowIndex: number) {
        if (this.products[rowIndex].id) {
            this.tmpProducts[rowIndex] = this.cloneObject(this.products[rowIndex]);
        } else {
            this.products.shift();
        }
        this.products = [...this.products];
        this.tmpProducts = this.cloneArray(this.products);

        this.editRow = null;
        this.modified = false;
    }
    async manageDevices(prod: ProductInstance, index: number) {
        let allDevices = this.devices ? this.devices.filter( dev => dev.Company && dev.Company.id === this.selectedDevice.Company.id) : [];
        allDevices = allDevices.filter( dev => dev.plantId === this.selectedDevice.plantId);

        const ref = this._dialog.open(DevicesDialogComponent, {
            width: '600px',
            data: {
                allDevices,
                selectedDevices: prod.Devices,
                companyId: prod.companyId
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {
                prod.Devices = result;
                this.setModified();
            }
        });
    }

    codeComparator(a, b) {
        return a.toUpperCase() > b.toUpperCase() || a === '' ? -1 : 1;
    }

    devicesComparator(a, b) {
        return a > b ? -1 : 1;
    }

    filter() {
        const tmp = this.products.filter((d) => {
            return d.code.toLowerCase().indexOf(this.codeSearch.toLowerCase()) > -1;
        });

        this.tmpProducts = this.cloneArray(tmp);
    }

    filterReset() {
        this.codeSearch = '';
        this.tmpProducts = this.cloneArray(this.products);
    }
    cloneArray(array: any[]) {
        return array.map((x) => {
            const y = Object.assign({}, x);
            if (y.idealPiecesPerMinutesSpeed && this.selectedDevice.sessionType === SessionType.timerMode) {
                y.idealPiecesPerMinutesSpeed = Math.round(60 / y.idealPiecesPerMinutesSpeed * 1000) / 1000;
            }
            return y;
        });
    }
    cloneObject(object: any) {
        const y = Object.assign({}, object);
        if (y.idealPiecesPerMinutesSpeed && this.selectedDevice.sessionType === SessionType.timerMode) {
            y.idealPiecesPerMinutesSpeed = Math.round(60 / y.idealPiecesPerMinutesSpeed * 1000) / 1000;
        }
        return y;
    }
    findWithAttr(array, attr, value) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    getStopTreshold(row: ProductInstance): string {
        if (row.stopProductsThresholdSec) {
            return '' + row.stopProductsThresholdSec;
        } else if (this.selectedDevice.stopProductsThresholdSec) {
            return '' + this.selectedDevice.stopProductsThresholdSec;
        } else {
            return '35';
        }
    }

    getStartTreshold(row: ProductInstance): string {
        if (row.resumeProductionProductsThreshold) {
            return '' + row.resumeProductionProductsThreshold;
        } else if (this.selectedDevice.resumeProductionProductsThreshold) {
            return '' + this.selectedDevice.resumeProductionProductsThreshold;
        } else {
            return '30';
        }
    }

    getMultiplier(row: ProductInstance): string {
        if (row.multiplier) {
            return '' + row.multiplier;
        } else if (this.selectedDevice.multiplier) {
            return '' + this.selectedDevice.multiplier;
        }
    }

    getInputMultiplier(row: ProductInstance): string {
        if (row.inputMultiplier) {
            return '' + row.inputMultiplier;
        } else if (this.selectedDevice.multiplier) {
            return '' + this.selectedDevice.multiplier;
        }
    }

    getScrapsMultiplier(row: ProductInstance): string {
        if (row.scrapsMultiplier) {
            return '' + row.scrapsMultiplier;
        } else if (this.selectedDevice.scrapsMultiplier) {
            return '1';
        }
    }

    getDivider(row: ProductInstance): string {
        if (row.divider) {
            return '' + row.divider;
        }

        return '1';
    }

    getMultiplierHeader(): string {
        if (!this.deltaMode) {
            return this.getUppercaseHeader('products.columns.multiplier');
        } else {
            return this.getUppercaseHeader('products.columns.output-multiplier');
        }
    }

    getUppercaseHeader(path: string) {
        return this._translate.instant(path).toUpperCase();
    }
}
