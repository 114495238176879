import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoaderService {
    private loaderSubject = new Subject<any>();
    private isStarted = false;
    public generalLoader$ = this.loaderSubject.asObservable();

    constructor() { }

    startLoader(message: string = '') {
        if (this.isStarted) { 
            return; 
        }
        this.isStarted = true;
        this.loaderSubject.next({ isLoading: true, message: message});
    }

    stopLoader() {
        if (!this.isStarted) {
            return;
        }
        this.isStarted = false;
        this.loaderSubject.next({isLoading: false});
    }

    getSubject(): Observable<any> {
        return this.generalLoader$;
    }
}
