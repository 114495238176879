import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ConfigService } from './config.service';
import { WorkProcessInstance } from '../models/work-process.model';
import { Phase } from '../models/phase.model';
import { Activity } from '../models/activity.model';

@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  constructor(private _http: HttpService,
    private _config: ConfigService) { }


  async create(phase: Phase, processId: number, deviceIds: number[]) {

    const url = `${this._config.API.url}/workprocess/phase`;

    const targetTime = phase.targetTimeInMinutes ? phase.targetTimeInMinutes : null;

    const data = {
      workProcessId: processId,
      plantId: phase.plantId,
      description: phase.description,
      enabled: phase.WorkProcessesPhases.enabled,
      targetTimeInMinutes: targetTime,
      deviceIds: deviceIds
    };

    const response = await this._http.post(url, data);

    return response.json() as Phase;
  }

  async update(processId: number, data: Phase[]) {
    const url = `${this._config.API.url}/workprocess/process/${processId}/phases`;

    const phase: any[] = [];

    data.forEach(p => {
      const data = {
        phaseId: p.id,
        enabled: p.WorkProcessesPhases.enabled ? true : false,
        order: p.WorkProcessesPhases.order
      };
      phase.push(data);
    });
    const response = await this._http.put(url, phase);
    return response;
  }

  async updatePhase(phase: Phase, deviceIds: number[]) {
    const url = `${this._config.API.url}/workprocess/phase/${phase.id}`;

    const data = {
      description: phase.description,
      targetTimeInMinutes: phase.targetTimeInMinutes,
      deviceIds: deviceIds
    };

    const response = await this._http.put(url, data);
    // return response.json() as Phase;
  }

  async updatePhaseActivities(phase: Phase, activities: Activity[]) {
    const url = `${this._config.API.url}/workprocess/phase/${phase.id}/activities`;

    const datas = [];

    activities.forEach(activity => {
      let data = {
        activityUnitId: activity.id,
        enabled: activity.PhasesActivityUnits.enabled ? true : false,
        order: activity.PhasesActivityUnits.order
      };
      datas.push(data);
    });

    const response = await this._http.put(url, datas);
    // return response.json() as Phase;
  }

  // TO DO

  // async duplicate(processId: number) {
  //     const url = `${this._config.API.url}/workprocess/process/${processId}/duplicate`;

  //     const response = await this._http.post(url);

  //     return response.json() as WorkProcessInstance;
  // }

  // async delete(phase: Phase) {
  //   const url = `${this._config.API.url}/workprocess/process/${phase.WorkProcessesPhases.processId}/phases/${phase.id}`;

  //   const response = await this._http.delete(url);

  //   return response;
  // }
}
