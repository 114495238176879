import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../../../models/user.model';
import { CompanyInstance } from '../../../models/company.model';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';
import { ProductInstance } from '../../../models/product.model';
import { WorkProcessInstance } from '../../../models/work-process.model';
import { Phase } from '../../../models/phase.model';
import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { DeviceService } from '../../../services/device.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { UiService } from '../../../services/ui.service';
import { QualityControlDialogComponent } from '../../ui/quality-control-dialog/quality-control-dialog.component';
import { WorkProcessService } from '../../../services/workProcess.service';
import { PhaseService } from '../../../services/phase.service';
import { LoaderService } from '../../../services/loader.service';

@Component({
    selector: 'app-process-management',
    templateUrl: './process-management.component.html',
    styleUrls: ['./process-management.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProcessManagementComponent implements OnInit {

    // @ViewChild('table') table: MatTable<Phase>;
    productChange: EventEmitter<any> = new EventEmitter();
    processChange: EventEmitter<any> = new EventEmitter();
    phaseChange: EventEmitter<any> = new EventEmitter();

    dropzoneHovered = false;
    blocProduct = false;
    blocProcess = false;
    blocPhase = false;

    columns = [];
    /** only if is admin */
    companies: CompanyInstance[];
    /** only if is normal user */
    company: CompanyInstance;

    plants: PlantInstance[] = [];

    selectedCompany: CompanyInstance;

    selectedPlant: PlantInstance;

    selectedCompanyId: number;

    selectedPlantId: number;

    devices: DeviceInstance[];

    products: ProductInstance[] = [];
    filteredProducts: ProductInstance[] = [];
    selectProducts: ProductInstance[] = [];

    processes: WorkProcessInstance[];
    filteredProcesses: WorkProcessInstance[] = [];
    selectProcesses: WorkProcessInstance[] = [];

    phases: Phase[];
    filteredPhases: Phase[] = [];
    selectPhases: Phase[] = [];

    selectedProduct: any;
    productPreview: string;

    selectedProcess: any;
    processPreview: string;

    selectedPhase: any;
    phasePreview: string;

    editRow: number;

    isChild: boolean;

    isAdmin = true;

    displayedColumns: string[] = ['image', 'name', 'activity', 'deviceId', 'enabled'];

    dataSource: Phase[] = [];

    modified = false;

    process_managementProcessForm = new FormGroup({
        processes: new FormControl('', []),
    });

    process_managementPhaseForm = new FormGroup({
        phases: new FormControl('', [])
    });

    process_managementProductForm = new FormGroup({
        products: new FormControl('', [])
    });

    constructor(
        private _ui: UiService,
        private _devices: DeviceService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _company: CompanyService,
        private _translate: TranslateService,
        private _router: Router,
        private auth: AuthService,
        private _dialog: MatDialog,
        private __workProcesses: WorkProcessService,
        private _phaseService: PhaseService,
        private _loaderService: LoaderService
    ) { }

    async ngOnInit() {

        this._translate.stream([
            'processManagement.title',
        ]).subscribe(async (translations) => {
            this._navbar.setTitle(translations['processManagement.title']);
            setTimeout(() => this._sidebar.selected = 'process-management');
        });

        this._loaderService.startLoader();
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.selectedPlantId = Number(localStorage.getItem('plantId'));
        this.selectedProduct = null;
        // check if user is Admin
        this.isAdmin = this.auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            // if is admin fetch all companies
            this.companies = await this._company.getCompanies();

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged();
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged();
            }
        } else {
            // if is user get company detailt to get plants
            this.company = await this._company.getCompany(this.selectedCompanyId);
            // set plants
            this.plants = this.company.Plants;
            await this._handleBuildPlantArrayObject(true);
            await this.plantChanged();
        }
        this._loaderService.stopLoader();
    }

    async _ngInitProducts() {
        // get products
        this.products = [];
        if (this.processes) {
            this.processes.forEach(process => {
                if (process.Products.length > 0) {
                    process.Products.forEach(product => {
                        this.products.push(product);
                    });
                }
            });
        }
    }

    async _ngInitPhases() {
        // get phases
        this.phases = [];
        if (this.processes) {
            this.processes.forEach(process => {
                if (process.WorkProcessPhases.length > 0) {
                    process.WorkProcessPhases.forEach(phase => {
                        if (phase.processIds == null) {
                            phase.processIds = [];
                            phase.processIds.push(process.id);
                        }
                        if (!this.isDuplicate(this.phases, phase)) {
                            this.phases.push(phase);
                        }
                    });
                }
            });
        }
    }

    async _ngInitWorkProcess() {
        // get products
        this.processes = await this.__workProcesses.getProcesses(this.selectedPlantId, true, true, true, true);
        for (const process of this.processes) {
            this._sortPhasesByOrder(process.WorkProcessPhases);
        }
    }

    // only if user is admin can change company
    async companyChanged() {
        this.selectedCompanyId = this.selectedCompany.id;
        localStorage.setItem('companyId', this.selectedCompanyId.toString());
        this.plants = this.selectedCompany.Plants;
        const isSelectedPlantInCurrentCompany = this.selectedPlantId ?
            this.selectedCompany.Plants.find((p) => p.id === this.selectedPlantId) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged();
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlantId || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId);
        }
    }

    async plantChanged() {
        if (this.selectedPlant) {
            this.selectedPlantId = this.selectedPlant.id;
            localStorage.setItem('plantId', this.selectedPlantId.toString());

            // get devices for selected plant
            this.devices = await this._devices.getDevicesByPlant(this.selectedCompanyId, this.selectedPlantId);

            // get product for selected plant
            await this._ngInitWorkProcess();
            await this._ngInitProducts();
            await this._ngInitPhases();

            this._sortProcesses(this.processes);
            this._sortProducts(this.products);
            this._sortPhases(this.phases);

            this.initializeFilters();
            this.editRow = null;
        }
    }

    private initializeFilters() {
        this.filteredProducts = [...this.products];
        this.filteredProcesses = [...this.processes];
        this.filteredPhases = [...this.phases];

        this.selectProcesses = [...this.filteredProcesses];
        this.selectProducts = [...this.filteredProducts];
        this.selectPhases = [...this.filteredPhases];

        this.selectedProduct = null;
        this.selectedProcess = null;
        this.selectedPhase = null;

        this.process_managementProductForm.reset();
        this.process_managementProcessForm.reset();
        this.process_managementPhaseForm.reset();

        this._buildFilterProductOption();
        this._buildFilterProcessOption();
        this._buildFilterPhaseOption();

    }

    /**
     * PRODUCT CONTROLLER
     */

    async addProduct(productId: number) {

        this.blocProduct = true;

        this.selectedProduct = this.products.find(p => p.id === productId);
        this.process_managementProductForm.get('products').setValue(this.selectedProduct.code + ' ' + this.selectedProduct.name);

        this._filterLists();

        // this._buildFilterProcessOption();
        // this._buildFilterPhaseOption();

        setTimeout(() => this.blocProduct = false, 1500);
    }

    private _sortProducts(list: ProductInstance[]): void {
        if (!list) {
            return;
        }
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.code.toUpperCase(); // ignore upper and lowercase
            const codeB = b.code.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    private async _buildFilterProductOption() {
        this.productChange.subscribe(description => {
            if (description) {
                this.selectProducts = this._filterProduct(description);
                this._sortProducts(this.selectProducts);
            } else {
                this.selectedProduct = null;
                this._filterLists();
            }
        });
    }

    private _filterProduct(value: string): ProductInstance[] {
        const filterValue = value.toLowerCase();
        this._sortProducts(this.filteredProducts);
        return this.filteredProducts.filter(product =>
            (product.name.toLowerCase().includes(filterValue) || product.code.toLowerCase().includes(filterValue)));
    }

    /**
     * PROCESS CONTROLLER
     */

    async addProcess(processId: number) {
        this.blocProcess = true;

        this.selectedProcess = this.processes.find(p => p.id === processId);
        this.process_managementProcessForm.get('processes').setValue(this.selectedProcess.description);

        // refresh products dropdown list
        this._filterLists();

        setTimeout(() => this.blocProcess = false, 1500);
    }

    private _sortProcesses(processes: WorkProcessInstance[]): void {
        if (!processes) {
            return;
        }
        // sort alphanumeric label
        processes.sort((a, b) => {
            const codeA = a.description.toUpperCase(); // ignore upper and lowercase
            const codeB = b.description.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0;
        });

        processes.forEach(p => {
            this._sortPhasesByOrder(p.WorkProcessPhases);
        });
    }

    private async _buildFilterProcessOption() {
        this.processChange.subscribe(description => {

            if (description) {
                this.selectProcesses = this._filterProcess(description);
                this._sortProcesses(this.selectProcesses);
            } else {
                this.selectedProcess = null;
                this._filterLists();
            }
        });
    }

    private _filterProcess(value: string): WorkProcessInstance[] {
        const filterValue = value.toLowerCase();

        return this.filteredProcesses.filter(process => process.description.toLowerCase().includes(filterValue));
    }

    /**
     * PHASES CONTROLLER
     */

    async addPhase(phaseId: number) {
        this.blocPhase = true;
        this.selectedPhase = this.phases.find(p => p.id === phaseId);
        this.process_managementPhaseForm.get('phases').setValue(this.selectedPhase.description);

        // refresh products dropdown list
        this._filterLists();

        setTimeout(() => this.blocPhase = false, 1500);
    }

    private _sortPhases(list: Phase[]): void {
        if (!list) {
            return;
        }
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.description.toUpperCase(); // ignore upper and lowercase
            const codeB = b.description.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }
    private _sortPhasesByOrder(list: Phase[]): void {
        if (!list) {
            return;
        }
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.WorkProcessesPhases.order; // ignore upper and lowercase
            const codeB = b.WorkProcessesPhases.order; // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    private async _buildFilterPhaseOption() {
        this.phaseChange.subscribe(description => {
            if (description) {
                this.selectPhases = this._filterPhase(description);
                this._sortPhases(this.selectPhases);
            } else {
                this.selectedPhase = null;
                this._filterLists();
            }
        });
    }

    private _filterPhase(value: string): Phase[] {
        const filterValue = value.toLowerCase();

        return this.filteredPhases.filter(phase =>
            phase.description.toLowerCase().includes(filterValue));
    }

    // Functions to calculate lists

    private calculateProcessesList() {
        const productSelected = this.selectedProduct != null ? true : false;
        const processSelected = this.selectedProcess != null ? true : false;
        const phaseSelected = this.selectedPhase != null ? true : false;

        const filteredProcesses: WorkProcessInstance[] = this._selectProcessFiltered(processSelected, phaseSelected, productSelected) || [];
        return filteredProcesses;
    }

    private _filterLists() {

        const filteredPhases: Phase[] = [];
        const filteredProducts: ProductInstance[] = [];

        const filteredProcesses: WorkProcessInstance[] = this.calculateProcessesList();
        this.filteredProcesses = filteredProcesses;
        this.selectProcesses = [...this.filteredProcesses];

        if (filteredProcesses.length > 0) {
            if (filteredProcesses.length > 1) {
                filteredProcesses.forEach(process => {
                    process.Products.forEach(Product => {
                        filteredProducts.push(Product);
                    });
                    process.WorkProcessPhases.forEach(phase => {
                        if (!this.isDuplicateFilterList(filteredPhases, phase)) {
                            filteredPhases.push(phase);
                        }
                    });
                });

                this.filteredProducts = filteredProducts || [];
                this.filteredPhases = filteredPhases || [];

                this.selectProducts = [...this.filteredProducts];
                this.selectPhases = [...this.filteredPhases];
            } else {
                this.filteredProducts = filteredProcesses[0].Products;
                this.filteredPhases = filteredProcesses[0].WorkProcessPhases;

                this.selectProducts = [...this.filteredProducts];
                this.selectPhases = [...this.filteredPhases];
            }

            this._sortProducts(this.filteredProducts);
            this._sortProcesses(this.filteredProcesses);

            this._sortProducts(this.selectProducts);
            this._sortProcesses(this.selectProcesses);
        }
    }

    private _selectProcessFiltered(process: boolean, phase: boolean, product: boolean): WorkProcessInstance[] {
        let filteredProcess: WorkProcessInstance[] = [];
        if (product) {
            if (this.processes && this.processes.length) {
                this.processes.forEach(proc => {
                    proc.Products.forEach(Product => {
                        if (Product.id === this.selectedProduct.id) {
                            filteredProcess.push(proc);
                        }
                    });
                });
            }
        } else if (process) {
            this.processes.forEach(Process => {
                if (this.selectedProcess && Process.id === this.selectedProcess.id) {
                    filteredProcess.push(Process);
                }
            });
        } else if (phase) {
            if (this.processes && this.processes.length) {
                this.processes.forEach(proc => {
                    if (this.selectedPhase && this.selectedPhase.processIds.includes(proc.id)) {
                        filteredProcess.push(proc);
                    }
                });
            }
        } else {
            filteredProcess = this.processes;
        }
        return filteredProcess;
    }

    // Actions

    async addNew(processId?: number, phaseId?: number) {
        let url = `process-management/new-process`;
        let editable = false;

        if (processId && !phaseId) {
            const res = await this.__workProcesses.isEditable(processId);
            if (res.result) {
                url = `process-management/${processId}/new-phase`;
                editable = true;
            } else {
                this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
            }
        }

        if (phaseId) {
            const res = await this.__workProcesses.isEditable(processId);
            if (res.result) {
                url = `process-management/${processId}/phase/${phaseId}/new-activity`;
                editable = true;
            } else {
                this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
            }
        }

        if (editable || (!processId && !phaseId)) {
            this._router.navigate([url]);
        }
    }

    async editProcess(row: WorkProcessInstance) {
        this._loaderService.startLoader();
        const res = await this.__workProcesses.isEditable(row.id);
        this._loaderService.stopLoader();

        if (res.result) {
            this._router.navigate(['/process-management', row.id]);
        } else {
            this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
        }
    }

    async editPhase(phase: Phase, processId: number) {
        this._loaderService.startLoader();
        const res = await this.__workProcesses.isEditable(processId);
        this._loaderService.stopLoader();

        if (res.result) {
            this._router.navigate(['/process-management', phase.WorkProcessesPhases.processId, 'phase', phase.id]);
        } else {
            this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
        }
    }

    async setModifiedProcess(process: WorkProcessInstance) {
        if (process) {
            const res = await this.__workProcesses.isEditable(process.id);
            if (res.result) {
                await this.__workProcesses.update(process.id, process);
            } else {
                this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
            }
        }
    }

    public async setModifiedPhase(phase: Phase, processId: number) {
        const res = await this.__workProcesses.isEditable(processId);
        let wpEnabled = false;

        if (res.result) {
            if (phase) {
                let process = this.filteredProcesses.find(p => p.id === phase.WorkProcessesPhases.processId);
                process.WorkProcessPhases.forEach(phase => {
                    if (phase.WorkProcessesPhases.enabled) {
                        wpEnabled = true;
                    }
                });
                if (wpEnabled === false) {
                    process.enabled = wpEnabled;
                    await this.__workProcesses.update(process.id, process);
                    this._ui.openSnackBar(this._translate.instant('process.noPhaseActive'));
                } 
                const res = await this._phaseService.update(process.id, process.WorkProcessPhases);   
            }
        } else {
            this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
        }
    }

    public async deleteProcess(processId: number) {
        this._loaderService.startLoader();
        const res = await this.__workProcesses.isEditable(processId);
        if (res.result) {
            if (processId) {
                const res = await this.__workProcesses.delete(processId);
                this._loaderService.stopLoader();

                if (res.status === 200) {
                    if (this.selectedProcess && this.selectedProcess.id === processId) {
                        this.selectedProcess = null;
                    }
                    this.processes = this.processes.filter(t => t.id !== processId);
                    this._sortProcesses(this.processes);
                    this._filterLists();
                } else {
                    this._ui.openSnackBar(this._translate.instant('processManagement.deleted-error'));
                }
            } else {
                this._loaderService.stopLoader();
            }
        } else {
            this._loaderService.stopLoader();
            this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
        }
    }

    async deletePhase(phase: Phase, processId: number) {
        this._loaderService.startLoader();
        const res = await this.__workProcesses.isEditable(processId);
        if (res.result) {
            if (phase) {
                this.rebuildPhaseList(phase);
    
                const processId = phase.WorkProcessesPhases.processId;
                const process = this.processes.find(p => p.id === processId);
                let order = 1;
                for (const ph of process.WorkProcessPhases) {
                    ph.WorkProcessesPhases.order = order;
                    order++;
                }
                
                const res = await this._phaseService.update(processId, process.WorkProcessPhases);
                this._loaderService.stopLoader();

                if (res.status === 200) {
                    if (this.selectedPhase && this.selectedPhase.processIds.includes(phase.WorkProcessesPhases.processId)) {
                        this.selectedPhase.processIds = this.selectedPhase.processIds.filter(n => n !== phase.WorkProcessesPhases.processId);
                        if (this.selectedPhase.length === 0) {
                            this.selectedPhase = null;
                        }
                    }
                    this._filterLists();
                } else {
                    this._ui.openSnackBar(this._translate.instant('processManagement.deleted-error'));
                }
            } else {
                this._loaderService.stopLoader();
            }
        } else {
            this._loaderService.stopLoader();
            this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
        }
    }

    private rebuildPhaseList(phase: Phase) {
        this.processes.forEach(process => {
            process.WorkProcessPhases.forEach(ph => {
                if (ph.id === phase.id) {
                    ph.processIds = ph.processIds.filter(number => number !== phase.WorkProcessesPhases.processId);
                }
            });
            if (process.id === phase.WorkProcessesPhases.processId) {
                process.WorkProcessPhases = process.WorkProcessPhases.filter(p => p.id !== phase.id);
            }
        });
    }

    async duplicateProcess(event: Event, process: WorkProcessInstance) {
        if (process) {
            this._loaderService.startLoader();
            const res = await this.__workProcesses.duplicate(process.id);
            if (res) {
                res.Products = [];
                this.processes.push(res);
                for (const phase of res.WorkProcessPhases) {
                    this.addDuplicate(phase, res.id);
                }
                this._ngInitPhases();
                this._filterLists();

                this._loaderService.stopLoader();
                this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-successfully'));
            } else {
                this._loaderService.stopLoader();
                this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-error'));
            }
        }
    }

    // NON UTILIZZATO, MANCA BE
    // async duplicatePhase(phase: Phase) {
    //     if (phase) {
    //         const res = 0;
    //         // await this.__workProcesses.duplicate(process);
    //         if (res) {
    //             this.phases.push(res);
    //             this._filterLists();
    //             this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-successfully'));
    //         } else {
    //             this._ui.openSnackBar(this._translate.instant('quality-controls.duplicated-error'));
    //         }
    //     }
    // }

    async dropTable(event: CdkDragDrop<string[]>, process: WorkProcessInstance) {
        const processId = process.id;
        if (process) {
            this._loaderService.startLoader();
            const res = await this.__workProcesses.isEditable(processId);
            if (res.result) {
                const selectedProcess: WorkProcessInstance = this.processes.find(p => p.id === processId);
                moveItemInArray(selectedProcess.WorkProcessPhases, event.previousIndex, event.currentIndex);
                let order = 1;
                for (const phase of selectedProcess.WorkProcessPhases) {
                    phase.WorkProcessesPhases.order = order;
                    order++;
                }
                await this._phaseService.update(processId, selectedProcess.WorkProcessPhases);
                this._loaderService.stopLoader();
            } else {
                this._loaderService.stopLoader();
                this._ui.openSnackBar(this._translate.instant('processManagement.edit-error'));
            }
        }
    }

    openDialog(process: WorkProcessInstance) {
        let labels: string[] = [];

        const products = process.Products;
        labels = products.map((prod) => prod.code + prod.name);

        this._dialog.open(QualityControlDialogComponent, {
            width: '600px',
            height: '600px',
            data: {
                key: 'quality-controls.columns.products',
                values: labels
            }
        });
    }

    private isDuplicate(phases: Phase[], phaseDuplicate: Phase): boolean {
        let truth = false;
        if (phases.length > 0) {
            phases.forEach(phase => {
                if (!phase.processIds.includes(phaseDuplicate.WorkProcessesPhases.processId)) {
                    phase.processIds.push(phaseDuplicate.WorkProcessesPhases.processId);
                }
                if (phase.id === phaseDuplicate.id) {
                    truth = true;
                }
            });
        }
        return truth;
    }

    private addDuplicate(phaseDuplicate: Phase, processId: number) {
        if (this.phases.length > 0) {
            this.phases.forEach(phase => {
                if (phase.id === phaseDuplicate.id) {
                    if (!phase.processIds.includes(processId)) {
                        phase.processIds.push(processId);
                    }
                }
            });
        }
    }

    private isDuplicateFilterList(phases: Phase[], phaseDuplicate: Phase): boolean {
        let truth = false;
        if (phases.length > 0) {
            phases.forEach(phase => {
                if (phase.id === phaseDuplicate.id) {
                    phase.processIds.push(phaseDuplicate.WorkProcessesPhases.processId);
                    truth = true;
                }
            });
        }
        return truth;
    }

    filterReset(reset: string) {
        switch (reset) {
            case 'process': {
                this.process_managementProcessForm.reset();
                break;
            }
            case 'product': {
                this.process_managementProductForm.reset();
                break;
            }
            case 'phase': {
                this.process_managementPhaseForm.reset();
                break;
            }
        }
    }

    canEnableProcess(process: WorkProcessInstance) {
        return process.WorkProcessPhases.some(ph => ph.WorkProcessesPhases.enabled);
    }
}
