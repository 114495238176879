
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { QRCodeModule } from 'angular2-qrcode';
import { MomentModule } from 'ngx-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { DeviceService } from './services/device.service';
import { ErrorService } from './services/error.service';
import { HttpService } from './services/http.service';
import { NavbarService } from './services/navbar.service';
import { ProductService } from './services/product.service';
import { SidebarService } from './services/sidebar.service';
import { UiService } from './services/ui.service';
import { ProcessDataService } from './services/processData.service';
import { ViewService } from './services/view.service';

import { Utility } from './utils/utility';

import {
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatExpansionModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatGridListModule
} from '@angular/material';

import { CmsComponent } from './cms/cms.component';

import { ErrorsComponent } from './components/pages/errors/errors.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { LoginComponent } from './components/pages/login/login.component';
import { TagsComponent } from './components/pages/tags/tags.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UsersComponent } from './components/pages/users/users.component';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { DialogComponent } from './components/ui/dialog/dialog.component';
import { DevicesDialogComponent } from './components/ui/devices-dialog/devices-dialog.component';
import { ProductDialogComponent } from './components/ui/product-dialog/product-dialog.component';
import { ErrorDialogComponent } from './components/ui/error-dialog/error-dialog.component';
import { QrDialogComponent } from './components/ui/qr-dialog/qr-dialog.component';
import { InformativeDialogComponent } from './components/ui/informative-dialog/informative-dialog.component';
import { NewDeviceComponent } from './components/pages/new-device/new-device.component';
import { NewDeviceDialogComponent } from './components/ui/new-device-dialog/new-device-dialog.component';
import { NewVirtualDeviceDialogComponent } from './components/ui/new-virtual-device-dialog/new-virtual-device-dialog.component';
import { NewThirdPartyDeviceDialogComponent } from './components/ui/new-thirdparty-device-dialog/new-thirdparty-device-dialog.component';
import { CompanyService } from './services/company.service';
import { ModulesService } from './services/modules.service';
import { TagsService } from './services/tag.service';
import { FilterCategoryPipe } from './pipes/filter-category.pipe';
import { ShiftsComponent } from './components/pages/work-shift/work-shift.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { UserService } from './services/user.service';
import { WorkShiftsService } from './services/shift.service';
import { CalendarDialogComponent } from './components/ui/calendar-dialog/calendar-dialog.component';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeGr from '@angular/common/locales/el';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TaskService } from './services/task.service';
import { CenterMatmenuDirective } from './directives/center-matmenu.directive';
import { ControlSchedulingService } from './services/controlScheduling.service';
import { WINDOW_PROVIDERS } from './services/window.service';
import { FileSizePipe } from './pipes/file-size.pipe';
import { QualityControlDialogComponent } from './components/ui/quality-control-dialog/quality-control-dialog.component';
import { OrdersComponent } from './components/pages/orders/orders.component';
import { OrdersService } from './services/orders.service';
import { OrdersCoreService } from './services/ordersCore.service';
import { TaskDialogComponent } from './components/ui/task-dialog/task-dialog.component';
import { QualityControlsComponent } from './components/pages/quality-controls/quality-controls.component';
import { ControlComponent } from './components/pages/control/control.component';
import { ControlsService } from './services/controls.service';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CompaniesComponent } from './components/pages/companies/companies.component';
import { ProcessDataComponent } from './components/pages/process-data/process-data.component';
import { PlantService } from './services/plant.service';
import { OrdersCoreComponent } from './components/pages/orders-core/orders-core.component';
import { OrderCoreDialogComponent } from './components/ui/order-core-dialog/order-core-dialog.components';
import { OrderDeviceCoreDialogComponent } from './components/ui/order-device-core-dialog/order-device-core-dialog.components';
import { PluginComponent } from './components/pages/plugin/plugin.components';
import { PluginService } from './services/plugin.service';
import { WorkProcessService } from './services/workProcess.service';
import { UnitService } from './services/unit.service';
import { ProcessManagementComponent } from './components/pages/process-management/process-management.component';
import { ProcessComponent } from './components/pages/process/process.component';
import { ThemeService } from "./services/theme.service";
import { PageWithLoader } from "./components/pages/page-with-loader";
import { PlantModuleDialogComponent } from './components/ui/plant-module-dialog/plant-module-dialog.component';
import { DeviceModuleDialogComponent } from './components/ui/device-module-dialog/device-module-dialog.component';
import { LoaderService } from './services/loader.service';
import { LoaderComponent } from './components/ui/loader/loader.component';
import { EnvConfigurationService } from './services/env-config.service';
import { APP_INITIALIZER } from '@angular/core';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeGr);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function initConfig(configService: EnvConfigurationService) {
    // load the config file in this function
    return () => configService.load()
}

@NgModule({
    declarations: [
        AppComponent,
        CmsComponent,
        ErrorsComponent,
        LoginComponent,
        NavbarComponent,
        ProductsComponent,
        DialogComponent,
        DevicesDialogComponent,
        ProductDialogComponent,
        ErrorDialogComponent,
        QrDialogComponent,
        InformativeDialogComponent,
        NewDeviceDialogComponent,
        NewVirtualDeviceDialogComponent,
        NewThirdPartyDeviceDialogComponent,
        ProcessDataComponent,
        CalendarDialogComponent,
        DevicesComponent,
        NewDeviceComponent,
        TagsComponent,
        ShiftsComponent,
        UsersComponent,
        QualityControlsComponent,
        ControlComponent,
        TaskDialogComponent,
        QualityControlDialogComponent,
        OrdersComponent,
        OrdersCoreComponent,
        CategoriesComponent,
        CompaniesComponent,
        FileSizePipe,
        FilterCategoryPipe,
        ClickOutsideDirective,
        CenterMatmenuDirective,
        OrderCoreDialogComponent,
        OrderDeviceCoreDialogComponent,
        PluginComponent,
        ProcessManagementComponent,
        ProcessComponent,
        PageWithLoader,
        PlantModuleDialogComponent,
        DeviceModuleDialogComponent,
        LoaderComponent,
        PageWithLoader
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTableModule,
        MatExpansionModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        MatAutocompleteModule,
        NgxDatatableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatGridListModule,
        QRCodeModule,
        MomentModule,
        DragDropModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],

    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [EnvConfigurationService],
            multi: true
        },
        AuthService,
        ConfigService,
        CompanyService,
        ModulesService,
        DeviceService,
        ErrorService,
        HttpService,
        NavbarService,
        ProductService,
        WorkProcessService,
        SidebarService,
        UiService,
        TagsService,
        ControlSchedulingService,
        WorkShiftsService,
        ClickOutsideDirective,
        UserService,
        OrdersService,
        OrdersCoreService,
        ControlsService,
        TaskService,
        UnitService,
        PlantService,
        Utility,
        ProcessDataService,
        PluginService,
        ViewService,
        ThemeService,
        LoaderService,
        WINDOW_PROVIDERS
    ],

    bootstrap: [AppComponent],

    entryComponents: [
        DialogComponent,
        DevicesDialogComponent,
        ProductDialogComponent,
        ErrorDialogComponent,
        QrDialogComponent,
        InformativeDialogComponent,
        NewDeviceDialogComponent,
        NewVirtualDeviceDialogComponent,
        NewThirdPartyDeviceDialogComponent,
        CalendarDialogComponent,
        TaskDialogComponent,
        QualityControlDialogComponent,
        OrderCoreDialogComponent,
        OrderDeviceCoreDialogComponent,
        PlantModuleDialogComponent,
        DeviceModuleDialogComponent
    ]
})
export class AppModule { }
