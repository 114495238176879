import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import { UserRole } from '../../../models/user.model';

import { SidebarService } from '../../../services/sidebar.service';
import { AuthService } from '../../../services/auth.service';
import { NavbarService } from '../../../services/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewService, UserView } from '../../../services/view.service';
import {ThemeService} from "../../../services/theme.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

    private control_details = '/details';
    private _filterSubscription;


    languages = [
        { code: 'en', locale: 'en-US', label: 'English' },
        { code: 'it', locale: 'it-IT', label: 'Italiano' },
        { code: 'de', locale: 'de-DE', label: 'Deutsch' },
        { code: 'fr', locale: 'fr-FR', label: 'Français' },
        { code: 'el', locale: 'el-GR', label: 'Ελληνικά' }
    ];

    currentLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

    showMenu = false;

    isAdmin = false;
    isOrdersCore = false;
    isManager = false;
    isOrdersManager = false;
    isChildPage: boolean;
    isWorkProcessManager: boolean = false;

    constructor(
        public sidebar: SidebarService,
        private _router: Router,
        public _auth: AuthService,
        public navbar: NavbarService,
        public _translate: TranslateService,
        public _viewService: ViewService,
        private _themeService: ThemeService
    ) { }

    ngOnInit() {
        this._filterSubscription = this._auth.onNavbarChange.subscribe(() => {
            this.navbarChange();
        });

        if (!!this._auth && !!this._auth.user) {
            this.isAdmin = this._auth.user.role === UserRole.admin;
            this.isManager = this._auth.user.role === UserRole.manager;

            this.isOrdersManager = this.isAdmin || localStorage.getItem('satelliteUrl') ? true : false;
            
            this._viewService.userCanView(this._auth.user, UserView.ordersCore)
                            .then( userCanViewOrdersCore => {
                                this.isOrdersCore = userCanViewOrdersCore;
                            });
            this._viewService.userCanView(this._auth.user, UserView.workProcess)
                            .then( userCanViewWorkProcess => {
                                this.isWorkProcessManager = userCanViewWorkProcess;
                            });

        }
        this._router.events.subscribe(() => {
            // see also
            this.isControlPage();
        });
    }

    ngOnDestroy() {
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
    }

    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isLoginPage(): boolean {
        return this._router.isActive('/login', true);
    }

    setLanguage(locale: string) {
        if (this._translate.currentLang === locale) {
            return;
        }

        localStorage.setItem('lang', locale);
        window.location.reload();
    }

    logout() {
        this._auth.logout();
    }
    toggleMenu() {
        this.showMenu = !this.showMenu;
    }

    isControlPage() {
        const url = this._router.url;
        this.isChildPage = url.includes(this.control_details);
    }

    goBack() {
        this._router.navigate(['/quality-controls']);
    }

    private navbarChange() {
        this.isAdmin = this._auth.user.role === UserRole.admin;
        // this.isOrdersCore = this.isAdmin || this._auth.user.role === UserRole.manager;
        this.isManager = this._auth.user.role === UserRole.manager;
        this.isOrdersManager = this.isAdmin || localStorage.getItem('satelliteUrl') ? true : false;

        this._viewService.userCanView(this._auth.user, UserView.ordersCore)
                            .then( userCanViewOrdersCore => {
                                this.isOrdersCore = userCanViewOrdersCore;
                            });
        this._viewService.userCanView(this._auth.user, UserView.workProcess)
                            .then( userCanViewWorkProcess => {
                                this.isWorkProcessManager = userCanViewWorkProcess;
                            });
    }

  getDirImages() {
      //console.log(this._themeService.isTeamSystemThemeSetted());
      return this._themeService.isTeamSystemThemeSetted() ? 'team_system/' : '';
  }
}
