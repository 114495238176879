import { Component, OnInit, ViewEncapsulation, Inject, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, NativeDateAdapter, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { OrderCoreInstance } from '../../../models/order-core.model';
import { ProductInstance } from '../../../models/product.model';
import { DeviceInstance } from '../../../models/device.model';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { WorkProcessInstance } from '../../../models/work-process.model';
import { FormControl, FormGroup } from '@angular/forms';

export const PICK_FORMATS = {
    parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};

export class PickDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        const locale = localStorage.getItem('lang') || 'en-EN';
        if (displayFormat === 'input') {
            return formatDate(date, 'dd/MM/yyyy', locale);
        } else {
            return formatDate(date, 'EEEE, dd MMM yyyy', locale).toString();
        }
    }
}

export interface JsonInfo{
    key: string;
    value: string;
}

@Component({
    selector: 'app-order-core-dialog',
    templateUrl: './order-core-dialog.component.html',
    styleUrls: ['./order-core-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: DateAdapter, useClass: PickDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
    ]
})
export class OrderCoreDialogComponent implements OnInit {

    productChange: EventEmitter<any> = new EventEmitter();

    orderCoreProductForm = new FormGroup({
        products: new FormControl('', [])
    });

    order: OrderCoreInstance;
    plantProducts: ProductInstance[];
    products: ProductInstance[];
    selectedProducts: ProductInstance[];
    processes: WorkProcessInstance[];
    columns = [];
    devices: DeviceInstance[] = [];
    selectedDevicesControl = new FormControl();
    selectedProduct: ProductInstance;
    selectedProcess: WorkProcessInstance;
    isSelectAll = false;
    selectProcess;
    jsonInfoArray: JsonInfo[];

    minDate: Date;

    @ViewChild('checkbox') checkboxTemplate: TemplateRef<any>;
    @ViewChild('checkboxHeader') checkboxHeaderTemplate: TemplateRef<any>;

    constructor(
        private _ref: MatDialogRef<OrderCoreDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { order?: OrderCoreInstance, products: ProductInstance[], processes: WorkProcessInstance[] },
        private _translate: TranslateService,
    ) { }

    async ngOnInit() {
        this.order = this.cloneObject(this.data.order);
        this.plantProducts = this.clone(this.data.products);
        this.processes = this.clone(this.data.processes);

        this.minDate = new Date();

        if (this.order.Product) {
            this.selectedProduct = this.cloneObject(this.order.Product);
            if (this.selectedProduct.id !== -1) {
                this.orderCoreProductForm.get('products').setValue(this.selectedProduct.code + ' ' + this.selectedProduct.name);
            } else {
            this.selectedProducts = this.clone(this.plantProducts);
            }
        } else {
            this.selectedProduct = this.createEmptyProduct();
            this.selectedProducts = this.clone(this.plantProducts);
        }

        this._buildFilterProductOption();

        if (this.order.WorkProcess) {
            this.selectedProcess = this.cloneObject(this.order.WorkProcess);
            this.selectProcess = true;
            const process = this.processes.find(p => p.id === this.selectedProcess.id);
            this.products = this.clone(process.Products);
        } else {
            this.selectedProcess = this.createEmptyProcess();
            this.products = this.clone(this.plantProducts);
        }

        if (this.order.Devices) {
            const product = this.products.find(p => p.id === this.selectedProduct.id);
            if (product && product.Devices) {
                this.devices = this.clone(product.Devices);
                this.selectedDevicesControl.patchValue(this.order.Devices);
            }
        }

        this.jsonInfoArray = this.createJsonInfoArray();

        this._translate.get('dialogs.devices.select').subscribe((res) => {
            this.columns = [{
                name: 'dialog.devices.select-all',
                resizeable: false,
                canAutoResize: false,
                width: 250,
                cellTemplate: this.checkboxTemplate,
                headerTemplate: this.checkboxHeaderTemplate
            }, {
                resizeable: false,
                name: res,
                prop: 'label',
                canAutoResize: false,
                width: 300
            }];
        });
    }

    /**
     * PRODUCT CONTROLLER
     */

    private _refreshProducts(list: ProductInstance[]): void {
        // sort alphanumeric label
        list.sort((a, b) => {
            const codeA = a.code.toUpperCase(); // ignore upper and lowercase
            const codeB = b.code.toUpperCase(); // ignore upper and lowercase

            if (codeA < codeB) return -1;
            if (codeA > codeB) return 1;
            return 0; // names must be equal
        });
    }

    private async _buildFilterProductOption() {
        this.productChange.subscribe(description => {
            if (description) {
                this.selectedProducts = this._filterProduct(description);
                this._refreshProducts(this.selectedProducts);
            } else {
                this.selectedProducts = this.products;
                this.selectedProduct = this.createEmptyProduct();
            }
        });
    }

    private _filterProduct(value: string): ProductInstance[] {
        const filterValue = value.toLowerCase();
        return this.products.filter(product =>
            (product.name.toLowerCase().includes(filterValue) || product.code.toLowerCase().includes(filterValue)));
    }


    onNoClick() {
        this._ref.close();
    }

    closeDialog() {
        this._ref.close();
    }

    confirm(mode?: string) {
        if (mode === 'save') {
            this.order.Product = this.selectedProduct;
            this.order.Devices = this.getSelectedDevices(this.selectedDevicesControl.value);
            this.order.WorkProcess = this.selectedProcess && this.selectedProcess.id > 0 ? this.selectedProcess : null;
            this.order.jsonInfo = this.createJsonInfoObject();
            this._ref.close(this.order);
        } else {
            this._ref.close(null);
        }
    }

    rowIdentityComparator(row: DeviceInstance) {
        return row.id;
    }

    changeSelectProcess() {
        this.selectedDevicesControl = new FormControl();
        this.selectedProcess = this.createEmptyProcess();
        this.selectedProduct = this.createEmptyProduct();
        if (this.selectProcess) {
            this.products = [];
        } else {
            this.products = this.plantProducts;
        }
        this.orderCoreProductForm.get('products').setValue('');
    }

    changeProduct(selectedProductId: number) {
        //this.isSelectAll = false;
        this.devices = [];
        this.selectedDevicesControl = new FormControl();
        this.selectedProduct.id = selectedProductId;
        if (this.selectedProduct.id !== -1) {
            const products = this.clone(this.products);
            const product = products.find(p => {
                return p.id === this.selectedProduct.id;
            });
            this.selectedProduct = this.cloneObject(product);
            if (!this.selectProcess) {
                this.devices = this.clone(this.selectedProduct.Devices);
                this.selectedDevicesControl = new FormControl();
            }
        } else {
            this.selectedProduct = this.createEmptyProduct();
        }
        this.orderCoreProductForm.get('products').setValue(this.selectedProduct.code + ' ' + this.selectedProduct.name);
    }

    changeProcess() {
        this.devices = [];
        this.selectedDevicesControl = new FormControl();
        if (this.selectedProcess.id !== -1) {
            const processes = this.clone(this.processes);
            const process = processes.find(p => {
                return p.id === this.selectedProcess.id;
            });
            this.selectedProcess = this.cloneObject(process);

            this.products = this.clone(this.selectedProcess.Products);
            this.selectedProduct = this.createEmptyProduct();
        } else {
            this.selectedProcess = this.createEmptyProcess();
        }
        this.selectedProducts = this.products;
    }

    createEmptyProduct() {
        return {
            id: -1,
            name: null,
            code: null,
            companyId: null,
            Devices: [],
            enabled: true
        };
    }
    createEmptyProcess() {
        return {
            id: -1,
            description: null,
            Products: [],
            enabled: true
        };
    }

    clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }

    cloneObject(object: any) {
        return Object.assign({}, object);
    }

    deleteJsonInfo(indexElement, element) {
        if (!this.jsonInfoArray.length) {
            return;
        }
        this.jsonInfoArray = this.jsonInfoArray.filter((val, index) => {
            return index !== indexElement;
        });
    }

    addNewJsonInfo() {
        this.jsonInfoArray.push({
            key: "",
            value: ""
        })
    }

    createJsonInfoArray(): JsonInfo[] {
        let jsonInfoParse = null;
        if (typeof this.order.jsonInfo === "object") {
            jsonInfoParse = this.cloneObject(this.order.jsonInfo);
        } else {
            jsonInfoParse = JSON.parse(this.order.jsonInfo || null);
        }
        let arr: JsonInfo[] = [];
        if (jsonInfoParse!== "{}" && jsonInfoParse && Object.keys(jsonInfoParse).length) {
            for (let key in jsonInfoParse) {
                arr.push({
                    key: key,
                    value: jsonInfoParse[key]
                })
            }
        } else {
            arr.push({
                key: "",
                value: ""  
            }, {
                key: "",
                value: ""  
            });
        }
        return arr;
    }

    createJsonInfoObject(): object {
        let object = {};
        if (!this.jsonInfoArray.length) {
            return null;
        }
        this.jsonInfoArray.forEach(el => {
            if(el.key !== "" || el.key) {
                object[el.key] = el.value;
            }
        })

        if (Object.keys(object).length) {
            return object
        } else {
            return null;
        }
    }


    compareObjects(o1: any, o2: any): boolean {
        if (!o1 || !o2) {
            return false;
        }
        return o1.id === o2.id;
    }

    getSelectedDevices(selectedDevices) {
        return this.devices.filter(el => (selectedDevices.map(el => el.id)).includes(el.id))
    }
}
