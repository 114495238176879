import { OnInit, Component, ViewEncapsulation, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { UserRole } from '../../../models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';
import { AuthService } from '../../../services/auth.service';
import { CompanyInstance } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { PlantInstance } from '../../../models/plant.model';
import { OrderCoreInstance } from '../../../models/order-core.model';
import { MatDialog } from '@angular/material';
import { OrderCoreDialogComponent } from '../../ui/order-core-dialog/order-core-dialog.components';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { QualityControlDialogComponent } from '../../ui/quality-control-dialog/quality-control-dialog.component';
import { ProductInstance } from '../../../models/product.model';
import { ProductService } from '../../../services/product.service';
import { OrdersCoreService } from '../../../services/ordersCore.service';
import { UiService } from '../../../services/ui.service';

import { Utility } from '../../../utils/utility';
import * as moment from 'moment';
import { OrderDeviceCoreDialogComponent } from '../../ui/order-device-core-dialog/order-device-core-dialog.components';
import { WorkProcessInstance } from '../../../models/work-process.model';
import { WorkProcessService } from '../../../services/workProcess.service';
import {PageWithLoader} from "../page-with-loader";
import {ThemeService} from "../../../services/theme.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-orders-core',
    templateUrl: './orders-core.component.html',
    styleUrls: ['./orders-core.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class OrdersCoreComponent extends PageWithLoader implements OnInit {

    lockRequest = false;
    productSearch = '';
    machineSearch = '';

    isAdmin: boolean;

    showTodo = true;
    orders: OrderCoreInstance[] = [];
    todo: OrderCoreInstance[] = [];
    progressDone: OrderCoreInstance[] = [];
    tmpTodo: OrderCoreInstance[] = [];
    tmpProgressDone: OrderCoreInstance[] = [];

    columns = [];
    companies: CompanyInstance[];
    company: CompanyInstance;
    plants: PlantInstance[] = [];
    selectedCompany: CompanyInstance;
    selectedPlant: PlantInstance;
    selectedCompanyId: number;
    selectedPlantId: number;
    products: ProductInstance[];
    workProcesses: WorkProcessInstance[];
    orderWithProcess = false;
    orderWithDevices = false;
    isSticky = false;

    @ViewChild('code') codeTemplate: TemplateRef<any>;
    @ViewChild('productTemplate') productTemplate: TemplateRef<any>;
    @ViewChild('target') targetTemplate: TemplateRef<any>;
    @ViewChild('deliveryDate') deliveryDateTemplate: TemplateRef<any>;
    @ViewChild('addItem') addItemTemplate: TemplateRef<any>;
    @ViewChild('actions') actionsTemplate: TemplateRef<any>;
    @ViewChild('devicesTemplate') deviceTemplate: TemplateRef<any>;
    @ViewChild('devicesHeader') devicesHeaderTemplate: TemplateRef<any>;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        this.isSticky = window.pageYOffset >= 200;
    }

    constructor(
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _company: CompanyService,
        private _translate: TranslateService,
        private _dialog: MatDialog,
        private _products: ProductService,
        private _workProcesses: WorkProcessService,
        private _ordersCoreService: OrdersCoreService,
        private _ui: UiService,
        private _utility: Utility,
        public auth: AuthService,
        _themeService: ThemeService
    ) {
      super(_themeService);
    }

    async ngOnInit(): Promise<void> {
        this._translate.stream([
            'orders-core.title',
            'orders-core.columns.code',
            'orders-core.columns.product',
            'orders-core.columns.product-description',
            'orders-core.columns.target',
            'orders-core.columns.delivery-date'
        ]).subscribe((translations) => {
            this._navbar.setTitle(translations['orders-core.title']);

            setTimeout(() => this._sidebar.setSelected('orders-core'));
            this.columns = [{
                resizable: true,
                canAutoResize: true,
                name: translations['orders-core.columns.code'],
                prop: 'code',
                cellTemplate: this.codeTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['orders-core.columns.target'],
                prop: 'target',
                cellTemplate: this.targetTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['orders-core.columns.product-description'],
                cellTemplate: this.productTemplate
            }, {
                resizable: true,
                canAutoResize: true,
                name: translations['orders-core.columns.delivery-date'],
                prop: 'deliveryDate',
                cellTemplate: this.deliveryDateTemplate
            }, {
                resizable: false,
                canAutoResize: true,
                width: 150,
                maxWidth: 250,
                name: '',
                prop: 'Devices.length',
                headerTemplate: this.devicesHeaderTemplate,
                cellTemplate: this.deviceTemplate
            }, {
                resizeable: false,
                canAutoResize: true,
                width: 160,
                name: '',
                headerTemplate: this.addItemTemplate,
                cellTemplate: this.actionsTemplate
            }];
        });

        this.lockRequest = true;
        this.selectedCompanyId = Number(localStorage.getItem('companyId'));
        this.selectedPlantId = Number(localStorage.getItem('plantId'));

        // check if user is Admin
        this.isAdmin = this.auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            // if is admin fetch all companies
            this.companies = await this._company.getCompanies();

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged();
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged();
            }
        } else {
            // if is user get company detailt to get plants
            this.company = await this._company.getCompany(this.selectedCompanyId);
            // set plants
            this.plants = this.company.Plants;
            await this._handleBuildPlantArrayObject(true);
            await this.plantChanged();
        }
        this.lockRequest = false;
    }

    async companyChanged() {
        this.selectedCompanyId = this.selectedCompany.id;
        localStorage.setItem('companyId', this.selectedCompanyId.toString());
        this.plants = this.selectedCompany.Plants;
        const isSelectedPlantInCurrentCompany = this.selectedPlantId ?
            this.selectedCompany.Plants.find((p) => p.id === this.selectedPlantId) : false;
        await this._handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany ? true : false);
        await this.plantChanged();
    }

    async _handleBuildPlantArrayObject(isSelectedPlantInCurrentCompany: boolean) {
        // set plant
        if (!this.selectedPlantId || !isSelectedPlantInCurrentCompany) {
            this.selectedPlant = this.plants[0];
        } else if (this.plants.length > 0) {
            this.selectedPlant = this.plants.find((p) => p.id === this.selectedPlantId);
        }
    }

    async plantChanged() {
        this.selectedPlantId = this.selectedPlant.id;
        localStorage.setItem('plantId', this.selectedPlantId.toString());
        await this.loadData();
    }

    async loadData() {
        const deviceAttributes = ['id', 'label'];
        this.products = await this._products.getProducts(null, this.selectedPlantId, true, true, deviceAttributes);
        this._sortProductsByCode(this.products);
        this.workProcesses = await this._workProcesses.getProcesses(this.selectedPlantId, true, true);
        this._sortProcessesByDescription(this.workProcesses);
        await this.fetchOrders();
    }

    async fetchOrders() {
        const retrievedOrders: OrderCoreInstance[] = await this._ordersCoreService.getAll(
            this.selectedPlantId,
            null,
            null,
            null,
            true);
        this.orders = retrievedOrders || [];

        this.todo = retrievedOrders.filter( e => !e.done && !e.isInProgress);
        // this._sortOrdersByDeliveryDate(this.todo);
        this.updateDeviceHeaderLabel();
        this.tmpTodo = this._utility.cloneArray(this.todo);

        this.progressDone = retrievedOrders.filter( e => e.done || e.isInProgress );
        // this._sortOrdersByDeliveryDate(this.progressDone);
        this.tmpProgressDone = this._utility.cloneArray(this.progressDone);
    }

    async addOrder() {
        const product = {
            id: -1
        };
        const newOrder = {
            code: null,
            deliveryDate: null,
            target: null,
            priorityErrorTarget: null,
            Product: product,
            Devices: []
        };
        this.openPopup(newOrder, null);
    }

    async deleteOrder(order: OrderCoreInstance, index: number) {
        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                title: this._translate.instant('orders-core.dialog.title'),
                message: this._translate.instant('orders-core.dialog.text'),
                disabled: true
            }
        });
        ref.afterClosed().subscribe(async (result) => {
            if (result === 'delete') {
                const deleteResult = await this._ordersCoreService.delete(order.id);
                if (deleteResult !== 200) {
                    this._ui.openSnackBar(this._translate.instant('orders-core.error-delete-alreadyinprogress'));
                } else {
                    // Remove from table
                    this.todo.splice(index, 1);
                    this.updateDeviceHeaderLabel();
                    this.tmpTodo = this._utility.cloneArray(this.todo);
                    this._ui.openSnackBar(this._translate.instant('orders-core.deleted'));
                }
            }
        });
    }

    async editOrder(order: OrderCoreInstance, index: number) {
        this.openPopup(order, index);
    }

    openPopup(order, index) {
        if (order.Devices.length) {
            if (order.Devices[0].OrdersDevices) {
                order.priorityErrorTarget = order.Devices[0].OrdersDevices.priorityErrorTarget
            }
        }
        const ref = this._dialog.open(OrderCoreDialogComponent, {
            width: '800px',
            height: '600px',
            data: {
                order: order,
                products: this.products,
                processes: this.workProcesses
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {
                if (result.id) {
                    // Edit
                    const devicesId = result.Devices.map(d => d.id);
                    const utcDateB =  moment.utc(moment(result.deliveryDate).format('YYYY-MM-DDTHH:mm:ss'));
                    const utcDateS = utcDateB.toDate();
                    const jsonInfo = result.jsonInfo || null;
                    const orderUpdated = await this._ordersCoreService.update(
                        order.id,
                        result.code,
                        null,
                        this.selectedPlant.id,
                        result.Product.id,
                        result.WorkProcess ? result.WorkProcess.id : null,
                        devicesId,
                        result.target,
                        result.priorityErrorTarget,
                        utcDateS,
                        false,
                        jsonInfo);

                    if (typeof orderUpdated === 'number') {
                        this._ui.openSnackBar(this._translate.instant('orders-core.error-save'));
                        return;
                    }

                    let deviceComputed = result.Devices.map((device, index) => {
                        return {
                            ...device,
                            ...orderUpdated.Devices[index]
                        }
                    })

                    orderUpdated.Product = result.Product;
                    orderUpdated.Devices = deviceComputed,
                    orderUpdated.WorkProcess = result.WorkProcess;
                    this.todo.splice(index, 1);
                    this.todo.unshift(orderUpdated);
                    this.updateDeviceHeaderLabel();
                    this.tmpTodo = this._utility.cloneArray(this.todo);
                    this._ui.openSnackBar(this._translate.instant('orders-core.saved'));
                } else {
                    // Create
                    const devicesId = result.Devices.map(d => d.id);
                    const utcDateB =  moment.utc(moment(result.deliveryDate).format('YYYY-MM-DDTHH:mm:ss'));
                    const utcDateS = utcDateB.toDate();
                    const jsonInfo = result.jsonInfo || null;
                    const orderCreated = await this._ordersCoreService.create(
                        result.code,
                        null,
                        this.selectedPlant.id,
                        result.Product.id,
                        result.WorkProcess ? result.WorkProcess.id : null,
                        devicesId,
                        result.target,
                        result.priorityErrorTarget,
                        utcDateS,
                        false,
                        jsonInfo);

                    if (typeof orderCreated === 'number') {
                        this._ui.openSnackBar(this._translate.instant('orders-core.error-save'));
                        return;
                    }

                    let deviceComputed = result.Devices.map((device, index) => {
                        return {
                            ...device,
                            ...orderCreated.Devices[index]
                        }
                    })

                    orderCreated.Product = result.Product;
                    orderCreated.Devices = deviceComputed,
                    orderCreated.WorkProcess = result.WorkProcess;
                    this.todo.push(orderCreated);
                    // this._sortOrdersByDeliveryDate(this.todo);
                    this.updateDeviceHeaderLabel();
                    this.tmpTodo = this._utility.cloneArray(this.todo);
                    this.filter();
                    this._ui.openSnackBar(this._translate.instant('orders-core.saved'));
                }
            }
        });
    }

    openDialogDevices(order: OrderCoreInstance, index: number) {
        const labels = order.Devices.map((d) => d.label);
        let title = 'orders-core.columns.devices';
        if (labels.length === 1) {
            title = 'orders-core.columns.singular';
        }
        this._dialog.open(QualityControlDialogComponent, {
            width: '600px',
            height: '600px',
            data: {
                key: title,
                values: labels,
                needFilterBar: true
            }
        });
    }

    async openStatusOrder(order: OrderCoreInstance, index: number) {
        const ref = this._dialog.open(OrderDeviceCoreDialogComponent, {
            width: '600px',
            data: {
                orderData: order
            }
        });

        ref.afterClosed().subscribe(async (orderUpdated) => {
            if (orderUpdated) {
                const idx = this.progressDone.findIndex(o => o.id === orderUpdated.id);
                this.progressDone[idx] = orderUpdated;
                // this._sortOrdersByDeliveryDate(this.progressDone);
                this.tmpProgressDone = this._utility.cloneArray(this.progressDone);
                this.filter();
            }
        });
    }

    filter() {
        const cloneArr = this.showTodo ? this._utility.cloneArray(this.todo) : this._utility.cloneArray(this.progressDone);
        let tmp = null;
        const productSearchLowerCase = this.productSearch ? this.productSearch.toLowerCase() : null;
        const machineSearchLowerCase = this.machineSearch ? this.machineSearch.toLowerCase() : null;
        if (this.productSearch && this.machineSearch) {
            tmp = cloneArr.filter(o => {
                const code = o.Product.code.toLowerCase();
                const name = o.Product.name.toLowerCase();
                return code.indexOf(productSearchLowerCase) > -1 || name.indexOf(productSearchLowerCase) > -1;
            });
            tmp = tmp.filter(o => {
                let found = false;
                for (const d of o.Devices) {
                    const label = d.label.toLowerCase();
                    if (label.indexOf(machineSearchLowerCase) > -1) {
                        found = true;
                        break;
                    }
                }
                return found;
            });
        } else if (this.productSearch) {
            tmp = cloneArr.filter(o => {
                const code = o.Product.code.toLowerCase();
                const name = o.Product.name.toLowerCase();
                return code.indexOf(productSearchLowerCase) > -1 || name.indexOf(productSearchLowerCase) > -1;
            });
        } else if (this.machineSearch) {
            tmp = cloneArr.filter(o => {
                let found = false;
                for (const d of o.Devices) {
                    const label = d.label.toLowerCase();
                    if (label.indexOf(machineSearchLowerCase) > -1) {
                        found = true;
                        break;
                    }
                }
                return found;
            });
        } else {
            tmp = cloneArr;
        }

        if (this.showTodo) {
            this.tmpTodo = tmp;
        } else {
            this.tmpProgressDone = tmp;
        }
    }

    filterResetProduct() {
        this.productSearch = '';
        this.filter();
    }

    filterResetMachine() {
        this.machineSearch = '';
        this.filter();
    }

    tabChanged(e) {
        this.showTodo = e.index === 0;
    }

    private _sortOrdersByDeliveryDate(orders) {
        if (orders) {
            orders.sort((a, b) => {
                const dateA = a.deliveryDate;
                const dateB = b.deliveryDate;
                if (dateA < dateB) return -1;
                else if (dateA > dateB) return 1;
                else return 0;
            });
        }
    }
    private _sortProductsByCode(products) {
        if (products) {
            products.sort((a, b) => {
                const productA = a.code;
                const productB = b.code;
                if (productA < productB) return -1;
                else if (productA > productB) return 1;
                else return 0;
            });
        }
    }

    private _sortProcessesByDescription(processes: WorkProcessInstance[]) {
        if (processes) {
            processes.sort((a, b) => {
                const processA = a.description;
                const processB = b.description;
                if (processA < processB) return -1;
                else if (processA > processB) return 1;
                else return 0;
            });
        }
    }

    updateDeviceHeaderLabel() {
        this.orderWithProcess = !!this.todo.find(order => !!order.WorkProcess);
        this.orderWithDevices = !!this.todo.find(order => !order.WorkProcess);
    }

    async sortByDate() {
        if (this.productSearch || this.machineSearch) {
            this._ui.openSnackBar(this._translate.instant('orders-core.avoid-sorting'));
            return;
        }
        this.lockRequest = true;
        await this._ordersCoreService.sortByDate(this.selectedPlantId);
        await this.fetchOrders();
        this.lockRequest = false;
    }

    async drop(event: CdkDragDrop<string[]>) {
        if (this.productSearch || this.machineSearch) {
            this._ui.openSnackBar(this._translate.instant('orders-core.avoid-sorting'));
            return;
        }
        const movedOrder: OrderCoreInstance = this.todo[event.previousIndex];
        if (movedOrder && movedOrder.id) {
            moveItemInArray(this.tmpTodo, event.previousIndex, event.currentIndex);
            const response = await this._ordersCoreService.sort(this.tmpTodo.map(o => o.id), this.selectedPlantId);
            if (response && response.sorted && response.refetchOrders) {
                this.lockRequest = true;
                await this.fetchOrders();
                this.lockRequest = false;
            }
        }
    }
}
