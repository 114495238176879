import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UiService } from '../../../services/ui.service';
import { NavbarService } from '../../../services/navbar.service';
import { SidebarService } from '../../../services/sidebar.service';

import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { CompanyService } from '../../../services/company.service';
import { CompanyInstance } from '../../../models/company.model';
import { AuthService } from '../../../services/auth.service';
import { UserRole } from '../../../models/user.model';
import { DeviceService } from '../../../services/device.service';
import { UserInstance } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { DeviceInstance } from '../../../models/device.model';
import { PlantInstance } from '../../../models/plant.model';


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {

    columns = [];

    isAdmin = false;

    companies: CompanyInstance[];

    users: UserInstance[];

    tmpUsers: UserInstance[];

    selectedCompany: any;

    selectedCompanyId: number;

    editRow: number;

    modified: boolean;

    USER_ROLE: typeof UserRole = UserRole;

    current: UserRole;

    devices: DeviceInstance[];

    plants: PlantInstance[];

    @ViewChild('email') textTemplate: TemplateRef<any>;
    @ViewChild('firstName') firstNameTemplate: TemplateRef<any>;
    @ViewChild('lastName') lastNameTemplate: TemplateRef<any>;
    @ViewChild('role') roleTemplate: TemplateRef<any>;
    @ViewChild('plant') plantTemplate: TemplateRef<any>;
    @ViewChild('roleHelp') roleHelpTemplate: TemplateRef<any>;
    @ViewChild('enabled') enabledTemplate: TemplateRef<any>;
    @ViewChild('actions') actionsTemplate: TemplateRef<any>;
    @ViewChild('addItem') addItemTemplate: TemplateRef<any>;

    constructor(
        private _company: CompanyService,
        private _user: UserService,
        private _device: DeviceService,
        private _ui: UiService,
        private _navbar: NavbarService,
        private _sidebar: SidebarService,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        public auth: AuthService,
    ) { }

    async ngOnInit() {

        this._translate
            .stream([
                'users.title',
                'users.columns.email',
                'users.columns.firstName',
                'users.columns.lastName',
                'users.columns.createAt',
                'users.columns.role',
                'users.columns.enabled',
            ])
            .subscribe((translations) => {
                this._navbar.setTitle(translations['users.title']);
                setTimeout(() => this._sidebar.selected = 'users');
                this.columns = [{
                    resizable: false,
                    canAutoResize: true,
                    width: 300,
                    maxWidth: 500,
                    name: translations['users.columns.email'],
                    prop: 'email',
                    cellTemplate: this.textTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 250,
                    maxWidth: 350,
                    name: translations['users.columns.firstName'],
                    prop: 'firstName',
                    cellTemplate: this.firstNameTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 350,
                    maxWidth: 500,
                    name: translations['users.columns.lastName'],
                    prop: 'lastName',
                    cellTemplate: this.lastNameTemplate
                }, {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    prop: 'role',
                    headerTemplate: this.roleHelpTemplate,
                    cellTemplate: this.roleTemplate
                    // }, {
                    //     resizable: false,
                    //     canAutoResize: true,
                    //     width: 150,
                    //     maxWidth: 150,
                    //     name: translations['users.columns.enabled'],
                    //     prop: 'enabled',
                    //     cellTemplate: this.enabledTemplate
                },
                {
                    resizable: false,
                    canAutoResize: true,
                    width: 150,
                    maxWidth: 250,
                    prop: 'plant',
                    name: 'PLANT',
                    cellTemplate: this.plantTemplate
                },
                {
                    resizeable: false,
                    canAutoResize: true,
                    prop: '',
                    headerTemplate: this.addItemTemplate,
                    cellTemplate: this.actionsTemplate
                }];
            });

        this.isAdmin = this.auth.user.role === UserRole.admin;
        if (this.isAdmin) {
            this.companies = await this._company.getCompanies();
            this.selectedCompanyId = Number(localStorage.getItem('companyId'));

            if (this.selectedCompanyId) {
                this.selectedCompany = this.companies.find((company) => company.id === this.selectedCompanyId);
                await this.companyChanged();
            } else if (this.companies.length > 0) {
                this.selectedCompany = this.companies[0];
                await this.companyChanged();
            }
        } else {
            this.devices = await this._device.getDevices();
            if (this.devices.length > 0) {
                this.selectedCompany = this.devices[0].Company;
                this.users = await this._user.getUsers();
                this.users = this.users.filter((tag) => {
                    return tag.id > 0;
                });
                this.tmpUsers = this.clone(this.users);
                this.editRow = null;
                this.plants = await this.getPlants(this.selectedCompany.id);
            }
        }
    }

    add() {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        const newUser: UserInstance = {
            id: null,
            firstName: '',
            lastName: '',
            role: null,
            plant: {id : -1}
        };

        this.users.unshift(newUser);
        this.tmpUsers = this.clone(this.users);
        this.editRow = 0;
    }

    async companyChanged() {
        this.selectedCompanyId = this.selectedCompany.id;
        this.plants = await this.getPlants(this.selectedCompany.id);
        localStorage.setItem('companyId', this.selectedCompanyId.toString());
        this.users = await this._user.getUsers(this.selectedCompany.id);
        this.users = this.users.filter((user) => {
            return user.id > 0;
        });

        this.tmpUsers = this.clone(this.users);
        this.editRow = null;
        this.modified = false;
    }

    /**
     * Retrieve the plant list about company
     *
     * @param {number} companyId
     * @returns
     * @memberof UsersComponent
     */
    async getPlants (companyId: number) {
        return await this._company.getPlants(companyId);
    }

    isEditRow(rowIndex: number) {
        return this.editRow === rowIndex;
    }

    isEditMode(rowIndex: number) {
        return (this.editRow) && !this.isEditRow(rowIndex);
    }

    setModified() {
        this.modified = true;
    }

    edit(rowIndex: number) {
        if (this.editRow || this.editRow === 0) {
            return;
        }
        this.editRow = rowIndex;

        if (this.tmpUsers[rowIndex] && !this.tmpUsers[rowIndex].plant) {
            let defaultPlant: PlantInstance = {id: -1};
            if (this.tmpUsers[rowIndex].Plants && this.tmpUsers[rowIndex].Plants.length > 0) {
                defaultPlant = {id: this.tmpUsers[rowIndex].Plants[0].id};
            }
            this.users[rowIndex].plant = defaultPlant;
            this.tmpUsers[rowIndex].plant = defaultPlant;
        }
    }

    back(rowIndex: number) {
        if (this.users[rowIndex].id) {
            this.tmpUsers = this.clone(this.users);
        } else {
            this.users.shift();
            this.tmpUsers.shift();
        }

        this.users = [...this.users];
        this.tmpUsers = [...this.tmpUsers];
        this.editRow = null;
        this.modified = false;
    }

    async delete(user: UserInstance, index: number) {
        if (!user.id) {
            this.users.shift();
            this.tmpUsers.shift();
            this.users = [...this.users];
            this.tmpUsers = [...this.tmpUsers];
            this.editRow = null;
            this.modified = false;
            return;
        }

        const ref = this._dialog.open(DialogComponent, {
            width: '600px',
            data: {
                title: this._translate.instant('users.dialog.title'),
                message: this._translate.instant('users.dialog.text', { prod: user.email }),
                disabled: 'no'
            }
        });

        ref.afterClosed().subscribe(async (result) => {
            if (result) {

                await this._user.delete(user.id);

                this.users.splice(index, 1);
                this.tmpUsers.splice(index, 1);

                this.users = [...this.users];
                this.tmpUsers = [...this.tmpUsers];

                this.editRow = null;
                this.modified = false;

                this._ui.openSnackBar(this._translate.instant('users.deleted'));
            }
        });
    }

    async save(user: UserInstance, index: number) {
        let res = null;

        if (user.id) {
            res = await this._user.update(user);
            if (res === 200) {
                this.updateSelectedUserPlant(user);
                this.users[index] = user;
                this.tmpUsers = this.clone(this.users);
                this.modified = false;
                this.editRow = null;
                this._ui.openSnackBar(this._translate.instant('users.saved'));

            } else {
                this._ui.openSnackBar(this._translate.instant('users.error'));
            }
        } else {
            user.companyId = this.selectedCompany.id;
            const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
            res = await this._user.create(user, lang);
            if (res) {
                user.id = res.id;
                this.updateSelectedUserPlant(user);
                this.users[index] = user;
                this.tmpUsers = this.clone(this.users);
                this.modified = false;
                this.editRow = null;
                this._ui.openSnackBar(this._translate.instant('users.saved'));

            } else {
                this._ui.openSnackBar(this._translate.instant('users.error'));
            }

        }
    }

    clone(array: any) {
        return array.map(x => Object.assign({}, x));
    }

    updateSelectedUserPlant(user: UserInstance) {
        user.Plants = [];
        if (user && user.plant && user.plant.id !== -1 && this.plants && this.plants.length > 0) {
            user.Plants[0] = this.plants.find(p => p.id === user.plant.id);
        }
    }
}
