import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-qr-dialog',
    templateUrl: './qr-dialog.component.html',
    styleUrls: ['./qr-dialog.component.scss']
})
export class QrDialogComponent {
    constructor(
        private _ref: MatDialogRef<QrDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onClose(result) {
        const source = document.getElementById('qrImage').firstElementChild;

        this._ref.close({
            name: result,
            src: source.getAttribute('src')
        });
    }
}
