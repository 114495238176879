import { Component, Injector} from '@angular/core';
import {ThemeService} from "../../services/theme.service";

@Component({ template: '' })
export class PageWithLoader {

  constructor(private _themeService: ThemeService) {
  }

  getLoaderSrc() : string {
    return this._themeService.isTeamSystemThemeSetted() ? 'assets/images/team_system/wait.png' : 'assets/images/wait.gif';
  }
}
