import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ControlSchedulingIstance } from '../models/control-scheduling.model';


@Injectable()
export class ControlSchedulingService {

    constructor(private _http: HttpService, private _config: ConfigService) { }

    async update(data: any) {
        const url = `${this._config.API.url}/controlSchedulings`;

        const response = await this._http.post(url, data);

        return response;
    }

    async delete(scheduleId: number, controlId: number) {
        const url = `${this._config.API.url}/controlScheduling/${scheduleId}/controls/${controlId}`;

        const response = await this._http.delete(url);

        return response;
    }

}
