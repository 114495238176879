export interface OrderDeviceIstance {
    orderId: number;
    deviceId: number;
    items?: number;
    scraps?: number;
    status?: OrderStatus;
    done?: boolean;
}

export enum OrderStatus {
    draft = 1,
    planned = 2,
    running = 3,
    suspended = 4,
    completed = 5
}
