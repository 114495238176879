import { Injectable } from '@angular/core';
import { Activity } from '../models/activity.model';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private _http: HttpService,
    private _config: ConfigService) { }

  async create(activity: Activity) {

    const url = `${this._config.API.url}/workprocess/activity`;

    let data;

    data = {
      description: activity.description,
      plantId: activity.plantId,
      activityUnitType: activity.type,
      enableRepeatByPiece: false
    };

    if (activity.ProductionActivity.noteEnabled) {
      data.note = activity.ProductionActivity.note;
    }

    if (activity.ProductionActivity.targetTimeInMinutesEnabled) {
      data.targetTimeInMinutes = activity.ProductionActivity.targetTimeInMinutes;
    }

    const response = await this._http.post(url, data);
    return response.json() as Activity;
  }

  async update(activity: Activity) {

    const url = `${this._config.API.url}/workprocess/activity/${activity.id}`;

    let data;

    data = {
      description: activity.description,
      enableRepeatByPiece: false
    };

    if (activity.ProductionActivity.noteEnabled) {
      data.note = activity.ProductionActivity.note;
    }

    if (activity.ProductionActivity.targetTimeInMinutesEnabled) {
      data.targetTimeInMinutes = activity.ProductionActivity.targetTimeInMinutes;
    }

    const response = await this._http.put(url, data);
    return response.json() as Activity;
  }

}
