import { Pipe, PipeTransform } from '@angular/core';
import { ErrorCategoryInstance } from '../models/error-category.model';

@Pipe({
    name: 'filterCategory',
    pure: false
})
export class FilterCategoryPipe implements PipeTransform {
    transform(items: ErrorCategoryInstance[], filter: number): any {
        if (!items || (!filter && filter !== 0)) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.type === filter);
    }
}
